import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}
@media (min-width: 700px) {
  html {
    scroll-padding-top: 150px;
  }
}

*,*::after,*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.global};
  &.unscrollable{
    overflow-y: hidden;
  }
}
input,button,textarea {
  font-family: ${({ theme }) => theme.fontFamily.global};
  outline: none;
}
a {
  text-decoration: none;
  color: ${({ theme }) => theme.colors.dark};;
}
button {
  cursor: pointer;
}

table{
  border-spacing:3em;
  background-color: white;
}

li::marker {
   font-weight: bold;
   font-size:25px;
}

.legend {
  background-color: black;
  opacity: 1;
}
   
`;

export default GlobalStyle;
