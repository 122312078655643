import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FreeAdviceModal from '../components/FreeAdviceModal';
import { useSelector } from 'react-redux';
import { useBodyUnscrollable } from '../hooks';
import CoockieChooser from '../components/CoockieChooser';

const ContentWrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 1280px;
  width: 90%;
  margin-inline: auto;
  padding-bottom: 2rem;
  gap: 2rem;
  padding-top: 2.5rem;
  @media (min-width: 790px) {
    gap: 5rem;
  }
`;

const MainTemplate = ({ children }) => {
  const { isVisible } = useSelector(state => state.FreeAdviceReducer);
  const isCookieChooserVisible = useSelector(state => state.CoockieReducer.isVisible);

  useBodyUnscrollable(isVisible);
  return (
    <>
      <Header />
      <ContentWrapper>{children}</ContentWrapper>
      <Footer />
      {isVisible && <FreeAdviceModal />}
      {isCookieChooserVisible && <CoockieChooser />}
    </>
  );
};

MainTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainTemplate;
