import styled from 'styled-components';
import Image from '../../assets/blog/art16.jpg';
import { basicRoute } from '../../routes/routes';
import { useLocation } from 'react-router';
import { Header } from '../../components/BlogTextHeder';
import { useEffect } from 'react';
import { Akapit } from '../../components/Akapit/Akapit';
const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Details = {
  head: ' Jak nie wystraszyć się proficredit?',
  bigImage: Image,
  text: ' ',
  tableOfContents: [
    {
      id: '1',
      text: 'Kiedy zaczynają się problemy?',
    },
    {
      id: '2',
      text: 'Warto przemyśleć w jaki sposób spłacać pożyczkę. Decyzja UOKiKu.',
    },
    {
      id: '3',
      text: '  Weksel in blanco.',
    },

    {
      id: '4',
      text: ' Podsumowanie',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`;
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit />
          ProfiCredit, to firma <b>pożyczkowa i windykacyjna</b> udzielająca długoterminowych
          pożyczek, które obciążają jej klientów ogromnymi kosztami pozaodsetkowymi (np.{' '}
          <b>prowizją</b>, opłatą przygotowawczą, opłatą za plan “Twój Pakiet”), nierzadko
          przekraczającymi kwoty otrzymanej pożyczki. Często okazuje się, że umowy pożyczek
          udzielanych przez ProfiCredit zawierają klauzule abuzywne (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog12} style={{ color: 'orange' }}>
            klauzule abuzywne
          </a>
          ) .
          <br />
          <Akapit /> Profil działalności ProfiCredit z pozoru nie różni niczym od innych parabanków,
          takich jak <b>Zaplo, Vivus, Provident, SuperGrosz (obecnie Aiqlabs), czy Rapida</b>. Co
          jednak powoduje, że w opinii wielu obecnych i byłych klientów ProfiCredit jawi się jako
          firma działająca na granicy prawa? Przede wszystkim wynika to z faktu, że firma ta
          prowadzi bardzo agresywną <b>windykację</b>, w tym również <b>windykację terenową </b>
          (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog22} style={{ color: 'orange' }}>
            Jak działa komornik ?
          </a>
          ) oraz stosuje inne <b>sztuczki</b> (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog21} style={{ color: 'orange' }}>
            Tricki i nieczyste zagrywki firm windykacyjnych
          </a>
          ) , które mają zmusić kredytobiorców do spłaty zawyżonych sum pieniędzy.{' '}
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>Spis treści :</strong>
        </p>
        <ol style={{ maringLeft: '20px' }}>
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <strong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </strong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper>
        <ol style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li id="1">
              <Header> Kiedy zaczynają się problemy?</Header>
              <StyledP>
                <Akapit />
                Oczywiście początkowe kontakty z ProfiCredit nie zwiastują wszystkich
                nieprzyjemności, które mogą pojawić się w przyszłości. Osoba udzielająca pożyczkę
                jest zazwyczaj miła i zapewnia, że cała współpraca przebiegnie bez problemów.
                Opowiada przy tym, że pożyczka będzie <strong>spłacana w ratach</strong>, nie
                wspominając o jej ogromnych kosztach. <br /> <Akapit />
                Problemy oczywiście pojawiają się w chwili powstania zaległości w spłacie. Zazwyczaj
                zaczyna się od nieprzyjemnych telefonów, w których klienci są popędzani i straszeni
                rozmaitymi konsekwencjami, takimi jak wizyta w domu, u pracodawcy lub kogoś z
                rodziny, skierowanie sprawy do sądu lub od razu do komornika. Ta ostatnia
                “zapowiedź” jest oczywiście kłamstwem, gdyż żeby sprawa trafiła do{' '}
                <strong>komornika </strong>(czyt. także {''}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog2}
                  style={{ color: 'orange' }}
                >
                  Przywrócenie terminu. Jak odzczytać pisamo od komornika?
                </a>
                ) , musi się ona wcześniej zakończyć w <strong>sądzie</strong> (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog3}
                  style={{ color: 'orange' }}
                >
                  Jak złożyć sprzeciw od nakazu zapłaty ?
                </a>
                ). Jest to kolejna rzecz “wyróżniająca” ProfiCredit od innych podmiotów działających
                na rynku finansowym. <br /> <Akapit />
                Co więcej ProfiCredit udziela również pożyczek bez weryfikowania zdolności
                kredytowej swoich pożyczkobiorców, a w przypadku problemów ze spłatą zobowiązania
                zachęca do zawierania kolejnych umów. W takich przypadkach z reguły cała kwota nowej
                pożyczki zostaje wykorzystana do spłacenia poprzednich pożyczek (oczywiście wiąże
                się to z koniecznością ponoszenia ciężaru kolejnej, <b>ogromnej</b>{' '}
                <strong>prowizji</strong>). Są to działania niebezpieczne dla konsumentów, które
                mogą doprowadzić do popadnięcia przez nich w <strong>pętle kredytową</strong>.
                <br /> <Akapit />O ile analizę czy warto brać kolejną pożyczkę w ProfiCredit, aby
                spłacić poprzednią, straszenie swoich klientów i “wymyślanie” różnych sankcji, które
                ci mogą ponieść w związku z zaległościami można w skutek rozmowy z doświadczonym
                prawnikiem rozszyfrować i przestać się nimi przejmować, o tyle nękanie przez
                pracowników ProfiCredit telefonami i <strong>wizytami “terenowymi”</strong> to
                realne zagrożenie z którym trzeba się zmierzyć.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="2">
              <Header>Warto przemyśleć w jaki sposób spłacać pożyczkę. Decyzja UOKiKu.</Header>

              <StyledP>
                <Akapit />
                Praktyki stosowane przez ProfiCredit nie pozostały niezauważone przez Urząd Ochrony
                Konkurencji i Konsumentów (UOKiK) i na ProfiCredit nałożona została przez Prezesa
                ww. instytucji kara w wysokości 4 mln zł. Oznacza to, że nikt nie powinien mieć
                wątpliwości co do tego, że działania tej firmy pożyczkowej są{' '}
                <strong>niezgodne z prawem</strong>, a dochodzone przez nich zadłużenia opierają się
                na niedozwolonych zapisach umów pożyczkowych. <br />
                <Akapit />
                Czerpiąc z naszego doświadczenia w walce z ProfiCredit zauważamy, że sędziowie coraz
                częściej uznają za abuzywne zapisy dotyczące prowizji i innych opłat dochodzonych
                przez tego pożyczkodawcę. Co za tym idzie często w toku procesu sądowego dochodzi do
                ich unieważnienia i{' '}
                <strong>zwolnienia dłużników z konieczności ponoszenia tych opłat</strong>. Dlatego
                w przypadku zaciągnięcia pożyczki, korzystnym rozwiązaniem może być spłata tylko
                tzw. kapitału pożyczki czyli kwoty wypłaconej pożyczkobiorcy i walka o{' '}
                <strong>umorzenie</strong> pozostałych kosztów przed sądem. Znając praktykę
                orzekania sądów wiemy, że nawet w momencie kiedy pożyczka nie została spłacona w
                kwocie kapitału, pozew który otrzymałeś od ProfiCredit (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog1}
                  style={{ color: 'orange' }}
                >
                  jak zrozumieć nakaz zapłaty i pozew ?
                </a>
                ) może zostać <b>oddalony w całości</b>, co oznacza, że nie będziesz musiał już nic
                im płacić.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="3">
              <Header>Weksel in blanco.</Header>

              <StyledP>
                <Akapit />
                Pamiętając jednak o swoich prawach, nie zapominaj, że masz do czynienia z
                doświadczonym przeciwnikiem. ProfiCredit doskonale zdaje sobie sprawę z tego w jaki
                sposób postępuje, dlatego zabezpiecza się tym, że każdy kto zawiera z nimi umowę
                podpisuje tzw. <strong>weksel in blanco.</strong> Konsekwencją podpisania takiego
                weksla jest to, że ProfiCredit może wpisać <strong>w zasadzie dowolną kwotę</strong>{' '}
                jaką klient “zobowiązuje” się im zwrócić (podpis pożyczkobiorcy znajduje się na
                wekslu przed wpisaniem kwoty). Powoduje to, że nie wystarczy przed sądem powołać się
                jedynie ogólnikowo na fakt występowania klauzul abuzywnych, czy decyzję UOKiKu.
                Sprawa jest bardziej skomplikowana.
                <br /> <Akapit />
                Weksel stanowi bardzo mocny dowód w polskim systemie prawnym i fakt jego podpisania
                komplikuje sprawę oraz może przesądzić o konieczności spłaty zadłużenia nawet pomimo
                wyżej opisanych faktów. Na szczęście w toku procesu po podniesieniu właściwych
                argumentów można go podważyć i doprowadzić{' '}
                <strong>do umorzenia dodatkowych opłat i prowizji.</strong>
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="4">
              <Header>Podsumowanie</Header>

              <StyledP>
                <Akapit />W walce z ProfiCredit i ich nieuczciwymi metodami kluczem są dwie rzeczy.
                Pierwsza z nich to odwaga, żeby nie dać im się zastraszyć i oszukać. Drugą, jeszcze
                ważniejszą jest, aby sprawą zajął się doświadczony zespół prawników, ponieważ jest
                Profi Credit to bezwzględny i sprytny przeciwnik. Jeśli potrzebujesz porady w walce
                z tą firmą, otrzymałeś <strong>wezwanie do zapłaty</strong> lub{' '}
                <strong>nakaz zapłaty</strong> skontaktuj się z nami.{' '}
                <strong>Bezpłatnie przeanalizujemy</strong> twoją sprawę, wytłumaczymy Ci jakie
                działania może podjąć ProfiCredit oraz doradzimy jak z nimi postępować. Dzięki
                naszym działaniom możesz doprowadzić do{' '}
                <strong>anulowania nielegalnych opłat i prowizji.</strong>
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
