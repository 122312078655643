import styled from 'styled-components';
import axios from 'axios';
import { useState } from 'react';
import { MdPhonelinkRing } from 'react-icons/md';
import { MdEmail } from 'react-icons/md';
import { MdAccessTime } from 'react-icons/md';
import { MdFilePresent } from 'react-icons/md';
import { SubmitFormButton } from '../components/Buttons/submitFormButton';
import { MdArrowCircleUp } from 'react-icons/md';
import { BASE_URL } from '../constants/api';
import { useEffect } from 'react';
const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  align-self: center;
`;
const ContantImgWrapper = styled.div`
  min-width: 280px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
const ContactWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-inline: auto;
  @media (max-width: 790px) {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const ContactFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-inline: auto;
`;
const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
`;
const StyledInput = styled.input`
  width: 100%;
  border-radius: ${({ theme }) => theme.border.radius};
  padding: 1rem 1rem;
  border: 2px solid transparent;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.border.radius};
  border: 2px solid ${({ theme }) => theme.colors.lightBlue};
  resize: vertical;

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.darkBlue};
  }
`;
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const Contact = () => {
  const [name, setName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [files, setFiles] = useState(null);
  const [opis, setOpis] = useState('');
  const [date, setDate] = useState('');
  const [checkOne, setCheckOne] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [checkError, setCheckEror] = useState(false);
  // const [checkFileError, setCheckFileError] = useState(false);
  useEffect(() => {
    document.title = "Kontakt - Kancelaria Radcy Prawnego - Sprzeciw Długom"; 
    if (checkOne) {
      setCheckEror(false);
      // setCheckFileError(false);
    }
  }, [checkOne]);
  const handleSubmit = async e => {
    e.preventDefault();

    if (checkOne === false) {
      setCheckEror(true);
      // setCheckFileError(true);
    } else {
      var formData = new FormData();
      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append(`files`, files[i]);
        }
      }
      formData.append(
        'body',
        JSON.stringify({
          name,
          lastname,
          email,
          phoneNumber,
          opis,
          date,
        }),
      );
      const res = await axios({
        method: 'post',
        url: `${BASE_URL}/test`,
        data: formData,
        header: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res.status === 200) {
        setIsSubmited(true);
      } else {
        //set file error
      }
    }
  };
  
  return (
    <>
      <StyledH1>Skontaktuj się</StyledH1>
      <ContactWrapper>
        <ContantImgWrapper>
          <MdPhonelinkRing style={{ width: '60px', height: '60px', color: 'orange' }} />
          <p>+48 881 397 639</p>
        </ContantImgWrapper>
        <ContantImgWrapper>
          <MdEmail style={{ width: '60px', height: '60px', color: 'orange' }} />
          <p>kontakt@sprzeciwdlugom.pl</p>
        </ContantImgWrapper>
        <ContantImgWrapper>
          <MdAccessTime style={{ width: '60px', height: '60px', color: 'orange' }} />
          <p> Pn - Pt od 8:00 do 19:00 </p>
        </ContantImgWrapper>
      </ContactWrapper>
      {!isSubmited ? (
        <ContactFormWrapper>
          <FormWrapper>
            <StyledInput
              type="text"
              name="name"
              id="name"
              placeholder="Jan "
              onChange={e => setName(e.target.value)}
            />
            <StyledInput
              type="text"
              name="lastname"
              id="lastname"
              placeholder=" Kowalski"
              onChange={e => setLastName(e.target.value)}
            />
          </FormWrapper>
          <FormWrapper>
            <StyledInput
              type="text"
              name="email"
              id="email"
              placeholder="jankowalski@gmail.com"
              onChange={e => setEmail(e.target.value)}
            />
            <StyledInput
              type="text"
              name="phone"
              id="phone"
              placeholder="+48795123123"
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </FormWrapper>
          <FormWrapper>
            <label>Podaj datę nakazu</label>
            <StyledInput
              type="date"
              name="name"
              id="date"
              onChange={e => setDate(e.target.value)}
            />
          </FormWrapper>
          <FormWrapper>
            <StyledTextarea
              name="opis"
              placeholder="opisz swoją sprawę"
              onChange={e => setOpis(e.target.value)}
            />
          </FormWrapper>
          <CheckboxWrapper>
            <label htmlFor="fileInput">
              <MdFilePresent style={{ height: '24px', width: '24px' }} /> Dodaj pliki
            </label>
            <input
              id="fileInput"
              type="file"
              style={{ display: 'none' }}
              multiple
              onChange={e => setFiles([...e.target.files])}
            />
            {files && files.map(x => <p key={x.name + '123'}>{x.name}</p>)}
          </CheckboxWrapper>
          <CheckboxWrapper>
            <input
              type="checkbox"
              id="regulamin"
              checked={checkOne}
              onChange={() => setCheckOne(!checkOne)}
            />
            <label htmlFor="regulamin">Akceptuję Regulamin i Polityke prywatności strony.</label>
          </CheckboxWrapper>

          {checkError && (
            <p style={{ marginInline: 'auto', color: 'red', fontSize: '16px' }}>
              musisz zaakceptować regulamin, politykę prywatności <br />
            </p>
          )}
          <SubmitFormButton type="click" onClick={e => handleSubmit(e)}>
            WYŚLIJ <MdArrowCircleUp style={{ height: '24px', width: '24px' }} />
          </SubmitFormButton>
        </ContactFormWrapper>
      ) : (
        <div style={{ minHeight: '200px', marginInline: 'auto' }}>
          <p style={{ marginInline: 'auto', color: 'green', fontSize: '34px' }}>
            Dziękujemy za zgloszenie
          </p>
        </div>
      )}
    </>
  );
};

export default Contact;
