import styled from 'styled-components';
import Image from '../../assets/blog/art13.jpg';
import { basicRoute } from '../../routes/routes';
import { useLocation } from 'react-router';
import { Header } from '../../components/BlogTextHeder';
import { useEffect } from 'react';
import { Akapit } from '../../components/Akapit/Akapit';
const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Details = {
  head: ' Jak Prezes Urzędu Ochrony Konkurencji i Konsumentów pomaga osobom zadłużonym?',
  bigImage: Image,
  text: ' ',
  tableOfContents: [
    {
      id: '1',
      text: 'Kim jest Prezes Urzędu Ochrony Konkurencji i Konsumentów?     ',
    },
    {
      id: '2',
      text: '  Sprawy przeciwko firmom windykacyjnym i pożyczkowym',
    },
    {
      id: '3',
      text: '  Czy UOKIK zawsze Ci pomoże?',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`; 
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit /> Wielu dłużników uważa, że w starciu z firmami windykacyjnymi takimi jak
          Prokura, GetBack, Ultimo, Kredyt Inkaso czy z firmami pożyczkowymi jak Profi Credit, Yes
          Finance są zdani tylko na siebie i stoją na przegranej pozycji.<b> Nic z tych rzeczy! </b>
          Osoba potrzebująca pomocy w walce z wyżej wymienionymi podmiotami może zgłosić się do nas
          po bezpłatną analizę sprawy oraz o pomoc prawną. Po swojej stronie ma również
          sprzymierzeńca w postaci Prezesa Urzędu Ochrony Konkurencji i Konsumentów. Choć trzeba
          dodać, że <b>ten organ z reguły nie zajmuje się pojedynczymi sprawami.</b>{' '}
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>Spis treści :</strong>
        </p>
        <ol style={{ maringLeft: '20px' }}>
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <strong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </strong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper>
        <ol style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li id="1">
              <Header> Kim jest Prezes Urzędu Ochrony Konkurencji i Konsumentów?</Header>
              <StyledP>
                <Akapit /> Prezes Urzędu Ochrony Konkurencji i Konsumentów (w skrócie Prezes UOKIK)
                jest centralnym organem administracji państwowej podległym Radzie Ministrów, którego
                celem jest przeciwdziałanie praktykom monopolistycznym oraz ochrona interesów
                konsumentów np. osób fizycznych zaciągających pożyczki. Głównymi działaniami Prezesa
                UOKIK w zakresie antywindykacji jest prowadzenie postępowań w sprawach o{' '}
                <strong>uznanie postanowień wzorca umowy za niedozwolone </strong> (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog12}
                  style={{ color: 'orange' }}
                >
                  klauzule abuzywne
                </a>
                ), a co za tym idzie do prowadzenia{' '}
                <strong>rejestru klauzul niedozwolonych.</strong> Oprócz tego UOKIK prowadzi również
                postępowań w sprawach praktyk naruszających zbiorowe interesy konsumentów, które
                zazwyczaj doprowadzają do nałożenia wysokich kar finansowych na nieuczciwe firmy.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="2">
              <Header> Sprawy przeciwko firmom windykacyjnym i pożyczkowym</Header>
              <StyledP>
                <Akapit /> Prezes UOKIK podejmuje postępowania w zakresie działania firm
                udzielających kredytów, pożyczek oraz tzw. „chwilówek”. Za swoje niezgodne z prawem
                działania ukaranych zostało już wiele firm pożyczkowych między innymi Profi Credit.
                Ta firma nie udzielała bowiem konsumentom rzetelnych informacji o warunkach
                wypowiedzenia umowy oraz{' '}
                <strong>
                  naliczała dodatkowe opłaty, które nierzadko przewyższały kwotę zaciągniętej
                  pożyczki.
                </strong>{' '}
                Nie jest to jednak jedyna firma, na którą UOKIK nałożył karę. Pod lupę zostały
                również wzięte <strong>nieuczciwe praktyki</strong> Providenta, Incredit czy
                Wonga.pl.
                <br /> <Akapit />
                Sprawy automatycznych refinansowań (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog11}
                  style={{ color: 'orange' }}
                >
                  refinansowanie
                </a>
                ) również została zauważone przez UOKIK, który oskarżył Szybka Gotówka, Gwarant24
                oraz Centrum Rozwiązań Kredytowych o{' '}
                <strong>wielokrotne automatyczne “rolowanie” niespłaconych pożyczek</strong> przez
                co konsumenci zmuszeni byli do zaciągania kolejnych pożyczek na spłatę wciąż
                rosnącego zadłużenia. <br /> <Akapit />
                UKOIK zajmował się również nieuczciwymi praktykami firm windykacyjnych jak
                przykładowo GetBack (obecnie Asseta), któremu zarzucono, że egzekwował należności{' '}
                <strong>niezgodnie z prawem.</strong> Do jego przewinień należało stosowanie
                przymusu i presji polegające na{' '}
                <strong>uporczywym dzwonienieniu, smsowaniu w sprawie długu</strong> czy wysyłanie
                wezwań do zapłaty, w których GetBack umieszczał godło państwowe, co sprawiało, żę
                dłużnicy byli przekonani, iż pisze do nich jakiś państwowy organ (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog21}
                  style={{ color: 'orange' }}
                >
                  tricki i nieczyste zagrywki firm windykacyjnych
                </a>
                ). Ponadto firma ta wprowadzała w błąd konsumentów poprzez używanie nazwy Rzecznik
                Praw Dłużnika.
                <strong>Działania te kosztowały GetBack ponad 5 mln złotych.</strong>
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="3">
              <Header> Czy UOKIK zawsze Ci pomoże?</Header>
              <StyledP>
                <Akapit /> Osoby zadłużone nie są zdane tylko na siebie w walce o swoje prawa z
                firmami windykacyjnymi i pożyczkodawcami, mają bowiem po swojej stronie organ
                państwowy jakim jest Prezes Urzędu Ochrony Konkurencji i Konsumentów. Trzeba jednak
                mieć na uwadze, że postępowania wszczynane przez UOKIK nie są prowadzone w{' '}
                <strong>indywidualnych sprawach</strong>, przez co pokrzywdzeni konsumenci i{' '}
                <strong>tak muszą samodzielnie podejmować walkę przed sądem</strong> (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog21}
                  style={{ color: 'orange' }}
                >
                  tricki i nieczyste zagrywki firm windykacyjnych
                </a>
                ), co najwyżej powołując się na działania podjęte przez omawiany organ.{' '}
                <strong>Należy również wskazać, że działania tego organu są spóźnione</strong>, co w
                konsekwencji powoduje, że firm windykacyjne, pożyczkowe oraz tzw. parabanki jak np.
                Szybka Gotówka, Gwarant24 mogły bez przeszkód przez kilka lat prowadzić swoje
                niezgodne z prawem działania polegające na refinansowaniu pożyczek, dochodzeniu
                zawyżonych kwot przez sądem i egzekwowaniu ich za pośrednictwem komornika (czyt.
                także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog22}
                  style={{ color: 'orange' }}
                >
                  jak działa komornik
                </a>
                ).
                <br /> <Akapit />
                Dlatego w sytuacji otrzymania nakazu zapłaty czy pisma od komornika warto zgłosić
                się do doświadczonego prawnika, którzy bezpłatnie przeanalizują sprawę oraz
                zaproponują pomoc w anulowaniu długu czy prowizji bez oczekiwania na działania
                organów państwowych. Jeśli chcesz walczyć i zwyciężyć/wygrać z Krukiem, Bestem,
                Profi Credit i innymi, zgłoś się do nas. Pomożemy!
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
