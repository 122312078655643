import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Dlugi from '../../../assets/Dlugi.png';
import { navLinks } from '../../../constants/navLinks';
import FreeAdviceButton from '../../FreeAdviceButton';

const StyledNav = styled.nav`
  display: none;
  @media (min-width: 900px) {
    display: block;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: 300px;
`;

const MenuWrapper = styled.ul`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  gap: 1.5rem;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.dark};
  position: relative;
  &.active {
    color: ${({ theme }) => theme.colors.darkBlue};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    &:before {
      content: '';
      position: absolute;
      border-top: 2px solid ${({ theme }) => theme.colors.lightBlue};
      top: -5px;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
`;

const NavBar = () => {
  return (
    <StyledNav>
      <MenuWrapper>
        <li>
          <StyledNavLink to={navLinks[0].route}>{navLinks[0].text}</StyledNavLink>
        </li>
        <li>
          <StyledNavLink to={navLinks[1].route}>{navLinks[1].text}</StyledNavLink>
        </li>
        <li style={{ maxWidth: '270px', minWidth: '200px' }}>
          <StyledImage src={Dlugi} alt="sprzeciwdlugom.pl logo" />
        </li>
        <li>
          <StyledNavLink to={navLinks[2].route}>{navLinks[2].text}</StyledNavLink>
        </li>
        <li>
          <StyledNavLink to={navLinks[3].route}>{navLinks[3].text}</StyledNavLink>
        </li>
        <li>
          <StyledNavLink to={navLinks[4].route}>{navLinks[4].text}</StyledNavLink>
        </li>

        <li>
          <FreeAdviceButton text="DARMOWA PORADA" />
        </li>
      </MenuWrapper>
    </StyledNav>
  );
};

export default NavBar;
