import { useState } from 'react';
import { filter } from '../../actions/registrations';
import { useDispatch } from 'react-redux';

export const FilterForm = () => {
  const [name, setName] = useState(undefined);
  const [lastname, setLastname] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [tag, setTag] = useState('empty');
  const [status, setStatus] = useState(undefined);
  const [date, setDate] = useState(undefined);

  const dispatch = useDispatch();
  const handleSubmit = async e => {
    e.preventDefault();
    console.log(tag);
    if (tag === 'empty') {
      await dispatch(filter(phoneNumber, date, [], status, email, name, lastname));
    } else {
      await dispatch(filter(phoneNumber, date, tag, status, email, name, lastname));
    }
  };
  return (
    <div>
      <form onSubmit={e => handleSubmit(e)}>
        <label>imie:</label>
        <input type="text" value={name} onChange={e => setName(e.target.value)} />
        <label>nazwisko:</label>
        <input type="text" value={lastname} onChange={e => setLastname(e.target.value)} />
        <label>email:</label>
        <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
        <br />
        <label>numer telefonu:</label>
        <input type="number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
        <label>tag:</label>
        <select value={tag} onChange={e => setTag(e.target.value)}>
          <option selected value="empty">
            brak
          </option>
          <option value="mateusz">mateusz</option>
          <option value="olek">olek</option>
          <option value="krystian">krystian</option>
        </select>
        <label>status</label>
        <select value={status} onChange={e => setStatus(e.target.value)}>
          <option value="active">nie rozpatrzone</option>
          <option value="done">skoczone </option>
          <option value="inProgress">w trakcie</option>
        </select>
        <br />
        <label>data otrzymania nakazu</label>
        <input type="date" value={date} onChange={e => setDate(e.target.value)} />
        <button type="submit"> szukaj</button>
      </form>
    </div>
  );
};
