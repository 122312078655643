export const theme = {
  colors: {
    darkBlue: '#38587E',
    lightBlue: '#A6C4E0',
    dark: '#0F0F0F',
    white: '#F7F7F7',
    orange: '#FF7924',
    background: '#F1F1F1',
    konfederacja: '#FFB630',
  },
  border: {
    radius: '12px',
  },
  fontFamily: {
    global: `'Open Sans', sans-serif`,
  },
  fontWeight: {
    extraLight: 300,
    light: 400,
    medium: 500,
    bold: 700,
  },
  fontSize: {
    xs: '.75rem',
    s: '.875rem',
    m: '1rem',
    ml: '1.25rem',
    l: '1.5rem',
    xl: '2rem',
    xxl: '2.5rem',
    xxxl: '3rem',
  },
  iconSize: {
    s: '1rem',
    m: '1.25rem',
    l: '1.5rem',
  },
};
