import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { MdFilePresent, MdArrowCircleUp, MdClose } from 'react-icons/md';
import { SubmitFormButton } from '../Buttons/submitFormButton';
import { useDispatch } from 'react-redux';
import { changeVisibility } from '../../actions/freeAdvice';
import { useRef } from 'react';
import { BASE_URL } from '../../constants/api';
import axios from 'axios';
const MegaWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 999999;
  background: rgba(15, 15, 15, 0.5);
  display: grid;
  place-items: center;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  border: 2px solid ${({ theme }) => theme.colors.darkBlue};
  border-radius: ${({ theme }) => theme.border.radius};
  gap: 2rem;
  padding: 1rem;
`;

const XButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xl};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.orange};
  border: 2px solid ${({ theme }) => theme.colors.darkBlue};
  border-radius: ${({ theme }) => theme.border.radius};
`;

const StyledH3Wrapper = styled.div`
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  padding: 1rem 1rem;
  border-radius: 1em;
`;

const ContactFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-inline: auto;
`;
const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
`;
const StyledInput = styled.input`
  width: 100%;
  border-radius: ${({ theme }) => theme.border.radius};
  padding: 1rem 1rem;
  border: 2px solid transparent;
  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.darkBlue};
  }
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.border.radius};
  border: 2px solid transparent;
  resize: none;

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.darkBlue};
  }
`;
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const FreeAdviceForm = () => {
  const [name, setName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [files, setFiles] = useState(null);
  const [opis, setOpis] = useState('');
  const [date, setDate] = useState('');
  const [checkOne, setCheckOne] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [checkError, setCheckEror] = useState(false);
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickEsc = e => {
      if (e.key === 'Escape') dispatch(changeVisibility());
    };

    const handleClickOutside = e => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        dispatch(changeVisibility());
      }
    };
    document.addEventListener('keydown', handleClickEsc, false);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleClickEsc, false);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dispatch, wrapperRef]);
  useEffect(() => {
    if (checkOne) {
      setCheckEror(false);
      // setCheckFileError(false);
    }
  }, [checkOne]);

  const handleSubmit = async e => {
    e.preventDefault();

    if (checkOne === false) {
      setCheckEror(true);
      // setCheckFileError(true);
    } else {
      var formData = new FormData();
      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append(`files`, files[i]);
        }
      }
      formData.append(
        'body',
        JSON.stringify({
          name,
          lastname,
          email,
          phoneNumber,
          opis,
          date,
        }),
      );
      const res = await axios({
        method: 'post',
        url: `${BASE_URL}/test`,
        data: formData,
        header: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res.status === 200) {
        setIsSubmited(true);
      } else {
        //set file error
      }
    }
  };

  return createPortal(
    <MegaWrapper>
      <Wrapper ref={wrapperRef} onSubmit={handleSubmit}>
        <XButton onClick={() => dispatch(changeVisibility())}>
          <MdClose />
        </XButton>
        <StyledH3Wrapper>
          <h3>DARMOWA PORADA</h3>
        </StyledH3Wrapper>
        {!isSubmited ? (
          <ContactFormWrapper>
            <FormWrapper>
              <StyledInput
                type="text"
                name="name"
                id="name"
                placeholder="Jan "
                onChange={e => setName(e.target.value)}
              />
              <StyledInput
                type="text"
                name="lastname"
                id="lastname"
                placeholder=" Kowalski"
                onChange={e => setLastName(e.target.value)}
              />
            </FormWrapper>
            <FormWrapper>
              <StyledInput
                type="email"
                name="email"
                id="email"
                placeholder="jankowalski@gmail.com"
                onChange={e => setEmail(e.target.value)}
              />
              <StyledInput
                type="phone"
                name="phone"
                id="phone"
                placeholder="+48795123123"
                onChange={e => setPhoneNumber(e.target.value)}
              />
            </FormWrapper>
            <FormWrapper>
              <label>Podaj datę nakazu</label>
              <StyledInput
                type="date"
                name="name"
                id="date"
                onChange={e => setDate(e.target.value)}
              />
            </FormWrapper>
            <FormWrapper>
              <StyledTextarea
                name="opis"
                placeholder="opisz swoją sprawę"
                onChange={e => setOpis(e.target.value)}
              />
            </FormWrapper>
            <CheckboxWrapper>
              <label htmlFor="fileInput">
                <MdFilePresent style={{ height: '24px', width: '24px' }} /> Dodaj pliki
              </label>
              <input
                id="fileInput"
                type="file"
                style={{ display: 'none' }}
                multiple={true}
                onChange={e => setFiles([...e.target.files])}
              />
              {files && files.map(x => <p key={x.name + '123'}>{x.name}</p>)}
            </CheckboxWrapper>
            <CheckboxWrapper>
              <input
                type="checkbox"
                id="regulamin"
                checked={checkOne}
                onChange={() => setCheckOne(!checkOne)}
              />
              <label htmlFor="regulamin">Akceptuję Regulamin i Polityke prywatności</label>
            </CheckboxWrapper>
            {checkError && (
              <p style={{ marginInline: 'auto', color: 'red', fontSize: '16px' }}>
                musisz zaakceptować regulamin, politykę prywatności <br />
              </p>
            )}
            <SubmitFormButton type="submit">
              WYŚLIJ <MdArrowCircleUp style={{ height: '24px', width: '24px' }} />
            </SubmitFormButton>
          </ContactFormWrapper>
        ) : (
          <div style={{ minHeight: '200px', marginInline: 'auto' }}>
            <p style={{ marginInline: 'auto', color: 'green', fontSize: '34px' }}>
              Dziękujemy za zgloszenie
            </p>
          </div>
        )}
      </Wrapper>
    </MegaWrapper>,
    document.body,
  );
};

export default FreeAdviceForm;
