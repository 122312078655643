import { useState } from 'react';
import styled from 'styled-components';
import { SubmitFormButton } from '../components/Buttons/submitFormButton';
import { MdArrowCircleUp } from 'react-icons/md';
import { SignIn } from '../actions/login';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  align-self: center;
`;

const ContactFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-inline: auto;
  min-width: 340px;
  min-height: 400px;
`;
const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
`;
const StyledInput = styled.input`
  width: 100%;
  border-radius: ${({ theme }) => theme.border.radius};
  padding: 1rem 1rem;
  border: 2px solid transparent;
`;

const Login = () => {
  const history = useHistory();
  const [useranme, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [wrongEmailOrPassowrd, setWrongEmailOrPassword] = useState(false);
  const dispatch = useDispatch();

  const handleUserNameChange = e => {
    setUsername(e.target.value);
  };
  const handlePassowrdChange = e => {
    setPassword(e.target.value);
  };
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const req = await dispatch(SignIn(useranme, password));

      if (req.status === 200) {
        history.push('/admin');
      }
    } catch (error) {
      setWrongEmailOrPassword(true);
    }
  };
  return (
    <>
      <StyledH1>Zaloguj się</StyledH1>

      <ContactFormWrapper>
        <FormWrapper onSubmit={e => handleSubmit(e)}>
          <StyledInput
            type="text"
            name="username"
            id="username"
            required
            placeholder="jankowalski@gmail.com"
            onChange={e => handleUserNameChange(e)}
          />
        </FormWrapper>
        <FormWrapper>
          <StyledInput
            type="password"
            name="password"
            id="password"
            required
            placeholder="password"
            onChange={e => handlePassowrdChange(e)}
          />
        </FormWrapper>
        {wrongEmailOrPassowrd && <p style={{ color: 'red' }}>zle haslo lub email</p>}
        <SubmitFormButton type="click" onClick={e => handleSubmit(e)}>
          zaloguj <MdArrowCircleUp style={{ height: '24px', width: '24px' }} />
        </SubmitFormButton>
      </ContactFormWrapper>
    </>
  );
};

export default Login;
