import styled from 'styled-components';
import Image from '../../assets/blog/art12.jpg';
import { basicRoute } from '../../routes/routes';
import { useLocation } from 'react-router';
import { Header } from '../../components/BlogTextHeder';
import { useEffect } from 'react';
import { Akapit } from '../../components/Akapit/Akapit';
const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Details = {
  head: 'Refinansowanie pożyczki. Jak się nie dać złapać w pętlę kredytową? ',
  bigImage: Image,
  text: 'Osoby zadłużone często opisują, rozmowy z windykatorami firmy BEST, Ultimo czy Kruka  w trakcie, których są informowani, że firma windykacyjna kupiła dług albo, że komornik wszczął egzekucję na jej wniosek (czyt. także podlinkowany art. nr 2) i to pomimo tego, że nigdy nie zawierały żadnej umowy z taką firmą. Zdarza się również, że jesteśmy informowani o przypadkach, w których dłużnik otrzymuje nakaz z Eques Debitum, Kredyt Inkaso, choć nigdy nie słyszał o takiej firmie. W tym artykule wyjaśniamy jak dochodzi do takich zdarzeń, w jaki sposób firmy sprzedają sobie nawzajem długi, czy jest to zgodne z prawem oraz jak wyglądają powiązania firm pożyczkowych oraz firm windykacyjnych.  ',
  tableOfContents: [
    {
      id: '1',
      text: 'Co to jest refinansowanie ? ',
    },
    {
      id: '2',
      text: ' Zagrożenia, które niesie refinansowanie ',
    },
    {
      id: '3',
      text: '  Postępowania UOKIK w sprawie refinansowań',
    },
    {
      id: '4',
      text: '  Działalność tzw. firm “oddłużeniowych”',
    },
    {
      id: '5',
      text: ' Podsumowanie',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`; 
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit /> Pożyczki-chwilówki umożliwiają szybkie uzyskanie potrzebnej ilości gotówki. Są
          najczęściej zawierane na krótki okres i nie zawsze pożyczkobiorca jest ją w stanie spłacić
          w przewidzianym terminie z uwagi na dodatkowe koszty w postaci prowizji, które są
          naliczane przez podmioty je udzielające (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog12} style={{ color: 'orange' }}>
            klauzule abuzywne
          </a>
          ). Wielu pożyczkodawców np. NetCredit, Pożyczka Plus, Wandoo, Yes Finance, Szybka Gotówka,
          Ducatos, Centrum Rozwiązań Kredytowych, Finbo w przypadku opóźnień w spłacie oferuje
          możliwość refinansowania zaległości kolejną pożyczką. Jest to stosunkowo nowa oferta dla
          pożyczkobiorców, dlatego warto wiedzieć co to jest oraz jakie są zagrożenia wielokrotnego
          refinansowania niespłaconej pożyczki.
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>spis treści :</strong>
        </p>
        <ol style={{ maringLeft: '20px' }}>
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <strong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </strong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper>
        <ol style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li id="1">
              <Header> Co to jest refinansowanie</Header>

              <StyledP>
                <Akapit /> Refinansowanie to usługa firm pożyczkowych dla osób, które nie są w
                stanie we wskazanym w umowie terminie (30 dni, 60 dni) spłacić chwilówki. Dokonanie
                refinansowania umożliwia „wydłużenie” terminu spłaty. Polega to na tym, że udzielana
                jest nowa pożyczka, zazwyczaj przez inny podmiot współpracujący z pierwotnym
                wierzycielem (Netcredit {'->'} Adcredit, Szybka Gotówka {'->'} Centrum Rozwiązań
                Kredytowych, Ducatos{'->'}
                Primastar), która przeznaczana jest na terminową spłatę pierwszej pożyczki. Warto
                mieć na uwadze, że pożyczka refinansująca jest udzielana w takiej samej wysokości
                jak poprzednia pożyczka, z której spłatą się zalega. Osoba korzystająca z
                refinansowania nie otrzymuje więc żadnych pieniędzy na swoje konto, ponieważ środki
                są przelewane między dwiema firmami pożyczkowymi. W momencie przelewu pierwotna
                pożyczka zostaje rozliczona, a pożyczkobiorca ma do spłaty nową pożyczkę z
                późniejszym terminem spłaty i z kolejną, dużą prowizją.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="2">
              <Header> Zagrożenia, które niesie refinansowanie</Header>

              <StyledP>
                <Akapit />
                Warto mieć na względzie fakt, że pożyczki refinansujące nie są darmowe i zamiast
                umożliwić spłatę zadłużenia, bardzo często doprowadzają pożyczkobiorców do jeszcze
                większych problemów finansowych i tzw. “pętli zadłużeniowej”. Jak już wspomniano,
                wnioskowanie o pożyczkę refinansującą wiąże się także z koniecznością uiszczenia
                dodatkowej prowizji.
                <br />
                <Akapit /> Trzeba także wspomnieć, że wiele firm pożyczkowych już w chwili
                zawierania pierwotnej umowy zastrzega sobie, możliwość automatycznego refinansowania
                zadłużenia w przypadku zwłoki w spłacie. Opisane działanie firm pożyczkowych nie ma
                na celu pomocy w spłacie długu, a zwiększenie zadłużenia. Refinansowanie nie usuwa
                problemów finansowych, a odroczenie spłaty prowadzi do kolejnych kosztów, co w
                perspektywie kilku miesięcy skutkuje zdecydowanym zwiększeniem kwoty do spłaty. W
                efekcie suma opłat za kolejne refinansowania może przekroczyć kwotę kapitału
                pierwotnej pożyczki. Taka celowa działalność firm pożyczkowych nazywana jest
                “rolowaniem pożyczki”.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="3">
              <Header>Postępowania UOKIK w sprawie refinansowań?</Header>

              <StyledP>
                <Akapit />
                Problem rolowania kredytów został również zauważony przez Prezesa Urzędu Ochrony
                Konkurencji i Konsumentów (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog13}
                  style={{ color: 'orange' }}
                >
                  UOKIK
                </a>
                ) , który w sierpniu 2021 roku postawił zarzuty naruszenia zbiorowych interesów
                konsumentów firmom pożyczkowym: Szybka Gotówka, Gwarant24 oraz Centrum Rozwiązań
                Kredytowych (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog10}
                  style={{ color: 'orange' }}
                >
                  Cesja wierzytelności. O powiązaniach między pożyczkodawcami i firmami
                  windykacyjnymi
                </a>
                ), które działają na portalach Szybkagotowka.pl oraz Freezl.pl. Firmy te udzielały
                naprzemiennie między sobą pożyczek refinansujących, co naruszało przepisy art. 36a i
                art. 36b ustawy z dnia 12 maja 2011 r. o kredycie konsumenckim. Podobne zarzuty
                zostały postawione innym spółkom zajmującym się nadużywaniem przepisów prawa,
                mianowicie Yes Finance, Liberis Polska, Duo Finance oraz Primus Finance.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="4">
              <Header> Działalność tzw. firm “oddłużeniowych”</Header>

              <StyledP>
                <Akapit />
                Kolejnym aspektem, na pierwszy rzut oka mniej oczywistym, niż omówiony już schemat
                klasycznego “rolowania pożyczki” jest działalność tzw. firm “oddłużeniowych”. Firmy
                te ogłaszają się jako oferujące “pomoc” w wyjściu z długów. W praktyce “pomoc”
                takich instytucji polega na udzielaniu kolejnych pożyczek na spłatę tych
                poprzednich, zaciągniętych w innych bankach, czy parabankach. Takie działanie
                prowadzi w istocie to <strong>
                  dalszego pogłębienia problemów z zadłużeniem.
                </strong>{' '}
                Kolejne “oddłużeniowe” pożyczki najczęściej obarczone są wysokimi prowizjami i w
                żaden sposób nie pozwalają na uniknięcie zapłaty przedawnionych i niezasadnych
                długów dochodzonych przez firmy windykacyjne i komorników.
                <br />
                <Akapit />
                Warto pamiętać, że działalność firm “oddłużeniowych” nie ma nic wspólnego ze
                skuteczną antywindykacją, którą jako prawnicy zajmujemy się zarówno na etapie
                polubownym, sądowym i komorniczym. Spłacanie pożyczek kolejnymi pożyczkami zawsze
                prowadzi bowiem do popadnięcia w “pętle kredytową”, nie prowadzi do utraty mocy
                nakazu zapłaty, umorzenia egzekucji komorniczej i zwiększa jedynie koszty spłaty
                niezasadnych długów, które przy{' '}
                <strong>odpowiedniej pomocy doświadczonych prawników mogą zostać anulowane.</strong>
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="5">
              <Header>Podsumowanie</Header>

              <StyledP>
                <Akapit />
                Refinansowanie jako usługa przedłużenia spłaty pożyczki może jednorazowo pomóc w
                uregulowaniu zadłużenia, natomiast trzeba pamiętać o jej odpłatności oraz możliwych
                konsekwencjach, które mogą się pojawić przy permanentnym zawieraniu kolejnych
                pożyczek refinansujących, tj przede wszystkim ze znacznym kosztem wzrostów pożyczki.
                Działania organów państwowych prowadzą do zwiększenia ochrony konsumentów, którzy
                łatwo stają się ofiarami „chwilówek”, jednakże wiele osób ciągle boryka się w
                pojedynkę z problemami niespłaconych refinansowań i horrendalnych kwot do zapłaty.
                <br /> <Akapit />
                Nasza wieloletnia działalność pokazuje, że z praktyką “rolowania pożyczki” można
                skutecznie walczyć przed sądem za pomocą złożenia odpowiedzi na pozew lub sprzeciwu
                od nakazu zapłaty (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog3}
                  style={{ color: 'orange' }}
                >
                  jak złożyć sprzeciw od nakazu zapłaty
                </a>
                ). Pomoc możliwa jest także jeśli sprawa w której padłeś ofiarą firmy pożyczkowej
                była już lub wciąż znajduje się u komornika (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog2}
                  style={{ color: 'orange' }}
                >
                  przywrócenie terminu jak odzczytać pisamo od komornika
                </a>
                ). Jeśli jesteś osobą wykorzystaną przez firmę pożyczkową to z pewnością
                potrzebujesz naszej pomocy. Skontaktuj się nami za pomocą formularza, telefonicznie
                lub mailowo. Przeprowadzimy darmową analizę twojej sprawy i anulujemy twój dług!
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
