import store from './store';
import GlobalStyle from './theme/GlobalStyles';
import MainTemplate from './templates/MainTemplate';
import CookieConsent from 'react-cookie-consent';
import Home from './views/Home';
import About from './views/About';
import Blog from './views/Blog';
import Contact from './views/Contact';
import Workflow from './views/Workflow';
import PrivacyPolicy from './views/PrivacyPolicy';
import Login from './views/Login';
import Admin from './views/Admin';
import { basicRoute } from './routes/routes';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme/theme';
import { changeVisibilityCookie } from './actions/cookie';
import Blog1 from './views/DetailsBlog/Blog1';
import Blog2 from './views/DetailsBlog/Blog2';
import Blog3 from './views/DetailsBlog/Blog3';
import Blog4 from './views/DetailsBlog/Blog4';
import Blog5 from './views/DetailsBlog/Blog5';
import Blog55 from './views/DetailsBlog/Blog55';
import Blog8 from './views/DetailsBlog/Blog8';
import Blog10 from './views/DetailsBlog/Blog10';
import Blog11 from './views/DetailsBlog/Blog11';
import Blog12 from './views/DetailsBlog/Blog12';
import Blog13 from './views/DetailsBlog/Blog13';
import Blog14 from './views/DetailsBlog/Blog14';
import Blog16 from './views/DetailsBlog/Blog16';
import Blog18 from './views/DetailsBlog/Blog18';
import Blog21 from './views/DetailsBlog/Blog21';
import Blog22 from './views/DetailsBlog/Blog22';
const Button = () => {
  const dispach = useDispatch();
  return (
    <span
      style={{
        marginLeft: '10px',
        color: '#FF7924',
        fontSize: '12px',
        cursor: 'pointer',
      }}
      onClick={() => dispach(changeVisibilityCookie())}
    >
      Ustawienia preferencji
    </span>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <CookieConsent
          style={{ backgroundColor: '#38587E' }}
          buttonStyle={{ backgroundColor: '#FF7924', color: 'white', fontSize: '16px' }}
          buttonText="Zgadzam się"
        >
          Strona korzysta z plików cookie w celu realizacji usług zgodnie z Polityką Prywatności.
          Jeśli klikniesz „zgadzam się” będziemy stosować wszystkie pliki cookies w tym
          marketingowe. Możesz również w zakładce ustawienia preferencji ustawić, które cookies
          chcesz nam udostępnić.
          <Button>try me</Button>
        </CookieConsent>
        <BrowserRouter>
          <MainTemplate>
            <Switch>
              <Route exact path="/glowna" render={() => <Redirect to={basicRoute.home} />} />
              <Route path={basicRoute.home} exact component={Home} />
              <Route path={basicRoute.blog} exact component={Blog} />
              <Route path={basicRoute.about} component={About} />
              <Route path={basicRoute.contact} component={Contact} />
              <Route path={basicRoute.workflow} component={Workflow} />
              <Route path={basicRoute.statute} component={PrivacyPolicy} />
              <Route path={basicRoute.privacyPolicy} component={PrivacyPolicy} />
              <Route path={basicRoute.blog1} component={Blog1} exact />
              <Route path={basicRoute.blog2} component={Blog2} exact />
              <Route path={basicRoute.blog3} component={Blog3} exact />
              <Route path={basicRoute.blog4} component={Blog4} exact />
              <Route path={basicRoute.blog5} component={Blog5} exact />
              <Route path={basicRoute.blog55} component={Blog55} exact />
              <Route path={basicRoute.blog8} component={Blog8} exact />
              <Route path={basicRoute.blog10} component={Blog10} exact />
              <Route path={basicRoute.blog11} component={Blog11} exact />
              <Route path={basicRoute.blog12} component={Blog12} exact />
              <Route path={basicRoute.blog13} component={Blog13} exact />
              <Route path={basicRoute.blog14} component={Blog14} exact />
              <Route path={basicRoute.blog16} component={Blog16} exact />
              <Route path={basicRoute.blog18} component={Blog18} exact />
              <Route path={basicRoute.blog21} component={Blog21} exact />
              <Route path={basicRoute.blog22} component={Blog22} exact />
              <Route path={basicRoute.login} component={Login} exact />
              <Route path={basicRoute.admin} component={Admin} exact />
            </Switch>
          </MainTemplate>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
