import axios from 'axios';
import { actionTypes } from '../constants/actionTypes';
import { BASE_URL } from '../constants/api';
export const SignIn = (useranme, password) => async dispatch => {
  try {
    const data = await axios.post(
      `${BASE_URL}/user/signIn`,
      {
        email: useranme,
        password: password,
      },
      { withCredentials: true },
    );
    if (data.status === 200) {
      dispatch({
        type: actionTypes.SIGNIN,
        payload: true,
      });
    }

    return data;
  } catch (error) {}
};
