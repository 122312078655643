import styled from 'styled-components';

const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;

const Details = {
  head: ' Polityka Ochrony Prywatności ',
  text: 'Wielu dłużników uważa, że w starciu z firmami windykacyjnymi takimi jak Prokura, GetBack, Ultimo, Kredyt Inkaso czy z firmami pożyczkowymi jak Profi Credit, Yes Finance są zdani tylko na siebie i   stoją na przegranej pozycji. Nic z tych rzeczy! Osoba potrzebująca pomocy w walce z wyżej wymienionymi podmiotami może zgłosić się do nas po bezpłatną analizę sprawy oraz o pomoc prawną. Po swojej stronie ma również   sprzymierzeńca w postaci Prezesa Urzędu Ochrony Konkurencji i Konsumentów. Choć trzeba dodać, że ten organ z reguły nie zajmuje się pojedynczymi sprawami.  ',
  tableOfContents: [],
};
   
const StyledP = styled.p`
  line-height: 30px;
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const First = () => {
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>

      <MainTextWrapper>
        <ol style={{ maringLeft: '20px' }}>
          <article style={{ marginTop: '15px' }}>
            <h1 style={{ marginBottom: '10px', marginTop: '10px' }}> I. Zakres obowiązywania</h1>

            <li>
              <ol>
                <li>
                  <StyledP>
                    Niniejsza polityka jest skierowana jest do osób korzystających z Serwisu, dalej{' '}
                    <strong>"Użytkownik Serwisu"</strong>
                  </StyledP>
                </li>
                <li>
                  <StyledP>
                    Administratorem danych jest Kancelaria radcy prawnego Jacek Kołodziejczyk, Aleja
                    Pokoju 7, skr. Poczt. 5, 42-200 Częstochowa.
                  </StyledP>
                </li>
                <li>
                  <StyledP>
                    Celem tej Polityki Prywatności jest określenie działań podejmowanych przez
                    Administratora danych w zakresie ochrony danych osobowych przetwarzanych, w tym
                    zbieranych za pośrednictwem strony internetowej dalej jako „Serwis”.
                  </StyledP>
                </li>
                <li>
                  <StyledP>
                    Dane przetwarzane są zgodnie z obowiązującymi przepisami prawa w tym
                    Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
                    2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
                    osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia
                    dyrektywy 95/46/WE. Dz.Urz.UE. L. 2016 Nr 119/1.
                  </StyledP>
                </li>
                <li>
                  <StyledP>
                    Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem
                    informacji zawartych w plikach cookies i logach serwera www w celach
                    analitycznych. Dodatkowe dane osobowe (np. adres e-mail, numer telefonu)
                    zbierane są jedynie w miejscach, w których użytkownik wypełniając formularz
                    wyraźnie wyraził na to zgodę. Wyrażenie zgody jest dobrowolne.
                  </StyledP>
                </li>
              </ol>
              <h1 style={{ marginBottom: '30px', marginTop: '10px' }}>
                II. Przetwarzanie danych osobowych przez Kancelarię radcy prawnego Jacek
                Kołodziejczyk, w tym ich pozyskiwanie i przechowywanie
              </h1>
              <h3 style={{ marginBottom: '30px', marginTop: '10px' }}>PLIKI COOKIES</h3>
              <StyledP>
                Serwis używa cookies. Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne,
                w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym
                Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu.
                Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas
                przechowywania ich na urządzeniu końcowym oraz unikalny numer. Podmiotem
                zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz
                uzyskującym do nich dostęp jest operator Serwisu.
              </StyledP>
              <StyledP style={{ marginBottom: '30px', marginTop: '10px' }}>
                <b>W ramach Serwisu stosowane są następujące rodzaje plików cookies:</b>
              </StyledP>
              <ul>
                <li>
                  <StyledP>
                    pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do
                    wykrywania nadużyć w zakresie uwierzytelniania w ramach serwisu;
                  </StyledP>
                </li>
                <li>
                  <StyledP>
                    „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie
                    korzystania ze stron internetowych serwisu,
                  </StyledP>
                </li>
                <li>
                  <StyledP>
                    „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez
                    użytkownika ustawień i personalizację interfejsu użytkownika, np. w zakresie
                    wybranego języka lub regionu, z którego pochodzi użytkownik, rozmiaru czcionki,
                    wyglądu strony internetowej itp.;
                  </StyledP>
                </li>
                <li>
                  <StyledP>
                    „analityczne” oraz „marketingowe” pliku cookies, które umożliwiają nam
                    dopasowanie reklamy do Państwa preferencji.
                  </StyledP>
                </li>
              </ul>
              <StyledP style={{ marginBottom: '30px', marginTop: '10px' }}>
                <b>Serwis stosuje pliki cookies:</b>
              </StyledP>
              <ul>
                <li>
                  <StyledP>
                    w celu udostępniana Użytkownikom treści gromadzonych w Serwisie;
                  </StyledP>
                </li>
                <li>
                  <StyledP>w celach statystycznych. Analiza tych statystyk jest anonimowa.</StyledP>
                </li>
                <li>
                  <StyledP>
                    w celu zapamiętywania indywidualnych ustawień Użytkownika i optymalizacji
                    korzystania z Serwisu.
                  </StyledP>
                </li>
                <li>
                  <StyledP>
                    dostosowania zawartości Witryn do urządzenia końcowego Użytkownika
                  </StyledP>
                </li>
                <li>
                  <StyledP>
                    uzyskiwania zbiorczych, anonimowych danych statystycznych o sposobie korzystania
                    z Serwisu celem ulepszania struktury i funkcjonalność Serwisu
                  </StyledP>
                </li>
                <li>
                  <StyledP>
                    poprawy bezpieczeństwa, poprzez kontrolę nadużyć w procesie korzystania z
                    Serwisu,
                  </StyledP>
                </li>
                <li>
                  <StyledP>prowadzenia działań marketingowych.</StyledP>
                </li>
              </ul>
              <StyledP style={{ marginBottom: '30px', marginTop: '10px' }}>
                Dane osobowe gromadzone przy użyciu plików „cookies” mogą być przetwarzane wyłącznie
                w celu wykonywania określonych funkcji na rzecz użytkownika, opisanych powyżej.
                Takie dane są zabezpieczone w sposób uniemożliwiający dostęp do nich osobom
                nieuprawnionym.
                <br />
                <br />
                Uprawnienie Kancelarii radcy prawnego Jacek Kołodziejczyk do przechowywania i
                uzyskiwania dostępu do plików „cookies” wynika z uzasadnionego interesu
                administratora względnie dorozumianej zgody wyrażonej przez użytkownika strony
                internetowej. Dorozumiana zgoda jest wyrażana przez użytkownika podczas dokonywania
                konfiguracji przeglądarki internetowej lub wybranej strony internetowej (lub usługi)
                oraz przez wejście na stronę w zakresie zapisu plików niezbędnych do funkcjonowania
                Serwisu
                <br />
                <br />
                W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne”
                (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami
                tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu
                wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania
                (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu
                końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu
                ich usunięcia przez Użytkownika. Przeglądarka internetowa umożliwia usunięcie plików
                cookies. Możliwe jest także automatyczne blokowanie plików cookies. Szczegółowe
                informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.
                Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności
                dostępne na stronach internetowych Serwisu
                <br />
                <br />
                <b>Facebook.</b> Korzystamy z narzędzi marketingowych dostępnych w ramach serwisu
                Facebook i dostarczanych przez Facebook Ireland Ltd., 4 Grand Canal Square, Grand
                Canal Harbour, Dublin 2, Irlandia („Facebook“). W ramach tych narzędzi kierujemy do
                Państwa reklamy w serwisie Facebook. Działania w tym zakresie realizujemy, opierając
                się na naszym prawnie uzasadnionym interesie w postaci marketingu własnych
                produktów.
                <br />
                <br />
                W celu kierowania do Państwa reklam spersonalizowanych pod kątem Państwa zachowań na
                naszej Stronie, korzystamy z Pixel Facebooka, który w sposób automatyczny gromadzi
                informacje o Państwa korzystaniu z naszej Strony w zakresie przeglądanych stron.
                <br />
                <br />
                Za pośrednictwem ww. technologii nie przekazujemy danych osobowych. Informacje
                zbierane w ramach Pixela Facebooka są anonimowe, tj. nie pozwalają nam na Państwa
                identyfikację. Pozwalają nam wyłącznie uzyskać informacje jakie działania zostały
                podjęte w ramach naszej Strony. Zwracamy jednak uwagę, że Facebook może łączyć te
                informacje z innymi informacjami o Państwu zebranymi w ramach korzystania przez
                Państwa z serwisu Facebook i wykorzystywać dla swoich własnych celów, w tym
                marketingowych. Obecnie Pixel Facebooka oddziałuje również na dane z Instagrama.
                Takie działania Facebooka nie są już zależne od nas, a informacji o nich możesz
                szukać bezpośrednio w polityce prywatności Facebooka:
                https://www.facebook.com/privacy. Z poziomu swojego konta na Facebooku możesz
                również zarządzać swoimi ustawieniami prywatności.
                <br />
                <br />
                Zalecamy przeczytanie polityki ochrony prywatności tych firm, aby poznać zasady
                korzystania z plików cookie wykorzystywane w statystykach:
              </StyledP>
              <h3 style={{ marginBottom: '30px', marginTop: '10px' }}>
                Polityka ochrony prywatności Google Analytics
              </h3>
              <StyledP style={{ marginBottom: '30px', marginTop: '10px' }}>
                Niniejszy serwis korzysta z Google Analytics- usługi analizy oglądalności stron
                internetowych udostępnianej przez Google, Inc. (“Google”). Google Analytics używa
                “cookies”, w celu umożliwienia witrynie przeanalizowania sposobu, w jaki użytkownicy
                z niej korzystają. Informacje generowane przez cookie na temat korzystania z witryny
                przez użytkownika (włącznie z jego adresem IP) będą przekazywane spółce Google i
                przez nią przechowywane. Więcej o polityce prywatności Google można znaleźć na
                stronie http://www.google.pl/intl/pl/policies/privacy. Korzystając z niniejszego
                serwisu, użytkownik wyraża zgodę na przetwarzanie przez Google dotyczących go danych
                w sposób i w celach określonych powyżej.
              </StyledP>
              <h3 style={{ marginBottom: '30px', marginTop: '10px' }}>
                Zarządzanie plikami cookies – jak w praktyce zablokować otrzymywanie plików cookies
                lub wycofać zgodę?
              </h3>
              <StyledP style={{ marginBottom: '30px', marginTop: '10px' }}>
                Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia
                przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla
                procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może
                utrudnić, a w skrajnych przypadkach może uniemożliwić korzystanie ze stron www. W
                większości dostępnych na rynku przeglądarek sposób wyłączenia plików cookie można
                opisać następująco:
              </StyledP>
              <ol>
                <li>Otwórz menu przeglądarki</li>
                <li>Wybierz Ustawienia</li>
                <li>Wybierz Ustawienia zaawansowane lub Bezpieczeństwo i Prywatność</li>
                <li>Ustaw blokadę na pliki cookie</li>
              </ol>
              <StyledP style={{ marginBottom: '30px', marginTop: '10px' }}>
                Możesz też dowiedzieć się jak dokładnie to zrobić w każdej popularnej przeglądarce
                (Chrome, FireFox, Opera, Edge, Internet Explorer i Safari) oraz na urządzeniach
                mobilnych (Android, Windows, iOS). W tym celu przejdź do tego działu: Więcej w
                pomocy kontekstowej dostępnej dla każdej przeglądarki lub pod adresem:
                http://jakwylaczyccookie.pl/
              </StyledP>
              <h3 style={{ marginBottom: '30px', marginTop: '10px' }}>Google Analytics</h3>
              <StyledP style={{ marginBottom: '30px', marginTop: '10px' }}>
                Użytkownik może wyłączyć przesyłanie informacji do tej usługi, instalując bezpłatny
                dodatek do przeglądarki internetowej, blokujący Google Analytics. Kliknij tutaj (
                https://tools.google.com/dlpage/gaoptout?hl=pl )
              </StyledP>
              <h3 style={{ marginBottom: '30px', marginTop: '10px' }}>
                FORMULARZE KONTAKTOWE i REJESTRACJA KONTA UŻYTKOWNIKA NA STRONIE INTERNETOWEJ
                sPrzeciwDlugom.pl
              </h3>
              <StyledP style={{ marginBottom: '30px', marginTop: '10px' }}>
                Serwis umożliwia kontakt z Administratorem przy użyciu formularzy kontaktowych
                dostępnych w Serwisie. Formularz wymaga podania danych osobowych niezbędnych do
                kontaktu zwrotnego i udzielenia odpowiedzi na zadane pytanie. Dane przetwarzane są w
                celach marketingowych, w identyfikacji użytkownika korzystającego z zapytania i
                obsługi zapytania. Podstawą przetwarzania danych jest zgoda Użytkownika (art.6 ust.1
                a RODO) albo prawnie uzasadniony interes administratora danych osobowych w postaci
                marketingu bezpośredniego (art. 6 ust. 1 pkt. f RODO). Wyrażenie zgody jest
                dobrowolne, lecz brak zgody może skutkować brakiem możliwości obsługi zapytania.
                Odbiorcami danych osobowych podanych w formularzach kontaktowych mogą być
                przedstawiciele handlowi współpracujący z Kancelarią radcy prawnego Jacek
                Kołodziejczyk.
                <br />W przypadku rejestracji konta w Serwisie, Użytkownik podaje:
              </StyledP>
              <ul>
                <li>
                  <StyledP>adres e-mail,</StyledP>
                </li>
                <li>
                  <StyledP>hasło do nowo utworzonego konta,</StyledP>
                </li>
                <li>
                  <StyledP>
                    dane adresowe: kod pocztowy, miejscowość oraz ulica wraz z numerem domu i
                    mieszkania
                  </StyledP>
                </li>
                <li>
                  <StyledP>imię i nazwisko</StyledP>
                </li>
                <li>
                  <StyledP>firma przedsiębiorcy</StyledP>
                </li>
                <li>
                  <StyledP>numer telefonu</StyledP>
                </li>
                <li>
                  <StyledP>data urodzenia</StyledP>
                </li>
              </ul>
              <StyledP>
                Podczas rejestracji konta w Serwisie Użytkownik samodzielnie ustala indywidualne
                hasło dostępu do swojego konta. Podczas składania zapytania w Serwisie, Użytkownik
                podaje następujące dane:
              </StyledP>
              <ul>
                <li>
                  <StyledP>adres e-mail,</StyledP>
                </li>

                <li>
                  <StyledP>
                    dane adresowe: kod pocztowy, miejscowość oraz ulica wraz z numerem domu i
                    mieszkania
                  </StyledP>
                </li>
                <li>
                  <StyledP>imię i nazwisko</StyledP>
                </li>
                <li>
                  <StyledP>firma przedsiębiorcy</StyledP>
                </li>
                <li>
                  <StyledP>numer telefonu</StyledP>
                </li>
                <li>
                  <StyledP>data urodzenia</StyledP>
                </li>
              </ul>
              <StyledP>
                W celu ustalenia, dochodzenia i egzekucji potencjalnych roszczeń mogą być
                przetwarzane niektóre dane osobowe podane przez Użytkownika w ramach korzystania z
                funkcjonalności w Serwisie takie jak: imię, nazwisko, dane dotyczące korzystania z
                usług, jeżeli roszczenia wynikają ze sposobu, w jaki Użytkownik korzysta z usług,
                inne dane niezbędne do udowodnienia istnienia roszczenia, w tym rozmiarów
                poniesionej szkody. Przekazanie danych osobowych Administratorowi jest dobrowolne w
                celu zawierania i realizacji umowy o świadczenie usług prawnych, z tym jednak
                zastrzeżeniem, że niepodanie określonych w formularzach danych w procesie
                Rejestracji uniemożliwia Rejestrację i założenie Konta Użytkownika, zaś w przypadku
                składania zapytania w Serwisie uniemożliwi złożenie i realizację zamówienia
                Użytkownika.
              </StyledP>
              <h3 style={{ marginBottom: '30px', marginTop: '10px' }}>LOGI SERWERA</h3>
              <StyledP>
                Informacje o niektórych zachowaniach użytkowników podlegają logowaniu w warstwie
                serwerowej. Dane te są wykorzystywane wyłącznie w celu administrowania serwisem oraz
                w celu zapewnienia jak najbardziej sprawnej obsługi świadczonych usług hostingowych.
                Przeglądane zasoby identyfikowane są poprzez adresy URL. Ponadto zapisowi mogą
                podlegać: <br /> - czas nadejścia zapytania,
                <br /> - czas wysłania odpowiedzi, <br />- nazwę stacji klienta
                <br /> – identyfikacja realizowana przez protokół HTTP,
                <br /> - informacje o błędach jakie nastąpiły przy realizacji transakcji HTTP, adres
                URL strony poprzednio odwiedzanej przez użytkownika (referer link)
                <br /> – w przypadku gdy przejście do Serwisu nastąpiło przez odnośnik,
                <br /> - informacje o przeglądarce użytkownika,
                <br /> - informacje o adresie IP.
                <br />
                <br />
                Dane powyższe nie są kojarzone z konkretnymi osobami przeglądającymi strony.
                <br />
                Dane powyższe są wykorzystywane jedynie dla celów administrowania serwerem.
                <br />
                <br />
              </StyledP>

              <h1 style={{ marginBottom: '10px', marginTop: '10px' }}>
                III. Wykorzystanie zgromadzonych danych osobowych w ramach Serwisu.
              </h1>
              <ol>
                <li>
                  <StyledP>
                    Administrator danych przetwarza (np. zbiera, przechowuje, analizuje itp.) dane
                    osobowe Użytkowników sPrzeciwDlugom.pl w następujących celach:
                  </StyledP>
                  <ul>
                    <li>
                      <StyledP>udostępniania Użytkownikom treści gromadzone w Serwisie,</StyledP>
                    </li>
                    <li>
                      <StyledP>
                        analitycznych i statystycznych. Analiza tych statystyk jest anonimowa,
                      </StyledP>
                    </li>
                    <li>
                      <StyledP>
                        zapamiętywania indywidualnych ustawień Użytkownika i optymalizacji
                        korzystania z Serwisu
                      </StyledP>
                    </li>
                    <li>
                      <StyledP>
                        dostosowania zawartości Serwisu do urządzenia końcowego Użytkownika,
                      </StyledP>
                    </li>
                    <li>
                      <StyledP>
                        poprawy bezpieczeństwa, poprzez kontrolę nadużyć w procesie korzystania z
                        Serwisu,
                      </StyledP>
                    </li>
                    <li>
                      <StyledP>
                        marketingowych Administratora danych (w przypadku danych podawanych w
                        formularzach kontaktowych i przy rejestracji konta w Serwisie)
                      </StyledP>
                    </li>
                  </ul>
                </li>
                <li>
                  <StyledP>
                    Gromadzone informacje dotyczą: IP, typu wykorzystywanej przeglądarki, języka,
                    rodzaju systemu operacyjnego, dostawcy usług internetowych, informacji o czasie
                    i dacie, lokalizacji oraz informacji przesyłanych do serwisu za pośrednictwem
                    formularza kontaktowego (imię i nazwisko adres e-mail, miasto, telefon, treść
                    wiadomości, nazwę firmy) oraz formularza rejestracji konta.
                  </StyledP>
                </li>
                <li>
                  <StyledP>
                    Dane osobowe gromadzone przez Administratora pozostają do czasu odwołania zgody.
                  </StyledP>
                </li>
                <li>
                  <StyledP>
                    Decyzje dotyczące danych osobowych Użytkowników nie będą podejmowane w sposób
                    zautomatyzowany.
                  </StyledP>
                </li>
              </ol>
              <h3 style={{ marginBottom: '30px', marginTop: '10px' }}>IV. Udostępnianie danych</h3>
              <StyledP>
                Dane podlegają udostępnieniu podmiotom zewnętrznym wyłącznie w granicach prawnie
                dozwolonych. Operator może mieć obowiązek udzielania informacji zebranych przez
                Serwis upoważnionym organom na podstawie zgodnych z prawem żądań w zakresie
                wynikającym z żądania.
              </StyledP>
              <br />

              <StyledP>
                Państwa dane osobowe mogą być przetwarzane przez naszych podwykonawców, czyli
                podmioty, z których usług korzystamy przy przetwarzaniu danych oraz świadczeniu na
                Państwa rzecz usług. Wszystkie podmioty, którym powierzamy przetwarzanie danych
                osobowych gwarantują stosowanie odpowiednich środków ochrony i bezpieczeństwa danych
                osobowych wymaganych przez przepisy prawa.
              </StyledP>
              <ul>
                <li>
                  <StyledP>
                    organom państwowym lub innym podmiotom uprawnionym na podstawie przepisów prawa,
                    celem wykonania ciążących na nas obowiązków (Urząd Skarbowy, ZUS, GIODO,
                    Policja, prokuratura, Prezes UOKiK – jeżeli się o to zwrócą do Administratora);
                  </StyledP>
                </li>
                <li>
                  <StyledP>
                    podmiotom wspierającym nas w prowadzonej działalności na nasze zlecenie, w
                    szczególności dostawcom zewnętrznych systemów wspierającym naszą działalność
                    (np. usługi hostingowe). W zależności od uzgodnień umownych i okoliczności,
                    podmioty te działają na nasze zlecenie albo samodzielnie określają cele i
                    sposoby ich przetwarzania;
                  </StyledP>
                  <StyledP>
                    możemy udostępniać dane w pełni zanonimizowane (takie, które nie mogą Państwa
                    zidentyfikować) podmiotom, z którymi współpracujemy.
                  </StyledP>
                </li>
              </ul>
              <br />
              <StyledP>
                Nasi dostawcy mają siedzibę w Polsce i w innych krajach Europejskiego Obszaru
                Gospodarczego (EOG). Nie przekazujemy danych osobowych poza obszar EOG.
              </StyledP>
              <br />

              <h3 style={{ marginBottom: '30px', marginTop: '10px' }}>V. Bezpieczeństwo</h3>
              <StyledP>
                Dostęp do danych mają jedynie osoby upoważnione i jedynie w zakresie, w jakim jest
                to niezbędne ze względu na wykonywane przez nie zadania.
              </StyledP>
              <br />

              <h3 style={{ marginBottom: '30px', marginTop: '10px' }}>
                VI. Uprawnienia Użytkownika. Dane kontaktowe Administratora danych osobowych.
              </h3>
              <ol>
                <li>
                  <StyledP>
                    Użytkownikowi przysługuje prawo dostępu do treści swoich danych i możliwości ich
                    poprawiania, prawo do ograniczenia przetwarzania, wniesienia sprzeciwu wobec
                    przetwarzania, przenoszenia danych, a także prawo wycofania wyrażonych zgód bez
                    podania przyczyny, kontaktując się w tym celu z Kancelarią radcy prawnego Jacek
                    Kołodziejczyk z wykorzystaniem poczty e-mail na adres: kontakt@sprzeciwdlugom.pl
                    lub korespondencyjnie, pod adresem: Kancelaria radcy prawnego Jacek
                    Kołodziejczyk, Aleja Pokoju 7, skr. Poczt. 5, 42-200 Częstochowa, z dopiskiem
                    "Dane osobowe". Realizacja praw podmiotów danych następuje w sposób zgodny z
                    RODO.
                  </StyledP>
                </li>
                <li>
                  <StyledP>
                    Użytkownik posiada prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych
                    Osobowego jako organu nadzorczego.
                  </StyledP>
                </li>
              </ol>
              <h3 style={{ marginBottom: '30px', marginTop: '10px' }}>
                VII. Postanowienia końcowe
              </h3>
              <StyledP>
                Zastrzegamy sobie prawo do zmiany w niniejszej Polityce Prywatności, na które może
                wpłynąć rozwój technologii internetowej, ewentualne zmiany prawa w zakresie ochrony
                danych osobowych oraz serwisu internetowego. O wszelkich zmianach będziemy
                informować w sposób widoczny i zrozumiały.
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
