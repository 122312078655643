import React from 'react';
import styled from 'styled-components';
import { MdAccessTime } from 'react-icons/md';
import { MdPhone } from 'react-icons/md';
import { MdEmail } from 'react-icons/md';
import { MdFacebook } from 'react-icons/md';
import { MdPrivacyTip } from 'react-icons/md';
import { personalData } from '../../../constants';

const MainWrapper = styled.div`
  display: none;
  @media (min-width: 700px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.darkBlue};
    padding: 0.75rem 0.625rem;
  }
`;

const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const IconWithTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
`;

const StyledParagraph = styled.p`
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledMdAccessTime = styled(MdAccessTime)`
  width: ${({ theme }) => theme.iconSize.l};
  height: ${({ theme }) => theme.iconSize.l};
  color: ${({ theme }) => theme.colors.white};
`;
export const StyledFacebook = styled(MdFacebook)`
  width: ${({ theme }) => theme.iconSize.l};
  height: ${({ theme }) => theme.iconSize.l};
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledMdPhone = styled(MdPhone)`
  width: ${({ theme }) => theme.iconSize.l};
  height: ${({ theme }) => theme.iconSize.l};
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledMdEmail = styled(MdEmail)`
  width: ${({ theme }) => theme.iconSize.l};
  height: ${({ theme }) => theme.iconSize.l};
  color: ${({ theme }) => theme.colors.white};
`;
export const StyledMdPrivacyTip = styled(MdPrivacyTip)`
  width: ${({ theme }) => theme.iconSize.l};
  height: ${({ theme }) => theme.iconSize.l};
  color: ${({ theme }) => theme.colors.white};
`;

const TimeBar = () => {
  return (
    <MainWrapper>
      <TimeWrapper>
        <StyledMdAccessTime alt="clock icon" />
        <StyledParagraph>{personalData.openTime}</StyledParagraph>
      </TimeWrapper>

      <ContactWrapper>
        <IconWithTextWrapper>
          <StyledMdPhone alt="phone icon" />
          <a
            href="tel:+48881397639"
            target="_blank"
          >
          <StyledParagraph>{personalData.phone}</StyledParagraph>
          </a>
        </IconWithTextWrapper>
        <IconWithTextWrapper>
          <StyledFacebook alt="facebook icon" />
          <a
            href="https://www.facebook.com/Sprzeciwdlugompl-106305545356846"
            target="_blank"
            rel="noreferrer"
          >
            <StyledParagraph>{personalData.facebook}</StyledParagraph>
          </a>
        </IconWithTextWrapper>
        <IconWithTextWrapper>
          <StyledMdEmail alt="email icon" />
          <a
            href="mailto:kontakt@sprzeciwdlugom.pl"
            target="_blank"
          >
          <StyledParagraph>{personalData.email}</StyledParagraph>
          </a>
        </IconWithTextWrapper>
      </ContactWrapper>
    </MainWrapper>
  );
};

export default TimeBar;
