import { actionTypes } from '../../constants/actionTypes';

const INITIAL_STATE = {
  email: '',
  joinDate: '',
  name: '',
  _id: '',
};

const FreeAdviceReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.ME:
      return {
        email: payload.email,
        joinDate: payload.joinDate,
        name: payload.name,
        _id: payload._id,
      };
    default:
      return state;
  }
};

export default FreeAdviceReducer;
