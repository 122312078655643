import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { me } from '../actions/me';
import { registrations } from '../actions/registrations';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { BASE_URL } from '../constants/api';
import { FilterForm } from '../components/FilterForm/FilterForm';
import axios from 'axios';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-wrap: wrap;
  position: relative;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  padding: 20px 20px;
`;
const SecoundWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 2rem;
`;
const StyledPFile = styled.a`
  cursor: pointer;

  &:hover {
    color: green;
  }
`;
const EditButton = styled.button`
  position: absolute;
  left: 300px;
`;
const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Admin = () => {
  const [comment, setComment] = useState('');
  const [isCommnet, setIsComment] = useState(false);
  const [tag, setTag] = useState('');
  const [isTag, setIsTag] = useState(false);

  const dispatch = useDispatch();
  const xd = useSelector(state => state.MeReducer);
  const isLoged = useSelector(state => state.LoginReducer);
  const registers = useSelector(state => state.RegistrationsReducer);
  let history = useHistory();
  useEffect(() => {
    dispatch(me());
    dispatch(registrations());
  }, [dispatch]);
  useEffect(() => {
    if (isLoged.login === false) {
      history.push('/login');
    }
  }, [isLoged, history]);
  const handleDeleteClick = async id => {
    await axios.put(`${BASE_URL}/registration`, { id });
  };
  const handlReadedClick = async id => {
    await axios.put(`${BASE_URL}/registration/read`, { id });
  };
  const handleInProgress = async id => {
    await axios.put(`${BASE_URL}/registration/inprogress`, { id });
  };
  const addTag = async id => {
    await axios.post(`${BASE_URL}/tag`, { id, tag }, { withCredentials: true });
    setTag('');
    setIsTag(false);
  };
  const addComment = async id => {
    await axios.post(
      `${BASE_URL}/comment`,
      { id: id, comment: comment },
      { withCredentials: true },
    );
    setComment('');
    setIsComment(false);
  };

  return (
    <>
      <div>
        <p>jestes zalogowany jako : {xd.email}</p>
        <button style={{ marginBottom: '30px' }} onClick={() => dispatch(registrations())}>
          refresh
        </button>

        {/* <p style={{ margin: '10px 0px' }}>filter:</p>
        <div>
          <button onClick={() => dispatch(registrations())}>pokaz nieprzyjete</button>
          <button
            onClick={() => dispatch(registrationsInProgress())}
            style={{ marginLeft: '15px' }}
          >
            pokaz w trakcie rozpatrywania
          </button>
          <button onClick={() => dispatch(registrationsEnded())} style={{ marginLeft: '15px' }}>
            pokaz zakonczone
          </button>
        </div> */}

        <FilterForm />
      </div>

      <SecoundWrapper>
        {registers.map(x => {
          return (
            <MainWrapper key={x._id + 'asdas'}>
              <EditButton onClick={() => handlReadedClick(x._id)} style={{ top: '0px' }}>
                oznacz jako zakonczone
              </EditButton>
              <EditButton onClick={() => handleInProgress(x._id)} style={{ top: '30px' }}>
                oznacz jako w trakcie rozpatrywania
              </EditButton>
              <EditButton
                onClick={() => handleDeleteClick(x._id)}
                style={{ top: '60px', backgroundColor: 'red' }}
              >
                usun
              </EditButton>

              <div>
                <p>
                  <b>id :</b>
                </p>
                <p>{x._id}</p>
              </div>

              <div>
                <p>
                  <b>status:</b>
                </p>
                <p>{x.status}</p>
              </div>
              <div>
                <p>
                  <b>imie :</b>
                </p>
                <p>{x.name}</p>
              </div>
              <div>
                <p>
                  <b>nazwisko :</b>
                </p>
                {x.lastname && <p>{x.lastname}</p>}
              </div>
              <div>
                <p>
                  <b>email :</b>
                </p>
                <p>{x.email}</p>
              </div>
              <div>
                <p>
                  <b>telefon:</b>
                </p>
                <p>{x.phoneNumber}</p>
              </div>
              <div>
                <p>
                  <b>opis:</b>
                </p>
                <p>{x.opis}</p>
              </div>
              <div>
                <p>
                  <b>data otrzymania nakazu:</b>
                </p>
                <p>{x.date && format(new Date(x.date), 'yyyy/MM/dd')}</p>
              </div>
              <div>
                <p>
                  <b>data wyslania zgloszenia:</b>
                </p>
                <p>{x.sendDate && format(new Date(x.sendDate), 'yyyy/MM/dd')}</p>
              </div>
              {x.files && (
                <div>
                  <p>
                    <b>pliki:</b>
                  </p>
                  {x.files.map(x => {
                    return (
                      <FileWrapper>
                        <a
                          href={`https://storage.googleapis.com/${x}`}
                          target="_blank"
                          rel="noreferrer"
                          type="application/octet-stream"
                          download
                          title="xd"
                        >
                          {x}
                        </a>
                      </FileWrapper>
                    );
                  })}
                </div>
              )}
              {x.tags && (
                <div>
                  <p>
                    <b>tagi:</b>
                  </p>
                  {!isTag && <button onClick={() => setIsTag(!isTag)}>+</button>}
                  {isTag && (
                    <>
                      <select onChange={e => setTag(e.target.value)}>
                        <option selected value="mateusz">
                          mateusz
                        </option>
                        <option value="olek">olek</option>
                        <option value="krystian">krystian</option>
                      </select>
                      <button onClick={() => addTag(x._id)}>dodaj</button>
                    </>
                  )}

                  {x.tags.map(x => {
                    return (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <StyledPFile>{x}</StyledPFile>
                      </div>
                    );
                  })}
                </div>
              )}
              {x.comments && (
                <div>
                  <p>
                    <b>komentarze:</b>
                  </p>

                  {!isCommnet && <button onClick={() => setIsComment(!isCommnet)}>+</button>}
                  {isCommnet && (
                    <>
                      <input
                        type="text"
                        value={comment}
                        onChange={e => {
                          setComment(e.target.value);
                        }}
                      />
                      <button onClick={() => addComment(x._id)}>dodaj</button>
                    </>
                  )}
                  {x.comments.map(x => {
                    return (
                      <>
                        <p>autor: {x.autor}</p>
                        <p>komenatrz: {x.comment}</p>
                      </>
                    );
                  })}
                </div>
              )}
            </MainWrapper>
          );
        })}
      </SecoundWrapper>
    </>
  );
};

export default Admin;
