import React, { useEffect} from 'react';
import styled from 'styled-components';
import firstPhoto from '../assets/workflow/01.png';
import secondPhoto from '../assets/workflow/02.png';
import thirdPhoto from '../assets/workflow/03.png';
import fourthPhoto from '../assets/workflow/04.png';
import { personalData } from '../constants';

const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  align-self: center;
`;

const StyledUL = styled.ul`
  margin-left:10%;
  margin-top:3%;
  margin-right:10%;
  margin-bottom:3%;
`;

const MegaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-inline: auto;
  max-width: 730px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ImageWrapper = styled.div`
  max-width: 670px;
  margin-inline: auto;
  margin-bottom: 0.75rem;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const StyledH2 = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.ml};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const StyledParagraph = styled.p`
  text-align: justify;
  @media (min-width: 830px) {
    padding-left: 1rem;
  }
`;

const content = [
  {
    title: 'Nasz portal również aktywnie pomaga',
    text: 'w procesach sądowych z firmami windykacyjnymi, funduszami inwestycyjnymi, parabankami i bankami, pomaga w skutecznym napisaniu pisma do sądu, negocjuje z firmami windykacyjnymi warunki ugody, pomaga w wykreśleniu z baz dłużników, prowadzi postępowania o zwrot należności zajętych przez komorników w niecelowych egzekucjach oraz o zwrot spłaconych prowizji w pożyczkach obarczonych klauzulami abuzywnymi.',
    image: secondPhoto,
    alt: 'Fotografia ukazująca człowieka w cięzkim stanie psychicznym.',
  },
  {
    title: 'Działamy na terenie całego kraju i Europy',
    text: 'niezależnie od tego, w którym sądzie prowadzone jest postępowanie.',
    image: thirdPhoto,
    alt: 'Fotografia ukazująca portfel z pieniedzmi.',
  },
];

const Workflow = () => {
  useEffect(() => {
     document.title = "Jak działamy? - Kancelaria Radcy Prawnego - Sprzeciw Długom";  
   }, []);
  
  return (
    <>
      <StyledH1>Jak działamy?</StyledH1>
      <StyledParagraph>
        Portal Sprzeciw Długom to miejsce zrzeszające prawników mogących przedstawić środki zaradcze w sytuacji zadłużenia. Podstawą naszej pomocy jest porada prawna za darmo. Zespół kancelarii dokonuje analizy sytuacji Klienta, ocenia szanse na całkowite anulowanie zadłużenia i wykreślenie z rejestru dłużników lub jego zmniejszenie i na tej podstawie proponuje dalsze kroki.
      </StyledParagraph>
      <MegaWrapper>
        <Wrapper>
          <ImageWrapper>
            <StyledImage src={firstPhoto} alt="Fotografia ukazująca podpisywanie dokumentów." />
          </ImageWrapper>
          <StyledH2>Zakres działania portalu Sprzeciw Długom</StyledH2>
          <StyledParagraph>
            Nasza kancelaria może udzielić porady w sprawach takich jak:
          </StyledParagraph>
          <StyledUL>
            <li>analiza zadłużenia i dokładne wytłumaczenie zainteresowanemu istoty sprawy,</li>
            <li>napisanie odpowiedniego pisma procesowego,</li>
            <li>negocjacje ugodowe z firmami windykacyjnymi,</li>
            <li>wykreślenie z rejestru dłużników,</li>
            <li>pomoc z komornikiem, w tym prowadzenie spraw związanych z niecelowymi egzekucjami,</li>
            <li>zwrot spłaconych prowizji w pożyczkach obarczonych klauzulami abuzywnymi.</li>
          </StyledUL>
        </Wrapper>
        <br />
        <Wrapper>
          <ImageWrapper>
            <StyledImage
              src={secondPhoto}
            />
          </ImageWrapper>
          <StyledH2>Nasz portal również aktywnie pomaga</StyledH2>
          <StyledParagraph>
            Reprezentujemy Klienta w procesach sądowych z parabankami, bankami, firmami windykacyjnymi i komornikami. Zapewniamy pomoc prawną w zrozumieniu otrzymanych pism, także za darmo.
            <br />
            <br />
            W ramach naszych działań edukujemy o podstawowych zagadnieniach dotyczących antywindykacji: jak odczytywać wezwanie od komornika, jakie są konsekwencje niedotrzymania terminów nakazu zapłaty, jak sprawdzić, czy osoba fizyczna została wykreślona z rejestru dłużników. Artykuły znajdują się na naszym blogu.
          </StyledParagraph>
        </Wrapper>
        <br />
        <Wrapper>
          <ImageWrapper>
            <StyledImage
              src={thirdPhoto}
            />
          </ImageWrapper>
          <StyledH2>Działamy na terenie całego kraju i Europy</StyledH2>
          <StyledParagraph>
            Naszą pomoc kierujemy do Polaków, także przebywających za granicą. Dzięki kontaktowi telefonicznemu i mailowemu jesteśmy w stanie udzielić porady prawnej i sporządzić niezbędne pismo procesowe bez konieczności spotykania się w cztery oczy.
          </StyledParagraph>
        </Wrapper>
        <br />
        <Wrapper>
          <ImageWrapper>
            <StyledImage
              src={fourthPhoto}
              alt="Fotografia ukazujaca czlowieka w ciezkim stanie psychicznym na dokumentammi."
            />
          </ImageWrapper>
          <StyledH2>Jak skorzystać z usług kancelarii sPrzeciw Długom?</StyledH2>
          <StyledParagraph>
            Wyjaśnimy niezrozumiałe pisma procesowe, od komornika lub innych instytucji. Podczas porady prawnej za darmo odpowiemy na najważniejsze pytania i przygotujemy plan dalszych działań. Jesteśmy do dyspozycji pod numerem telefonu: <strong>+48 881 397 639</strong> oraz adresem mailowym: <strong>kontakt@sprzeciwdlugom.pl</strong>. Po otrzymaniu zgłoszenia przygotowujemy darmową analizę sytuacji i przedstawiamy szansę na wykreślenie z rejestru dłużników.
          </StyledParagraph>
        </Wrapper>
      </MegaWrapper>
    </>
  );
};

export default Workflow;
