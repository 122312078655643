import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/orange2.png';
import { useBodyUnscrollable } from '../../hooks';
import { basicRoute } from '../../routes/routes';
import NavBar from './NavBar';
import NavBarMobile from './NavBarMobile';
import TimeBar from './TimeBar';

const StyledHeader = styled.header`
  position: fixed;
  inset: 0 0 auto 0;
  background: ${({ theme }) => theme.colors.background};
  z-index: 10000;

  // it set's margin that's headers' height
  & + * {
    margin-top: 8rem;
  }
  @media (min-width: 700px) {
    & + * {
      margin-top: 10.5rem;
    }
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1.5rem 0.625rem;
  @media (min-width: 900px) {
    justify-content: space-between;
    max-width: 1280px;
    margin-inline: auto;
  }
`;

const Logo = styled(Link)`
  max-width: 240px;
  max-height: 34px;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const Hamburger = styled.div`
  position: relative;
  z-index: 3;
  @media (min-width: 900px) {
    display: none;
  }
`;
const Line = styled.div`
  width: 25px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  margin: 5px;
  transition: background-color 0.5s;
`;

const Header = () => {
  const [isMobileNavVisible, checkIsMobileNavVisible] = useState(false);
  useBodyUnscrollable(isMobileNavVisible);

  return (
    <>
      <StyledHeader>
        <TimeBar />
        <Wrapper>
          <Hamburger onClick={() => checkIsMobileNavVisible(prev => !prev)}>
            <Line />
            <Line />
            <Line />
          </Hamburger>
          <Logo to={basicRoute.home}>
            <StyledImage src={logo} alt="Sprzeciw Długom" />
          </Logo>
          <NavBar />
          <NavBarMobile
            isMobileNavVisible={isMobileNavVisible}
            hideMobileMenu={() => checkIsMobileNavVisible(false)}
          />
        </Wrapper>
      </StyledHeader>
    </>
  );
};

export default Header;
