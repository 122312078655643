import styled from 'styled-components';
import Image from '../../assets/blog/art14.jpg';
import { basicRoute } from '../../routes/routes';
import { useLocation } from 'react-router';
import { Header } from '../../components/BlogTextHeder';
import { useEffect } from 'react';
import { Akapit } from '../../components/Akapit/Akapit';

const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Details = {
  head: ' Kiedy warto iść na ugodę?',
  bigImage: Image,
  text: '',
  tableOfContents: [
    {
      id: '1',
      text: 'Kiedy nie warto podpisywać ugody. ',
    },
    {
      id: '2',
      text: ' Kiedy podpisanie ugody to jedyne wyjście? ',
    },
    {
      id: '3',
      text: '  Podsumowanie. Jak podpisać dobrą ugodę.',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`; 
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit />
          Ugoda stanowi formę porozumienia pomiędzy osobą posiadającą <b>zadłużenie</b> ,a{' '}
          <b>
            {' '}
            wierzycielem w postaci firmy pożyczkowej, firmy windykacyjnej, banku, parabanku lub
            funduszu inwestycyjnego
          </b>
          . Teoretycznie ugoda ma na celu korzystne dla obu stron uzgodnienie warunków spłaty
          zadłużenia i można zawrzeć ją zarówno w formie ustnej jak i sporządzić ją w formie
          pisemnej. W drugim przypadku obie strony składają swój podpis pod zawartymi ustaleniami, a
          następnie osoba zadłużona odsyła podpisany dokument do wierzyciela. W praktyce jednak
          bardzo często porozumienie zawarte np. podczas rozmowy telefonicznej z <b>
            windykatorem
          </b>{' '}
          jest <b>niekorzystne</b> dla dłużnika i stanowi jedną ze{' '}
          <b>sztuczek firm windykacyjnych</b>. (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog21} style={{ color: 'orange' }}>
            Tricki i nieczyste zagrywki firm windykacyjnych
          </a>
          ) Musisz wiedzieć, że ugodę warto podpisać tylko w pewnych okolicznościach. W wielu
          przypadkach podpisanie przez osobę zadłużoną ugody, może doprowadzić do{' '}
          <b>uznania długu, przerwania biegu przedawnienia</b> (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog4} style={{ color: 'orange' }}>
            Jak liczyć przedawnienie ?
          </a>
          ) oraz innych niekorzystnych konsekwencji, które finalnie mogą przekreślić szansę na
          <b>anulowanie długu</b>.{' '}
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>Spis treści :</strong>
        </p>
        <ol style={{ maringLeft: '20px' }}>
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <strong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </strong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper>
        <ol style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li id="1">
              <Header> Kiedy nie warto podpisywać ugody.</Header>
              <StyledP>
                <Akapit /> W pierwszej kolejności podkreślić trzeba, że decyzja, czy podpisać ugodę
                “podsuniętą” przez takie firmy jak <strong>Ultimo, Prokura, Kruk, Best</strong> i
                inne powinna zostać skonsultowana z <strong>doświadczonym prawnikiem,</strong> który
                będzie potrafił prawidłowo ocenić sytuację w jakiej się znalazłeś. Jak wynika z
                naszych doświadczeń w sporach z tymi firmami, istnieje wiele dróg, które mogą
                doprowadzić do uniknięcia spłaty zadłużenia, a ugoda zwykle nie jest
                najkorzystniejszą z nich. Firmy windykacyjne, firmy pożyczkowe, banki, parabanki i
                fundusze inwestycyjne, jako instytucje bezwzględnie nastawione na zysk, często
                poprzez przedstawianie Ci twojej sprawy jako sytuacji “bez wyjścia”, próbują
                stworzyć wizję podpisania ugody jako “światełka w tunelu” i “aktu łaski” oraz
                zachęcać do podpisania ugody, aby zyskać nad Tobą przewagę i zmusić Cię do spłacenia
                długu, który{' '}
                <strong>
                  może być niemożliwy do odzyskania na drodze postępowania sądowego lub egzekucji
                  komorniczej.
                </strong>{' '}
                <br />
                <Akapit />
                Czasami podpisanie ugody będzie <strong>
                  najgorszym możliwym rozwiązaniem!
                </strong>{' '}
                Często zdarza się, że wierzytelności dochodzone przez windykatorów są przedawnione
                lub obarczone innymi wadami prawnymi, które umożliwiają{' '}
                <strong>anulowanie długu.</strong> Pamiętaj, że wierzyciel działa dla swojego zysku,
                a nie twojego dobra, więc będzie Cię zachęcał do podpisania ugody nawet jeśli dług
                jest przedawniony albo w sytuacji w której możliwe byłoby{' '}
                <strong>przywrócenie terminu</strong> w twojej sprawie i wygranie jej przed sądem.
                <br />
                <Akapit />
                <strong>Windykacja</strong> działa najczęściej dwutorowo. Oznacza to, że kierowane
                są jednocześnie <strong>wezwania do zapłaty</strong> oraz <strong>pozwy</strong> do
                sądu w celu uzyskania{' '}
                <strong>
                  nakazu zapłaty, wyroku zaocznego lub innego orzeczenia sądowego, które umożliwi
                  skierowanie sprawy do komornika.
                </strong>{' '}
                W praktyce często zdarza się, że dostaniesz jednocześnie pismo z{' '}
                <strong>wezwaniem do zapłaty</strong> (zawierające gotową do podpisania ugodę) oraz
                pozew/nakaz zapłaty z sądu od którego będzie trzeba złożyć sprzeciw. Pamiętaj, aby{' '}
                <strong>nie podpisywać</strong> w takim wypadku ugody! Choć wierzyciele często
                zapewniają, że po jej podpisaniu wycofają sprawę z sądu, to zazwyczaj się tak nie
                dzieje, a wierzyciel oprócz ugody będzie dysponował również orzeczeniem sądu
                umożliwiającym wszczęcie egzekucji komorniczej! (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog22}
                  style={{ color: 'orange' }}
                >
                  Jak działa komornik?
                </a>
                ) Jest to celowe działanie windykacji, które ma na celu zabezpieczenie jej
                interesów.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="2">
              <Header> Kiedy podpisanie ugody to jedyne wyjście?</Header>
              <StyledP>
                <Akapit />
                Zdarza się jednak, że podpisanie ugody może być dla Ciebie korzystne. W pewnych
                okolicznościach spłata zadłużenia to jedyna droga, które pozwoli uniknąć Ci{' '}
                <strong>nękania przez windykację</strong> i naliczania dodatkowych kosztów przez
                komornika. Ugodę warto podpisać w sytuacji w której brałeś udział w rozprawie albo{' '}
                <strong>odebrałeś osobiście</strong> nakaz zapłaty lub wyrok zaoczny z Sądu i minął
                już termin na złożenie od niego sprzeciwu lub zarzutów.
                <br />
                <Akapit />
                Decyzję czy podpisać ugodę trzeba podejmować dla każdej sprawy osobno. Nie można
                więc generalizować i bez analizy danej sprawy stwierdzić, że podpisanie ugody jest
                korzystne lub niekorzystne. Każda sprawa powinna zostać{' '}
                <strog>
                  oceniona indywidualnie, przez osobę znającą w sposób kompleksowy zagadnienia
                  prawne{' '}
                </strog>{' '}
                rozwiązanie z zadłużeniami oraz znającą realia postępowania podmiotów występujących
                jako wierzyciele.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="3">
              <Header> Podsumowanie. Jak podpisać dobrą ugodę.</Header>
              <StyledP>
                <Akapit />
                Na sam koniec warto zauważyć, że ugoda ugodzie nierówna. Nawet jeśli będziesz
                zmuszony do podpisania ugody, nie oznacza to, że musisz godzić się na wszystkie
                warunki proponowane przez wierzyciela. Bardzo ważną cechą przy zawieraniu
                porozumienia są umiejętności negocjacyjne i obranie właściwej strategii. Przy
                odpowiednim prowadzeniu rozmów z windykacją istnieje szansa na podpisanie{' '}
                <strong>korzystniejszej dla Ciebie ugody! Dobrze wynegocjowana ugoda</strong> może
                zagwarantować rozłożenie długu na raty możliwe do spłaty lub nawet umorzenie części
                odsetek lub zadłużenia. Jako prawnicy oraz mediatorzy posiadamy{' '}
                <strong>duże doświadczenie </strong>w negocjowaniu warunków ugód. Pamiętaj, że Twój
                wierzyciel nagrywa rozmowy, które z Tobą prowadzi, zatem każde niewłaściwe słowo
                może być potem użyte przeciwko Tobie!
                <br />
                <Akapit /> Podpisanie ugody może więc być najlepszą albo najgorszą decyzją w sprawie
                twojego zadłużenia, nie pozwól wykorzystać się przez windykację, nie podpisuj ugody,
                która uniemożliwi anulowanie twojego długu.
                <br /> <Akapit />
                Zgłoś się do nas po <strong>darmową analizę </strong>twojej sprawy, wspólnie
                podejmiemy decyzję najlepszą dla Ciebie i Twoich bliskich!
                <br />
                <strong>
                  Musisz podpisać ugodę? Chcesz to zrobić dobrze? Zadzwoń do nas lub napisz,
                  zajmiemy się prowadzeniem negocjacji w Twoim imieniu!
                </strong>
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
