import styled from 'styled-components';
import Image from '../../assets/blog/art21.jpg';
import { basicRoute } from '../../routes/routes';
import { useLocation } from 'react-router';
import { Header } from '../../components/BlogTextHeder';
import { useEffect } from 'react';
import { Akapit } from '../../components/Akapit/Akapit';
const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Details = {
  head: ' Tricki i nieczyste zagrywki firm windykacyjnych.',
  bigImage: Image,
  text: '',
  tableOfContents: [
    {
      id: '1',
      text: ' Udawanie, że jest się dobrym',
    },
    {
      id: '2',
      text: 'Udawanie, że mówi się prawdę',
    },
    {
      id: '3',
      text: ' Udawanie, że jest się groźnym',
    },
    {
      id: '4',
      text: 'Podsumowanie',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`;
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit />
          <b>Firmy windykacyjne</b> takie jak{' '}
          <b>
            Prokura, Intrum, Hoist, Kruk, Best, Ultimo, Alektum, EasyDebt, Smart Lease, Getback
            (Asseta)
          </b>{' '}
          i inne to instytucje finansowe nastawione na jak największy zysk w jak najkrótszym czasie.
          Jak powszechnie wiadomo, działalność windykacji opiera się na odkupywaniu długów (czyt.
          także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog10} style={{ color: 'orange' }}>
            Cesja wierzytelności. O powiązaniach między pożyczkodawcami i firmami windykacyjnymi.
          </a>
          ) za ułamek ich wartości i odzyskiwanie należności w pełnym, a często zawyżonym wymiarze.
          Ten główny cel działalności firm windykacyjnych, tj. zarobek starają się one osiągnąć
          różnymi sposobami, które należy rozdzielić na dochodzenie zapłaty poprzez sąd i komornika
          (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog1} style={{ color: 'orange' }}>
            Jak zrozumieć nakaz spłaty i pozew?
          </a>
          ) oraz tzw. “windykację miękką”, czyli wszystkie działania pozasądowe i poza komornicze,
          które mają na celu zmuszenie ludzi do spłaty zadłużenia. W obu tych przypadkach firmy
          windykacyjne stosują różnego rodzaju zagrywki i tricki, które uznać można za działania na
          granicy prawa, a często granice te przekraczające. Dowiedz się jakie są najczęściej
          stosowane nieczyste zagrywki firm windykacyjnych, jak nie dać się na nie nabrać i jak
          skutecznie sobie z nimi radzić!'
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>Spis treści :</strong>
        </p>
        <ol style={{ maringLeft: '20px' }}>
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <strong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </strong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper>
        <ol style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li id="1">
              <Header> Udawanie, że jest się dobrym</Header>

              <StyledP>
                <Akapit />
                Zysk, to jak już wiemy główny cel firm windykacyjnych. Często instytucje te próbują
                jednak przekonać wszystkich naokoło, że są przyjaznymi, proklienckimi firmami, które
                pomagają ludziom <strong>pozbyć się ich długów. </strong> Tworzenie takiego
                wizerunku ma na celu zachęcanie osoby z zadłużeniami do kontaktowania się z
                windykacją i pozyskanie zaufania tych ludzi. Ma to ułatwić kontakt z osobami
                zadłużonymi, przekonać je, że firma windykacyjna jest instytucją “dobrą i
                nieomylną”, a finalnie doprowadzić do podpisania ugody (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog14}
                  style={{ color: 'orange' }}
                >
                  kiedy warto isć na ugodę?
                </a>
                ) oraz dokonywania przez osoby posiadające długi dobrowolnych wpłat. Warto w tym
                miejscu zauważyć, że korzystając z niewiedzy swoich dłużników firmy windykacyjne
                przedstawiają możliwość podpisania ugody jako swojego rodzaju nagrodę, podczas gdy
                najczęściej proponują podpisanie ugody w sprawach, w których zadłużenie jest już
                przedawnione (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog4}
                  style={{ color: 'orange' }}
                >
                  jak liczyć przedawnienie?
                </a>
                ) lub w inny sposób trudne do dochodzenia na drodze sądowej.
                <br />
                <Akapit />
                Pracownicy firm windykacyjnych są szkoleni w taki sposób, aby w zależności od
                potrzeby być tzw. dobrym lub złym “policjantem”. Jedną z głównych taktyk stosowanych
                przez windykatorów jest bowiem taktyka “kija i marchewki”, która sprowadza się do
                tego, że po wstępnej analizie kim jest osoba zadłużona, są w stosunku do niej albo
                bardzo mili albo ostrzy i stanowczy. Obie te postawy mają prowadzić do jednego - do
                dobrowolnej spłaty zadłużenia. Musisz wiedzieć, że pracownik firmy windykacyjnej
                jest wynagradzany <strong>prowizją</strong> od “ściągniętej” od Ciebie kwoty, zależy
                mu więc na tym aby jak najwięcej i jak najszybciej wyegzekwować.
                <br /> <Akapit /> W kontaktach z firmami windykacyjnymi warto pamiętać zawsze o
                jednym. Zazwyczaj jedyna pomoc w spłacie długu jaką otrzymuje się od windykacji, to
                podanie przez jej pracownika numeru konta do wpłaty i wskazanie kwoty jaka ma być na
                nie przelana. Bardzo rzadko zdarza się bowiem, że te instytucje obiektywnie
                przedstawiają sytuację w jakiej znajduje się dłużnik oraz, że wychodzą naprzeciw
                sytuacji życiowej w jakiej znalazła się dana osoba.
                <br />
                <Akapit />
                Warto w tym miejscu zauważyć też pewną bardzo istotną rzecz. Dłużnik firmy
                windykacyjnej <strong>nie jest</strong> jej klientem. Stosowanie przeciwnej narracji
                ma na celu wywołać u osób zadłużonych przekonanie, że firma windykacyjna działa w
                jego interesie.
              </StyledP>
            </li>
          </article>

          <article style={{ marginTop: '15px' }}>
            <li id="2">
              <Header> Udawanie, że mówi się prawdę</Header>

              <StyledP>
                <Akapit />
                Jednym z najważniejszych oręży stosowanych przez firmy windykacyjne wobec swoich
                dłużników jest brak wiedzy prawnej tych drugich. Najwięksi gracze na rynku, czyli
                wymienione na wstępie{' '}
                <strong>
                  {' '}
                  Prokura, Intrum, Hoist, Kruk, Best, Ultimo, Alektum, EasyDebt, Smartlease, Getback
                  (Asseta)
                </strong>{' '}
                posługują się najczęściej taktyką “nie mówienia całej prawdy”, tj. pracujący dla
                nich konsultanci nie oszukują swoich rozmówców wprost, ale nie udzielają im
                jednocześnie wszystkich informacji odnośnie stanu spraw i działań jakie można
                podjąć, aby zamiast spłacać zadłużenie <strong>anulować je.</strong> Pamiętając, że
                firmie windykacyjnej zależy na zysku nie powinno dziwić, że nie podsuwa ona swoim
                dłużnikom rozwiązań <strong>umożliwiających uniknięcie spłaty długu,</strong>{' '}
                ponieważ działałby wówczas wbrew swoim interesom!
                <br />
                <Akapit /> Powszechnym zjawiskiem jest również próba uzyskania wpłat od osób, które
                nie mają żadnego zadłużenia, ponieważ albo spłaciły swoje długi u poprzednich
                wierzycieli albo wygrały sprawę w sądzie poprzez <strong>
                  umorzenie długu.
                </strong>{' '}
                Firmy windykacyjne stosują takie praktyki na masową skalę, nie informując ludzi o
                tym, że wygrali sprawę w sądzie albo nie uznając argumentów (w tym często również
                argumentów w postaci potwierdzeń bankowych przelewów),{' '}
                <strong>
                  że dług został już spłacony. Należy więc pamiętać, że dochodzenie przez
                  windykatora jakiegoś zadłużenia, wcale nie musi oznaczać, że faktycznie trzeba je
                  spłacić!
                </strong>
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="3">
              <Header> Udawanie, że jest się groźnym</Header>

              <StyledP>
                <Akapit />
                Firmy windykacyjne mają skłonność do przesady. Najczęściej objawia się to w ilości
                telefonów wykonywanych przez ich pracowników, a także w wymyślaniu kolejnych sankcji
                jakie będą ponosić osoby zadłużone w związku z niespłacaniem wątpliwych długów.
                Emocje i strach “klientów” windykacji to coś, co przynosi jej ogromne zyski. Firmy
                windykacyjne wychodzą bowiem z założenia, że{' '}
                <strong>
                  {' '}
                  osoba wystraszona, która dodatkowo nie zna swoich praw, jest łatwiejszą “ofiarą”.
                </strong>{' '}
                Dlatego tak powszechnym zjawiskiem jest wywoływanie uczucia strachu i presji
                psychicznej, poprzez “informowanie” o nagłym skierowaniu sprawy do komornika albo do
                sądu, podczas, gdy często nie jest to możliwe ze względów prawnych. <br />
                <Akapit />
                Niczemu innemu nie służy wykonywanie telefonów do rodziny, sąsiadów a często miejsc
                pracy osób z zadłużeniami. Osoba taka, często zawstydzona i przestraszona wpłaci
                “każdą kwotę” tylko, żeby sytuacja taka się nie powtórzyła. W skrajnych przypadkach
                stosowane są również groźby karalne (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog16}
                  style={{ color: 'orange' }}
                >
                  jak nie wystraszyć się proficredit ?
                </a>
                ) i nachodzenie pożyczkobiorców w domach. <br />
                <Akapit />
                Pamiętaj, że próba wywołania strachu często bierze się z braku argumentów firmy
                windykacyjnej, a także z jej świadomości, że nie będzie mogła wyegzekwować pieniędzy
                po przez sąd i komornika.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="4">
              <Header> Podsumowanie</Header>

              <StyledP>
                <Akapit /> Podstawowym problemem każdej osoby borykającej się z zadłużeniem jest
                konieczność kontaktu z windykacją. Oczywistym jest, że rozmowa na temat zadłużenia
                jest nieprzyjemna i krępująca, jednak czasami warto ją odbyć, aby ocenić w jakiej
                znajdujesz się sytuacji. Skontaktuj się z nami i dowiedz się, kiedy i czy w ogóle
                musisz rozmawiać z windykacją. Od nas dowiesz się również co zrobić, kiedy otrzymasz
                pismo z Sądu (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog1}
                  style={{ color: 'orange' }}
                >
                  jak zrozumieć nakaz zapłaty i pozew?
                </a>
                ), co może, a czego nie może komornik (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog22}
                  style={{ color: 'orange' }}
                >
                  jak działa komornik?
                </a>
                ), oraz jaka jest różnica między nim a <strong>windykatorem terenowym.</strong>{' '}
                Dzięki temu unikniesz wielu nieprzyjemności i pułapek zastawianych przez
                konsultantów, a także dowiesz się jak pokonać firmę windykacyjną i{' '}
                <strong>anulować swój dług.</strong> Zamiast dzwonić do windykacji, zadzwoń do nas!
                Napisz maila albo zgłoś się przez formularz zgłoszeniowy, przeprowadzimy{' '}
                <strong>darmową analizę </strong>twojej sprawy i umorzymy zadłużenie!
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
