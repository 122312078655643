import React, { useEffect} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { basicRoute } from '../routes/routes';
import art1 from '../assets/blog/art1final.jpg';
import art2 from '../assets/blog/art2.jpg';
import art3 from '../assets/blog/art3.jpg';
import art4 from '../assets/blog/art4.jpg';
import art5 from '../assets/blog/art5.jpg';
import art55 from '../assets/blog/art55.jpg';

import art8 from '../assets/blog/art8.jpg';
import art10 from '../assets/blog/art10.jpg';
import art11 from '../assets/blog/art11.jpg';
import art12 from '../assets/blog/art12.jpg';
import art13 from '../assets/blog/art13.jpg';
import art14 from '../assets/blog/art14.jpg';
import art16 from '../assets/blog/art16.jpg';
import art18 from '../assets/blog/art18.jpg';
import art21 from '../assets/blog/art21.jpg';
import art22 from '../assets/blog/art22.jpg';

const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  align-self: center;
`;

const MegaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-inline: auto;
  max-width: 730px;
`;

const Wrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &:not(:last-child) {
    border-bottom: 1px solid black;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (min-width: 790px) {
    max-width: 670px;
    flex-shrink: 0;
    margin-inline: auto;
    margin-bottom: 0.75rem;
    width: 35%;
  }
`;

const StyledImage = styled.img`
  width: 100%;
`;

const StyledH2 = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.ml};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const StyledParagraph = styled.p`
  @media (min-width: 830px) {
    padding-left: 1rem;
  }
`;

const content = [
  {
    title: 'Jak zrozumieć nakaz zapłaty i pozew?',
    text: 'Nakaz zapłaty, który otrzymałeś jest tytułem egzekucyjnym wydanym przez Sąd, w związku ze złożeniem pozwu przez twojego aktualnego wierzyciela',
    image: art1,
    alt: '',
    link: `${basicRoute.blog1}`,
  },
  {
    title: 'Przywrócenie terminu. Jak odzczytać pisamo od komornika?',
    text: 'Przestrzeganie wynikających z kodeksów i ustaw terminów na złożenie sprzeciwu lub odpowiedzi na pozew jest niezwykle istotne',
    image: art2,
    alt: '',
    link: `${basicRoute.blog2}`,
  },
  {
    title: 'Jak złożyć sprzeciw od nakazu zapłaty ?',
    text: 'Dostałeś nakaz zapłaty w postępowaniu upominawczym i dowiedziałeś się, że firma windykacyjna taka jak Prokura, BEST, Ultimo, Eques Debitum czy Alektum żąda od Ciebie pieniędzy? Zastanawiasz się co teraz zrobić?',
    image: art3,
    alt: '',
    link: `${basicRoute.blog3}`,
  },
  {
    title: 'Jak liczyć przedawnienie ?',
    text: 'Dostałeś pismo z którego wynika, że masz zadłużenie? Jego nadawcą jest Sąd, Komornik? A może któryś z następujących podmiotów: Ultimo, Kruk, Best, Hoist, Kredyt Inkaso, ProfiCredit, Alektum, Eques Debitum, GetBack, jeden z banków lub z parabanków np. LoanMe, SuperGrosz, Provident, Vivus, Rapida albo funduszy sekurytyzacyjnych np. Omega, Centauris, Horyzont? ',
    image: art4,
    alt: '',
    link: `${basicRoute.blog4}`,
  },
  {
    title: 'Elektroniczne postępowanie upominawcze, czyli słów parę o tzw. e-sądzie.',
    text: 'Często zdarza się, że firmy windykacyjne takiej jak Intrum, Hoist czy EasyDebt oraz parabanki udzielające tzw. chwilówek pozywają dłużników przed VI Wydziałem Cywilnym Sądu Rejonowego Lublin-Zachód w Lublinie. W poniższym tekście wyjaśnię czemu tak chętnie kierują pozwy ',
    image: art5,
    alt: '',
    link: `${basicRoute.blog5}`,
  },
  {
    title: 'Jak założyć konto na e-sądzie ?',
    text: 'Jedynie w 2020 roku do Sądu Rejonowego Lublin-Zachód w Lublinie w ramach elektronicznego postępowania upominawczego wpłynęło blisko 2 miliony spraw, które przełożyły się na wydanie ponad 1,5 mln nakazów zapłaty. Można więc bez żadnych wątpliwości stwierdzić, że ',
    image: art55,
    alt: '',
    link: `${basicRoute.blog55}`,
  },
  {
    title: 'Jak sprawdzić czy mam zadłużenia ?',
    text: 'Działania mające na celu pozbycie się długów rozpocząć zawsze należy od ich “zlokalizowania”. Informacje o tym kto jest wierzycielem w sprawie, czy sprawa była w sądzie, jeśli tak to w jakim i co się w nim wydarzyło oraz kwoty jaka jest w tej chwili wymagalna do spłaty, są niezbędne dla wykonania analizy prawnej ',
    image: art8,
    alt: '',
    link: `${basicRoute.blog8}`,
  },
  {
    title: 'Cesja wierzytelności. O powiązaniach między pożyczkodawcami i firmami windykacyjnymi.',
    text: 'Osoby zadłużone często opisują, rozmowy z windykatorami firmy BEST, Ultimo czy Kruka  w trakcie, których są informowani, że firma windykacyjna kupiła dług albo, że komornik wszczął egzekucję na jej wniosek ',
    image: art10,
    alt: '',
    link: `${basicRoute.blog10}`,
  },
  {
    title: 'Klauzule abuzywne.',
    text: 'Najprościej mówiąc klauzule abuzywne to zapisy zawarte w umowach pożyczek, w tym w pożyczkach refinansujących, które ze względu na nadmierne obciążenie kredytobiorcy lub niezgodność z obowiązującym prawem, nie wiążą dłużnika. Oznacza to, że nawet jeśli zawarłeś jakąś niekorzystną umowę  ',
    image: art11,
    alt: '',
    link: `${basicRoute.blog12}`,
  },
  {
    title: 'Refinansowanie pożyczki. Jak się nie dać złapać w pętlę kredytową? ',
    text: 'Pożyczki-chwilówki umożliwiają szybkie uzyskanie potrzebnej ilości gotówki. Są najczęściej zawierane na krótki okres i nie zawsze pożyczkobiorca jest ją w stanie spłacić w przewidzianym terminie z uwagi na dodatkowe koszty w postaci prowizji ',
    image: art12,
    alt: '',
    link: `${basicRoute.blog11}`,
  },
  {
    title: 'Jak Prezes Urzędu Ochrony Konkurencji i Konsumentów pomaga osobom zadłużonym?',
    text: 'Wielu dłużników uważa, że w starciu z firmami windykacyjnymi takimi jak Prokura, GetBack, Ultimo, Kredyt Inkaso czy z firmami pożyczkowymi jak Profi Credit, Yes Finance są zdani tylko na siebie i   stoją na przegranej pozycji. Nic z tych rzeczy!',
    image: art13,
    alt: '',
    link: `${basicRoute.blog13}`,
  },
  {
    title: 'Kiedy warto isć na ugodę?',
    text: 'Ugoda stanowi formę porozumienia pomiędzy osobą posiadającą zadłużenie a wierzycielem w postaci firmy pożyczkowej, firmy windykacyjnej, banku, parabanku lub funduszu inwestycyjnego. Teoretycznie ugoda ma na celu korzystne dla obu stron ',
    image: art14,
    alt: '',
    link: `${basicRoute.blog14}`,
  },
  {
    title: 'Jak nie wstraszyć się proficredit?',
    text: 'ProfiCredit, to firma pożyczkowa i windykacyjna udzielająca długoterminowych pożyczek, które obciążają jej klientów ogromnymi kosztami pozaodsetkowymi (np. prowizją, opłatą przygotowawczą, opłatą za plan “Twój Pakiet”), często przekraczającymi kwoty otrzymanej pożyczki. Często okazuje się, że umowy pożyczek udzielane  przez ProfiCredit zawierają',
    image: art16,
    alt: '',
    link: `${basicRoute.blog16}`,
  },
  {
    title: 'Kwoty wolne od zajęć komorniczych.',
    text: 'Każda osoba zadłużona otrzymując pismo od komornika (podlinkowany art. 2) lub wchodząc na stronę internetową banku i uzyskując informację o zajęciu rachunku bankowego zastanawia się ile pieniędzy komornik może zająć. Odpowiedź na to pytanie zależy od kilku czynników, mianowicie od przyczyny zajęcia dokonanego przez komornika, dlatego na początku warto ustalić ',
    image: art18,
    alt: '',
    link: `${basicRoute.blog18}`,
  },
  {
    title: 'Tricki i nieczyste zagrywki firm windykacyjnych.',
    text: 'Firmy windykacyjne takie jak Prokura, Intrum, Hoist, Kruk, Best, Ultimo, Alektum, EasyDebt, Smart Lease, Getback (Asseta) i inne to instytucje finansowe nastawione na jak największy zysk w jak najkrótszym czasie. Jak powszechnie wiadomo, działalność windykacji opiera się na odkupywaniu długów za ułamek ich wartości i odzyskiwanie należności w pełnym, a często zawyżonym wymiarze ',
    image: art21,
    alt: '',
    link: `${basicRoute.blog21}`,
  },
  {
    title: 'Jak działa komornik ?',
    text: 'Bardzo często w trakcie komunikacji z dłużnikami firmy windykacyjne grożą skierowaniem sprawy do komornika. Jednak wbrew ich twierdzeniom przeprowadzenie egzekucji wcale nie jest takie proste i szybkie. Przed skierowaniem zadłużenia do egzekucji i zajęciem konta, pensji czy licytacją nieruchomości wierzyciel musi wygrać sprawę w sądzie. Dopiero po uprawomocnieniu się nakazu zapłaty czy wyroku, sprawą może zająć się organ egzekucyjny.     ',
    image: art22,
    alt: '',
    link: `${basicRoute.blog22}`,
  },
  // {
  //   title: 'Nasz portal również aktywnie pomaga',
  //   text: 'w procesach sądowych z firmami windykacyjnymi, funduszami inwestycyjnymi, parabankami i bankami, pomaga w skutecznym napisaniu pisma do sądu, negocjuje z firmami windykacyjnymi warunki ugody, pomaga w wykreśleniu z baz dłużników, prowadzi postępowania o zwrot należności zajętych przez komorników w niecelowych egzekucjach oraz o zwrot spłaconych prowizji w pożyczkach obarczonych klauzulami abuzywnymi...',
  //   image: art22,
  //   alt: 'Fotografia ukazująca człowieka w cięzkim stanie psychicznym.',
  //   link: `${basicRoute.blog11}`,
  // },
  // {
  //   title: 'Działamy na terenie całego kraju i Europy',
  //   text: 'niezależnie od tego, w którym sądzie prowadzone jest postępowanie...',
  //   image: art22,
  //   alt: 'Fotografia ukazująca portfel z pieniedzmi.',
  //   link: `${basicRoute.blog11}`,
  // },
];

const Xd = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 1rem;

  align-items: center;
  @media (min-width: 790px) {
    flex-direction: row;
  }
`;

const Blog = () => {
  
  useEffect(() => {
     document.title = "Blog - Kancelaria Radcy Prawnego - Sprzeciw Długom";  
   }, []);
  
  return (
    <>
      <StyledH1>Blog</StyledH1>
      <MegaWrapper>
        {content.map((content, i) => (
          <Wrapper key={content.title + i} to={content.link}>
            <Xd>
              <StyledH2>{content.title}</StyledH2>
            </Xd>
            <Xd>
              <ImageWrapper>
                <StyledImage src={content.image} alt={content.alt} />
              </ImageWrapper>
              <Xd>
                <StyledParagraph>{content.text}</StyledParagraph>
              </Xd>
            </Xd>
            <p style={{ marginLeft: 'auto', marginBottom: '10px' }}>czytaj wiecej</p>
          </Wrapper>
        ))}
      </MegaWrapper>
    </>
  );
};

export default Blog;
