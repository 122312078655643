import { actionTypes } from '../../constants/actionTypes';

const INITIAL_STATE = [
  {
    email: '',
    name: '',
    phoneNumber: '',
    opis: '',
    date: '',
    sendDate: '',
    files: [],
    comments: [],
    tags: [],
    _id: '',
  },
];

const RegistrationsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.REGISTRATIONS:
      return payload.allRegister;

    default:
      return state;
  }
};

export default RegistrationsReducer;
