import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { changeVisibility } from '../../actions/freeAdvice';

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.orange};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.global};
  border: 2px solid ${({ theme }) => theme.colors.darkBlue};
  border-radius: ${({ theme }) => theme.border.radius};
  white-space: nowrap;
  padding: 0.415em 0.85em;
  font-size: ${({ theme }) => theme.fontSize.ml};
`;

const FreeAdviceButton = ({ text }) => {
  const dispatch = useDispatch();

  return <StyledButton onClick={() => dispatch(changeVisibility())}>{text}</StyledButton>;
};

export default FreeAdviceButton;
