import styled from 'styled-components';
import Image from '../../assets/blog/art4.jpg';
import { basicRoute } from '../../routes/routes';
import { useLocation } from 'react-router';
import { Akapit } from '../../components/Akapit/Akapit';
import { Header } from '../../components/BlogTextHeder';
import { useEffect } from 'react';
const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Details = {
  head: 'Jak liczyć przedawnienie ?',
  bigImage: Image,
  text: 'Dostałeś pismo z którego wynika, że masz zadłużenie? Jego nadawcą jest Sąd, Komornik? A może któryś z następujących podmiotów: Ultimo, Kruk, Best, Hoist, Kredyt Inkaso, ProfiCredit, Alektum, Eques Debitum, GetBack, jeden z banków lub z parabanków np. LoanMe, SuperGrosz, Provident, Vivus, Rapida albo funduszy sekurytyzacyjnych np. Omega, Centauris, Horyzont? Dowiedz się, czy dochodzone od Ciebie zobowiązanie nie jest przedawnione.   ',
  tableOfContents: [
    {
      id: '1',
      text: 'Co to jest przedawnienie?',
    },
    {
      id: '2',
      text: ' Kiedy dług się przedawnia?',
    },
    {
      id: '3',
      text: ' Czynności, które przerywają bieg przedawnienia.',
    },
    {
      id: '4',
      text: ' Podsumowanie.',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`;
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit /> Dostałeś <b>pismo</b> z którego wynika, że masz <b>zadłużenie?</b> Jego nadawcą
          jest Sąd, Komornik? A może któryś z następujących podmiotów: Ultimo, Kruk, Best, Hoist,
          Kredyt Inkaso, ProfiCredit, Alektum, Eques Debitum, GetBack, jeden z banków lub z
          parabanków np. LoanMe, SuperGrosz, Provident, Vivus, Rapida albo funduszy
          sekurytyzacyjnych np. Omega, Centauris, Horyzont?{' '}
          <b>Dowiedz się, czy dochodzone od Ciebie zobowiązanie nie jest przedawnione.</b>{' '}
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>Spis treści :</strong>
        </p>
        <ol style={{ maringLeft: '20px' }}>
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <strong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </strong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper>
        <ol style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li id="1">
              <Header> Co to jest przedawnienie?</Header>

              <StyledP>
                <Akapit />
                <strong>Przedawnienie</strong> to instytucja prawa, która umożliwia{' '}
                <strong>uchylenie się od konieczności zapłaty</strong> zobowiązań w związku z tym,
                iż między datą ich wymagalności (tj. powstania obowiązku ich spłacenia), a datą
                podjęcia działań przez wierzyciela upłynął określony przepisami okres. Oznacza to,
                że jeśli <strong>dług</strong> (wierzytelność) stał się wymagalny (tzn. wierzyciel
                zyskał możliwość domagania się zwrotu zadłużenia i{' '}
                <strong>skierowania sprawy do sądu</strong> ), a wierzyciel zbyt długo zwlekał ze
                skierowaniem sprawy do sądu lub komornika, to nie może już dochodzić zadłużenia
                „przy pomocy” tych instytucji.{' '}
                <strong>
                  Przedawnienie dotyczy zarówno należności głównej, kosztów sądowych i komorniczych,
                  jak również odsetek.
                </strong>{' '}
                <br />
                <Akapit /> Kwestia przedawnienia nie zależy więc bezpośrednio od tego, kiedy zawarta
                została umowa będąca źródłem <srtrong>długu</srtrong>. Wbrew powszechnej opinii
                przedawnienie długu nie oznacza, że{' '}
                <strong>zadłużenie zostało całkowicie anulowane.</strong> Takie zobowiązanie
                funkcjonuje bowiem nadal, ale jako tzw. “zobowiązanie naturalne”. Oznacza to, że
                firma która jest właścicielem przedawnionej wierzytelności, choć nie może dochodzić
                jej spłaty używając przymusu instytucji publicznych (np. sądu lub komornika), to
                wciąż może żądać jej spłaty za pomocą innych, tzw. „miękkich” sposobów windykacji,
                które również mogą generować pewne kłopoty i być uciążliwymi. Przykładem takich
                działań jest np. nękanie osób zadłużonych przez{' '}
                <strong>windykatorów terenowych.</strong> (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog22}
                  style={{ color: 'orange' }}
                >
                  jak działa komornik?
                </a>{' '}
                )
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="2">
              <Header> Kiedy dług się przedawnia?</Header>

              <StyledP>
                <Akapit /> Polski Kodeks Cywilny określa wiele różnych terminów, po których upływie
                roszczenie wierzyciela względem dłużnika ulega przedawnieniu. Różnice w tych
                terminach wynikają z rodzaju umowy lub innej czynności, która spowodowała powstanie
                zadłużenia. Nie bez znaczenia są również działania podejmowane przez wierzycie oraz
                dłużnika. Mogą one bowiem wywołać bezpośredni wpływ na kwestię przedawnienia. Ze
                względu na obszerność zagadnienia, niniejszy artykuł odpowie na zawarte w jego
                tytule pytanie, jedynie w odniesieniu do długów związanych z{' '}
                <strong>chwilówkami</strong>, kredytami oraz mandatami.
                <br />
                <Akapit />
                Terminem wyjściowym dla rozważań dotyczących daty przedawnienia długów jest art 118
                k.c., który określa, że roszczenia przedawniają się co do zasady w terminie{' '}
                <strong>sześciu lat</strong> od daty wymagalności roszczenia. W przypadku roszczeń
                związanych z prowadzeniem działalności gospodarczej, (a taką prowadzą{' '}
                <strong>banki, parabanki, firmy windykacyjne i fundusze inwestycyjny</strong>)
                termin ten wynosi <strong>trzy lata</strong> (ten sam termin dotyczy odsetek jako
                tzw. świadczeń okresowych).{' '}
                <strong>Co ważne, ogólną zasadą jest również liczenie ich</strong> ze skutkiem na
                koniec roku kalendarzowego. Wyjątkiem w tym zakresie jest termin przedawnienia
                krótszy niż dwa lata. Powyższe zapisy prowadzą do różnego rodzaju trudności w
                określeniu daty <strong>przedawnienia długu.</strong> Oznaczają bowiem, że w
                niektórych przypadkach termin ten może wynosić dokładnie trzy lata, w niektórych
                nawet cztery - tj. cztery lata bez jednego dnia.
                <br />
                <Akapit />
                Przypadanie skutku przedawnienia na ostatni dzień roku kalendarzowego prowadzi do
                sytuacji, w której kluczowym dla poprawnego liczenia terminu przedawnienia długu
                jest właściwe określenie daty jego wymagalności. Wprowadzając takie rozwiązanie,
                ustawodawca jednocześnie zobowiązał sądy do badania czy termin przedawnienia
                dochodzonego roszczenia już upłynął. Oznacza to, że jeśli powód skieruje do sądu
                powództwo o przedawniony dług, sąd powinien odrzucić jego roszczenia.
                <br />
                <Akapit /> W praktyce jednak, interpretacja czy{' '}
                <strong>dług powinien zostać umorzony ze względu na jego przedawnienie</strong> nie
                jest tak prosta i jednoznaczna. Często istotne dla stwierdzenia, czy dany dług jest{' '}
                <strong>wystarczająco stary</strong> potrzebne jest również właściwe interpretowanie
                tzw. „przepisów przejściowych”, które określają zasady uznawania zadłużenia za
                przedawnione w sytuacji, w której na przestrzeni biegu terminu przedawnienia
                „spotykały” się dwa porządki prawne. Ostatnia duża zmiana w zakresie terminu oraz
                biegu terminu długu przedawnienia, została wprowadzona 09 lipca 2018 roku. Oznacza
                to, że w przypadku długów powstałych przed tą datą, dla oceniania czy dług się już
                przedawnił czy jeszcze nie trzeba sięgnąć również do tzw. przepisów przejściowych.
                Co dodatkowo komplikuje sprawę.
                <br />
                <Akapit />
                Zawiłość i różnorodne możliwości interpretacji tych przepisów doprowadzają często do
                sytuacji, w której sądy wciąż wydają nakazy zapłaty mimo tego, iż dług powinien
                zostać uznany za przedawniony. W tym celu należy pamiętać, że kwestie przedawnienia
                długu powinien przeanalizować zawsze fachowiec, regularnie zajmujący się walką z
                firmami windykacyjnymi, bankami, parabankami i funduszami sekurytyzacyjnymi (czyt.
                także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog1}
                  style={{ color: 'orange' }}
                >
                  jak zrozumieć nakaz zapłaty i pozew?
                </a>
                ). Sytuacja zmienia się diametralnie, kiedy analizie pod kątem przedawnienia podlega
                zadłużenie stwierdzone prawomocnym orzeczeniem (wyrokiem, nakazem zapłaty lub
                postanowieniem) przez sąd lub wynikające z podpisania
                {''} <strong>ugody</strong> (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog14}
                  style={{ color: 'orange' }}
                >
                  kiedy warto isć na ugodę?
                </a>
                ). Wówczas termin ten przedłuża się do lat sześciu i jego skutek przypada również na
                ostatni dzień kalendarzowy roku.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="3">
              <Header> Czynności, które przerywają bieg przedawnienia.</Header>

              <StyledP>
                <Akapit />
                Niestety, nie zawsze upływ terminu przedawnienia nakazu zapłaty oznacza, że będzie
                on przedawniony. Często w obrocie prawnym funkcjonują nieprzedawnione zadłużenia w
                sprawach, które toczyły się nawet dziesięć, czy piętnaście lat temu. Zdarzyć się
                bowiem może, że działania podjęte przez wierzyciela lub uznanie długu przez dłużnika
                przerwało bieg terminu przedawnienia i spowodowało jego zawieszenie lub rozpoczęcie
                liczenia go na nowo.
                <br />
                <Akapit />
                Czynnościami takimi będzie między innymi wszczęcie przez wierzyciela postępowania
                egzekucyjnego <strong> za pośrednictwem komornika </strong>
                , a także co do zasady każda skuteczna czynność przed sądem np. uzyskanie orzeczenia
                lub nadanie na nie klauzuli wykonalności przez wierzyciela, który kupił dług. W
                praktyce oznacza to dwie rzeczy:
                <br /> <strong>1. </strong> Wbrew pozorom i temu co można odnaleźć w internecie może
                dojść do sytuacji w której sprawa w sądzie zakończyła się dwadzieścia lat temu, a
                obecnie prowadzona egzekucja komornicza jest legalna, tzn. dług (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog10}
                  style={{ color: 'orange' }}
                >
                  Cesja wierzytelności. O powiązaniach między pożyczkodawcami i firmami
                  windykacyjnymi
                </a>
                ) nie jest przedawniony. Nie oznacza to jednak, że nie można podważyć takiego
                zadłużenia. Przy podjęciu odpowiednich działań taka egzekucja może bowiem zostać
                umorzona, a samo zadłużenie uznane za przedawnione. (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog2}
                  style={{ color: 'orange' }}
                >
                  Przywrócenie terminu. Jak odczytać pismo od komornika?
                </a>{' '}
                )
                <br /> <strong>2. </strong>Nie należy podejmować decyzji takich jak zawarcie ugody,
                bez konsultacji ze specjalistą, który zajmuje się walką z firmami windykacyjnymi,
                funduszami, bankami i parabankami. Działanie na własną rękę, bez opinii fachowca,
                prowadzi najczęściej do działania na własną niekorzyść. (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog21}
                  style={{ color: 'orange' }}
                >
                  Tricki i nieczyste zagrywki firm windykacyjnych
                </a>
                )
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="4">
              <Header>Podsumowanie</Header>

              <StyledP>
                <Akapit /> Ocena, czy zadłużenie jest przedawnione, czy nie, bardzo często jest
                trudniejsza, niż mogłoby się wydawać. Nie należy się przy tym kierować jedynie
                argumentami, że „dług przedawnia się po trzech albo po sześciu latach”, gdyż są to
                jedynie założenia wyjściowe, które podlegają zmianie w różnego rodzaju
                okolicznościach. Właściwa ocena, czy dług jest przedawniony oraz wykazanie tego
                przed sądem jest w wielu przypadkach kwestią „być albo nie być” oraz rozstrzygnie
                czy będziesz musiał <strong>spłacać dług.</strong> Zgłoś się do nas, przeprowadzimy{' '}
                <strong>darmową analizę</strong>. {''}
                Twojej sprawy pod kątem przedawnienia!
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
