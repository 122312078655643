import styled from 'styled-components';
import Image from '../../assets/blog/art18.jpg';
import { basicRoute } from '../../routes/routes';
import { useLocation } from 'react-router';
import { Header } from '../../components/BlogTextHeder';
import { useEffect } from 'react';
import { Akapit } from '../../components/Akapit/Akapit';
const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Details = {
  head: ' Kwoty wolne od zajęć komorniczych.',
  bigImage: Image,
  text: '',
  tableOfContents: [
    {
      id: '1',
      text: ' Rodzaje egzekucji komorniczych',
    },
    {
      id: '2',
      text: 'Kwoty wolne od zajęć na rachunku bankowym',
    },
    {
      id: '3',
      text: '  Kwoty wolne od zajęcia z wynagrodzenia o pracę i umowy zlecenia',
    },
    {
      id: '4',
      text: '  Co należy wiedzieć o egzekucji z emerytury?',
    },
    {
      id: '5',
      text: ' Tabela kwot wolnych od zajęć egzekucyjnych',
    },
    {
      id: '6',
      text: ' Podsumowanie',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`;
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit />
          Każda osoba zadłużona otrzymując pismo od komornika (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog2} style={{ color: 'orange' }}>
            Przywrócenie terminu. Jak odzczytać pisamo od komornika?
          </a>{' '}
          ) lub wchodząc na stronę internetową banku i uzyskując informację o zajęciu rachunku
          bankowego zastanawia się ile pieniędzy komornik może zająć. Odpowiedź na to pytanie zależy
          głównie od przyczyny zajęcia dokonanego przez komornika, oraz sposobu prowadzenia przez
          niego egzekucji. Na początku warto ustalić z czego komornik będzie egzekwował środki na
          rzecz wierzyciela.{' '}
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>Spis treści :</strong>
        </p>
        <ol style={{ maringLeft: '20px' }}>
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <strong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </strong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper>
        <ol style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li id="1">
              <Header> Rodzaje egzekucji komorniczych</Header>

              <StyledP>
                <Akapit />
                Komornik wszczyna postępowanie egzekucyjne na wniosek wierzyciela np. Prokury,
                Ultimo, BESTa, Kruka, Eques Debitum, Alektum, Providenta, Aasy czy innych funduszy
                windykacyjnych lub parabanków. W takim wniosku określony jest sposób prowadzenia
                egzekucji przez komornika, czy to ze świadczeń pieniężnych czy niepieniężnych.
                Najczęstszymi formami egzekucji, z którymi spotykają się nasi klienci to:
              </StyledP>
              <ul style={{ marginLeft: '30px', marginTop: '10px' }}>
                <li>egzekucja z konta bankowego,</li>
                <li>egzekucja z wynagrodzenia za pracę,</li>
                <li>egzekucja z umowy zlecenia,</li>
                <li>egzekucja z emerytury.</li>
              </ul>
            </li>
          </article>

          <article style={{ marginTop: '15px' }}>
            <li id="2">
              <StyledP>
                <Header> Kwoty wolne od zajęć na rachunku bankowym</Header>
                <Akapit /> Zajęcie rachunku bankowego jest jedną z najpopularniejszych form
                prowadzenia egzekucji. Wielu dłużników z zaskoczeniem odkrywa, że ich rachunek
                bankowy jest zajęty przez komornika, choć nie otrzymali żadnego pisma. Dzieje się
                tak, ponieważ komornik w pierwszej kolejności, korzystając z systemu
                teleinformatycznego, zawiadamia bank o zajęciu danego konta, a dopiero później
                kieruje do dłużnika list informujący o wszczęciu egzekucji. <br />
                <Akapit />
                Najczęściej osoby dotknięte tą formą egzekucji pytają o to “ile pieniędzy komornik
                może zabrać?” lub inaczej „ile pieniędzy komornik powinien pozostawić na koncie?”.
                Podstawową zasadą jest, że wartość kwoty wolnej od zajęcia jest równa 75%
                minimalnego wynagrodzenia brutto osoby, która pracuje w tzw. pełnym wymiarze czasu
                pracy. W 2021 r. kwota wolna od zajęcia wynosiła 2100 zł, natomiast obecnie, w 2022
                roku jest to 2257,50 zł. Warto jednak mieć na względzie, że część pieniędzy, które
                są przelewane na rachunek bankowy nie podlegają zajęciu jak np. świadczenie 500 plus
                i inne świadczenie o charakterze socjalnym. Wolne od zajęcia są również alimenty.
                Jeśli komornik dokona zajęcia takich środków należy go o tym powiadomić. Po
                uzyskaniu i potwierdzeniu takiej informacji powinien zwolnić zajęcie tej części
                rachunku bankowego i zwrócić pieniądze. Jeśli tego nie zrobi należ złożyć skargę na
                czynność komornika w terminie 7 dni liczonych od dnia zajęcia konta.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="3">
              <Header> Kwoty wolne od zajęcia z wynagrodzenia o pracę i umowy zlecenia</Header>

              <StyledP>
                <Akapit />
                Największe obawy budzi jednak zajęcia wynagrodzenia za pracę. W tym przypadku
                również wyznacznikiem jest kwota minimalnego wynagrodzenia netto, której wysokość
                stanowi kwotę wolną od zajęcia komorniczego. W 2021 roku wynosiła ona 2 061,67 zł, a
                w 2022 roku wynosi 2 363,56 zł. Jeśli osoba pracuje na część etatu np. na ½ etatu,
                to kwota wolna od zajęcia jest proporcjonalnie obniżona, obecnie w takiej sytuacji
                wyniosłaby więc połowę kwoty minimalnego wynagrodzenia tj. 1181,78 zł. Kwota wolna
                od zajęcia nie jest taka sama we wszystkich sprawach, albowiem dłużnicy
                alimentacyjni mogą mieć potrącone 60% minimalnego wynagrodzenia. Należy również
                pamiętać, że co do zasady komornik nie może zająć więcej niż połowy wynagrodzenia.
                Biorąc pod uwagę wyżej omówioną kwestię kwoty wolnej od zajęcia, ta ochrona będzie
                dotyczyła osób zarabiających powyżej 4 726,00 zł netto
                <br />
                <Akapit /> Wiele osób jest zatrudnionych na umowie zlecenia. Oni również zostali
                objęci ochroną od zajęcia egzekucyjnego. W przypadku, gdy jedynym źródłem dochodu
                jest wynagrodzenie z tej umowy cywilnoprawnej, to dochód równy minimalnemu
                wynagrodzeniu jest wolny od zajęcia egzekucyjnego. Trzeba jednak pamiętać, że
                przypadku dochodu z umowy o pracę i umowy zlecenia, to tylko ta pierwsza umowa
                podlega ochronie od zajęcia, a z drugiej umowy komornik może potrącić całość
                wynagrodzenia.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="4">
              <Header>Co należy wiedzieć o egzekucji z emerytury i renty?</Header>

              <StyledP>
                <Akapit />
                Podstawową zasadą stosowaną przy egzekucji z emerytury oraz, renty jest możliwość
                zajęcia maksymalnie 25 % kwoty świadczenia, o ile zadłużenie nie wynika z długów
                alimentacyjnych. W przypadku świadczeń alimentacyjnych komornik może prowadzić
                egzekucję do wysokości 60% kwoty świadczenia, natomiast w przypadku należności
                związanych z odpłatnością za pobyt w domach pomocy społecznej, zakładach
                opiekuńczo-leczniczych czy zakładach pielęgnacyjno-opiekuńczych komornik może zająć
                do 50% renty lub emerytury. Jednocześnie komornik musi pozostawić emerytowi czy
                renciście kwoty wolne od potrąceń realizowanych ze świadczeń należnych z
                ubezpieczenia społecznego, która od 1 marca 2021 roku wynosi 916,07 zł w przypadku
                emerytury lub renty z tytułu całkowitej niezdolności do pracy. W przypadku renty z
                tytułu częściowej niezdolności do pracy kwota wolna od zajęcia wynosi 732,86 zł.
                Warto również mieć na uwadze, że kwota ta jest co roku waloryzowana na zasadach
                określonych dla emerytur i rent. Emeryci nie muszą się też martwić, że komornik
                zajmie im tzw. „trzynastą emeryturę”, albowiem jest ona wolna od egzekucji
                komorniczej.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="5">
              <table>
                <tr>
                  <th>Rodzaj zajęcia</th>
                  <th>Kwota wolna od zajęć</th>
                  <th>Wyjątki</th>
                </tr>
                <tr>
                  <td>Na rachunku bankowym</td>
                  <td>75% minimalnego wynagrodzenia brutto osoby - 2257,50 zł</td>
                  <td>
                    Komornik nie może zająć świadczeń 500+ i innych świadczeń o charakterze
                    socjalnym
                  </td>
                </tr>
                <tr>
                  <td>Z wynagrodzenia za pracę</td>
                  <td>
                    100 % minimalnego wynagrodzenia netto - 2 363,56 zł w przypadku całego etatu. W
                    przypadku części etatu, kwota jest proporcjonalnie zmniejszona.
                  </td>
                  <td>Komornik może zająć maksymalnie 50% pensji</td>
                </tr>
                <tr>
                  <td>Z wynagrodzenia za umowę zlecenia</td>
                  <td>Gdy jest to jedyny dochód, to 100 % minimalnego wynagrodzenia netto</td>
                  <td>
                    Gdy jest to dodatkowe źródło dochodu, to komornik może zająć 100 %
                    wynagrodzenia.
                  </td>
                </tr>
                <tr>
                  <td>Z emerytury i renty</td>
                  <td>
                    Kwota wolna od zajęcia: 916,07 zł. W przypadku emerytury wyższej: <br /> - do 25
                    % kwoty świadczenia w przypadku długów niealimentacyjnych <br /> - do 50% kwoty
                    świadczenia w przypadku należności związanych z odpłatnością za pobyt w domach
                    pomocy społecznej, zakładach opiekuńczo-leczniczych czy zakładach
                    pielęgnacyjno-opiekuńczych
                  </td>
                  <td>Komornik nie może zająć tzw. „trzynastej emerytury”</td>
                </tr>
              </table>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="6">
              <StyledP>
                <Header>Podsumowanie</Header>
                <Akapit /> Kwoty wolne od zajęć komorniczych umożliwiają osobom zadłużonym
                zachowanie części środków pieniężnych w przypadku egzekucji, natomiast nie pozwolą
                ustrzec się przed niecelową egzekucją wszczętą na podstawie błędnie doręczonego
                nakazu zapłaty. (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog3}
                  style={{ color: 'orange' }}
                >
                  Jak złożyć sprzeciw od nakazu zapłaty?
                </a>
                ). Jeśli chcesz uzyskać bezpłatną analizę swojej sprawy i dowiedzieć się jak możesz
                pozbyć się egzekucji komorniczej, a także anulować swój dług, zgłoś się do nas przez
                formularz informacyjny, napisz email lub zadzwoń.
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
