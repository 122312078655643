import styled from 'styled-components';
import Image from '../../assets/blog/art22.jpg';
import { basicRoute } from '../../routes/routes';
import { useLocation } from 'react-router';
import { Header } from '../../components/BlogTextHeder';
import { useEffect } from 'react';
import { Akapit } from '../../components/Akapit/Akapit';
const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Details = {
  head: 'Jak działa komornik ?',
  bigImage: Image,
  text: '',
  tableOfContents: [
    {
      id: '1',
      text: ' Pracownik firmy windykacyjnej, a komornika;',
    },
    {
      id: '2',
      text: 'Kiedy komornik może podjąć działania;',
    },
    {
      id: '3',
      text: '  Jak działa komornik;',
    },
    {
      id: '4',
      text: ' Egzekucja z nieruchomości i ruchomości;',
    },

    {
      id: '5',
      text: 'Podsumowanie.',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`;
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit />
          Bardzo często w trakcie komunikacji z dłużnikami{' '}
          <b>firmy windykacyjne grożą skierowaniem sprawy do komornika</b>. Jednak wbrew ich
          twierdzeniom przeprowadzenie egzekucji wcale nie jest takie proste i szybkie. Przed
          skierowaniem zadłużenia do egzekucji i zajęciem konta, pensji czy licytacją nieruchomości
          wierzyciel musi wygrać sprawę w sądzie. Dopiero po uprawomocnieniu się nakazu zapłaty czy
          wyroku, sprawą może zająć się organ egzekucyjny.{' '}
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>Spis treści :</strong>
        </p>
        <ol style={{ maringLeft: '20px' }}>
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <strong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </strong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper>
        <ol style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li id="1">
              <Header>Pracownik firmy windykacyjnej, a komornika</Header>

              <StyledP>
                <Akapit /> Częstą pomyłką, chętnie wykorzystywaną przez firmy windykacyjne, jest
                mylenie przed dłużników tzw. terenowego pracownika firmy windykacyjnej z
                komornikiem. Należy w tym miejscu podkreślić, że pracownicy terenowi firm
                windykacyjnych <strong>nie są komornikami sądowymi.</strong> Takie osoby to zwykli
                pracownicy działów windykacji parabanków i funduszy inwestycyjnych, którym nie
                przysługują, żadne specjalne uprawnienia. W rzeczywistości dłużnik do którego
                przychodzą nie musi nawet z nimi rozmawiać.
              </StyledP>
            </li>
          </article>

          <article style={{ marginTop: '15px' }}>
            <li id="2">
              <Header>Kiedy komornik może podjąć działania </Header>

              <StyledP>
                <Akapit />
                Podstawą do wszczęcia egzekucji sądowej za pośrednictwem komornika jest{' '}
                <strong> tytuł wykonawczy.</strong> Jest to orzeczenie sądowe, które z reguły musi
                się uprawomocnić i zostać zaopatrzone przez sąd w klauzulę wykonalności tj.
                adnotację potwierdzającą, że dane orzeczenie jest już prawomocne oraz podlega
                wykonaniu.{' '}
                <strong>
                  Dopiero po nadaniu na orzeczenie sądowe klauzuli, sprawa może zostać skierowana do
                  komornika.{' '}
                </strong>
                <br />
                <Akapit />
                Firmom windykacyjnym z reguły zależy na tym, aby takim orzeczeniem były nakaz
                zapłaty wydany w postępowaniu upominawczym (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog1}
                  style={{ color: 'orange' }}
                >
                  jak zrozumieć nakaz zapłaty i pozew ?
                </a>
                ) lub elektronicznym postępowaniu upominawczym (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog5}
                  style={{ color: 'orange' }}
                >
                  Elektroniczne postępowanie upominawcze, czyli słów parę o tzw. e-sądzie
                </a>
                ). Jest to bowiem najprostszy i najszybszy sposób na uzyskanie prawomocnego
                orzeczenia sądowego. W przypadku tych postępowań wystarczy, że osoba pozwana w ciągu
                dwóch tygodni od daty odebrania nakazu zapłaty nie złoży sprzeciwu(czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog3}
                  style={{ color: 'orange' }}
                >
                  Jak złożyć sprzeciw od nakazu zapłaty?
                </a>
                ) , aby nakaz się uprawomocnił i po zaopatrzeniu w klauzulę, był podstawą do
                wszczęcia egzekucji komorniczej. <br />
                <Akapit />
                Wyjątkiem od wyżej omówionej reguły jest nakaz zapłaty wydany w postępowaniu
                nakazowym. W jego przypadku już z chwilą wydania,{' '}
                <strong>nawet bez zaopatrywania w klauzulę wykonalności,</strong>
                może on zostać wykorzystany w celu zabezpieczenia roszczenia dochodzonego przez
                firmę windykacyjną lub pożyczkową, czyli np.{' '}
                <strong> zajęcia rachunku bankowego lub wynagrodzenie z tytułu pracy.</strong> Tego
                typu orzeczenia mogą być na szczęście wydawane jedynie w ściśle określonych
                przypadkach i najczęściej zapadają w sprawach, w których pożyczka był zabezpieczona
                wekslem. Warto dodać, że pożyczkodawcą, który specjalizuje się w takich praktykach
                jest firma Profi Credit (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog16}
                  style={{ color: 'orange' }}
                >
                  jak nie wystraszyć się proficredit?
                </a>
                ). <br />
                <Akapit />
                Kolejnym wyjątkiem jest wyrok zaoczny. Może on zostać wydany w sytuacji kiedy osoba
                pozwana nie weźmie udziału w postępowaniu sądowym. Ten rodzaj orzeczenia jest{' '}
                <strong>natychmiast wykonalny</strong>. Oznacza to, że powód nie musi czekać na jego
                uprawomocnienie i od razu może wystąpić o nadanie klauzuli wykonalności, a następnie
                skierować sprawę do komornika.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="3">
              <Header> Jak działa komornik</Header>

              <StyledP>
                <Akapit /> Po uzyskaniu tytułu wykonawczego, sprawa może “przeleżeć” bez podjęcia
                żadnych kolejnych działań ze strony wierzyciela nawet wiele miesięcy, zanim trafi do
                komornika. Wynika to z faktu, że firmy windykacyjne takie jak Kruk, Prokura, Ultimo,
                Hoist, Getback (obecnie Asseta) , Best, Eques Debitum, EOS, Omega, Centauris,
                Alektum oraz firmy pożyczkowe (tzw. “chwilówki”) prowadzą bardzo wiele spraw. Bardzo
                często zdarzają się im więc opóźnienia, a ich działania nie zawsze są racjonalne.{' '}
                <strong>
                  Można być jednak pewnym, że przypilnują, aby sprawa trafiła do komornika zanim
                  dojdzie do przedawnienia długu{' '}
                </strong>
                (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog4}
                  style={{ color: 'orange' }}
                >
                  jak liczyć przedawnienie?
                </a>
                ) . Po skierowaniu zadłużenia do postępowania egzekucyjnego sprawę zaczyna prowadzić
                komornik. Jego działania są jednak ściśle uzależnione od żądań wierzyciela, komornik
                działa bowiem na jego zlecenia. <br />
                <Akapit />
                <strong>
                  Najczęściej komornik w pierwszej kolejności zajmuje wynagrodzenie, konto bankowe,
                  rentę lub emeryturę dłużnika.
                </strong>{' '}
                Jest to bowiem najszybsza i najprostsza droga do zaspokojenia wierzyciela, tj.
                odzyskania dla niego długu. Z reguły informacja o zajęciu w pierwszej kolejności
                trafia do banku, pracodawcy dłużnika lub organu wypłacającego dane świadczenia, a
                dopiero później do samego zainteresowanego. Warto pamiętać, że w przypadku egzekucji
                z ww. źródeł, komornika obowiązują <strong>kwoty wolne od zajęć</strong> (czyt.
                także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog18}
                  style={{ color: 'orange' }}
                >
                  Kwoty wolne od zajęć komorniczych.
                </a>
                ), które komornik musi zostawić dłużnikowi. Dłużnik w takiej sytuacji powinien
                otrzymać od komornika postanowienie o wszczęciu egzekucji. Jest to bardzo ważne
                pismo, ponieważ wskazuje na jakiej podstawie komornik prowadzi egzekucję. Takie
                pismo powinno zawierać nie tylko informację o wysokości zadłużenia,{' '}
                <strong>
                  ale również wskazywać na podstawie orzeczenia jakiego sądu egzekucja jest
                  prowadzona.{' '}
                </strong>
                Ta informacja umożliwia ustalenie w sądzie lub bezpośrednio u wierzyciela z czego
                wynika dany dług.{' '}
                <strong>
                  Może się również zdarzyć, że pomimo tego, iż sprawa została już skierowana do
                  komornika, w trakcie rozmowy z sądem ujawnią się okoliczności umożliwiające
                  uniknięcia spłaty zadłużenia.{' '}
                </strong>
                (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog2}
                  style={{ color: 'orange' }}
                >
                  przywrócenie terminu jak odczytać pismo od komornika?
                </a>
                ).
                <br />
                <Akapit />
                Wraz z postanowieniem o wszczęciu egzekucji komornicy często wysyłają również
                wezwanie do wskazania składników majątku dłużnika. Należy je wypełnić oraz w
                zakreślonym czasie dostarczyć organowi egzekucyjnemu.{' '}
                <strong>W innym przypadku komornik może nałożyć na dłużnika grzywnę. </strong>Należy
                również pamiętać, że z reguły komornicy weryfikują stan posiadanego przez dłużnika
                majątku w oficjalnych bazach lub rejestrach. Jeśli więc na czyjeś nazwisko
                zarejestrowany jest rachunek bankowy lub samochód komornik z pewnością się o tym
                dowie.
              </StyledP>
            </li>
          </article>

          <article style={{ marginTop: '15px' }}>
            <li id="4">
              <Header>Egzekucja z nieruchomości i ruchomości</Header>

              <StyledP>
                <Akapit />
                Rzadziej stosowaną formą egzekucji jest licytacja nieruchomości tzn. gruntów lub
                mieszkań albo ruchomości tj. wszystkich rzeczy niezwiązanych trwale z gruntem. Jest
                to bardziej skomplikowana forma zaspokojenia roszczeń wierzyciela, ponieważ wymaga
                zajęcia danego obiektu (w przypadku ruchomości konieczne jest ich fizyczne
                oznaczenie). Następnie zajęte obiekty muszą zostać oszacowane i dopiero wtedy
                wystawione na licytację. Także w przypadku tych form egzekucji komornik ograniczony
                jest przepisami prawa np. wyłączającymi spod zajęcia różne przedmioty. Jest to
                również procedura długotrwała i z tego powodu niechętnie stosowana przez komorników
                oraz wierzycieli.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="5">
              <Header>Podsumowanie.</Header>

              <StyledP>
                <Akapit />
                Na koniec warto dodać, że na każdą czynność komornika przysługuje skarga, którą
                dłużnik może złożyć w ciągu 7 dni od daty jej dokonania, do danego komornika. Jeśli
                komornik się z nią nie zgodzi w ciągu 3 dni musi przekazać ja do sądu, który
                ponownie ją rozpatrzy. Warto pamiętać, że do złożenia takiej skargi musi istnieć
                określona podstawa. Żeby ustalić, czy w danym przypadku złożenie skargi na czynności
                komornika będzie zasadne należy skontaktować się z{' '}
                <strong>doświadczonym prawnikiem.</strong>
                <br /> <Akapit />
                Dostałeś pismo od komornika? Skontaktuj się z nami, przeprowadzimy{' '}
                <strong>darmową analizę</strong> twojej sprawy i ustalimy plan działania, który może
                doprowadzić do <strong>umorzenia długu!</strong>
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
