import { basicRoute } from '../routes/routes';

export const navLinks = [
  {
    text: 'Strona główna',
    route: basicRoute.home,
  },
  {
    text: 'O nas',
    route: basicRoute.about,
  },
  {
    text: 'Jak działamy',
    route: basicRoute.workflow,
  },
  {
    text: 'Blog',
    route: basicRoute.blog,
  },
  {
    text: 'Kontakt',
    route: basicRoute.contact,
  },
];

export const allLinks = [
  ...navLinks,
  // {
  //   text: 'Regulamin i Polityka Prywatności',
  //   route: basicRoute.statute,
  // },
];
