import { actionTypes } from '../../constants/actionTypes';

const INITIAL_STATE = {
  isVisible: false,
};

const FreeAdviceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_VISIBILITY:
      return { ...state, isVisible: !state.isVisible };
    default:
      return state;
  }
};

export default FreeAdviceReducer;
