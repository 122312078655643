import React, { useEffect} from 'react';
import styled from 'styled-components';

const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  align-self: center;
`;

const StyledP = styled.p`
  text-align: justify;
  max-width: 700px;
  margin-inline: auto;
`;

const content = [
  'Dzięki doświadczeniu zdobytemu w trakcie postępowań sądowych oraz egzekucyjnych, zagwarantujemy Państwu nie tylko szybką i skuteczną pomoc prawną, ale również wytłumaczymy w jaki sposób poszczególne przepisy przekładają się na codzienne życie osoby zadłużonej i w jaki sposób należy ją wykorzystywać.',
  'Dzięki połączeniu prawniczej teorii oraz wiedzy o rzeczywistych procedurach, którymi kierują się sędziowie, komornicy czy pracownicy terenowi firm windykacyjnych jesteśmy w stanie doradzić Państwu działania, które będą skuteczne w Państwa konkretnej sytuacji oraz zgodne z prawem.',
  'Uważamy, że sama znajomość przepisów to za mało i nie wystarczy odnalezienie ich w internecie, tak samo jak niewystarczającym jest korzystanie z pomocy osób, które znają treść przepisów, ale nie umieją się nimi posługiwać.Łatwo bowiem poznać zapisy prawa, ale o wiele ciężej je zrozumieć i potrafić z nich właściwie korzystać.',
];

const About = () => {
  useEffect(() => {
     document.title = "O nas - Kancelaria Radcy Prawnego - Sprzeciw Długom";  
   }, []);
  
  return (
    <>
      <StyledH1>O nas</StyledH1>
      <StyledP>
        <strong>sPrzeciw Długom.pl</strong> zrzesza radców prawnych, aplikantów radcowskich oraz
        prawników, którzy od lat walczą z firmami windykacyjnymi oraz parabankami, próbującymi
        dochodzić nienależnych lub zawyżonych długów.
      </StyledP>
      {content.map((p, i) => (
        <StyledP key={i + i * (Math.random() * 100)}>{p}</StyledP>
      ))}
    </>
  );
};

export default About;
