import styled from 'styled-components';
export const SubmitFormButton = styled.button`
  display: flex;
  border: 2px solid ${({ theme }) => theme.colors.darkBlue};
  border-radius: ${({ theme }) => theme.border.radius};
  padding: 0.75em 3em;
  background-color: ${({ theme }) => theme.colors.orange};
  color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
  gap: 0.5em;
`;
