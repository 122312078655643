export const basicRoute = {
  home: '/',
  about: '/o-nas',
  contact: '/kontakt',
  workflow: '/jak-dzialamy',
  blog: '/blog',
  statute: '/regulamin',
  privacyPolicy: '/regulamin',
  details: '/wpis',
  admin: '/admin',
  login: '/login',
  blog1: '/jak-zrozumieć-nakaz-zapłaty-i-pozew',
  blog2: '/przywrócenie-terminu-jak-odzczytać-pisamo-od-komornika',
  blog3: '/jak-złożyć-sprzeciw-od-nakazu-zapłaty',
  blog4: '/jak-liczyć-przedawnienie',
  blog5: '/e-sąd',
  blog55: '/jak-załozyć-konto-na-e-sądzie',
  blog8: '/jak-sprzwdzić-czy-mam-zadłużenia',
  blog10: '/cesja-wierzytelności',
  blog12: '/klauzule-abuzywne',
  blog11: '/refinansowanie',
  blog13: '/uokik',
  blog14: '/kiedy-warto-isć-na-ugodę',
  blog16: '/jak-nie-wstraszyć-się-proficredit',
  blog18: '/kwoty-wolne-od-zajęć-komorniczych',
  blog21: '/tricki-i-nieczyste-zagrywki-firm-windykacyjnych',
  blog22: '/jak-działa-komornik',
};
