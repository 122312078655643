import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logoDesktop from '../../assets/orange1.png';
import logoMobile from '../../assets/orange2.png';

import { basicRoute } from '../../routes/routes';
import FreeAdviceButton from '../FreeAdviceButton';
import {
  StyledMdAccessTime,
  StyledMdPhone,
  StyledMdEmail,
  StyledFacebook,
  StyledMdPrivacyTip,
} from '../Header/TimeBar';
import { allLinks, personalData } from '../../constants';

const StyledFooter = styled.footer``;

const Logo = styled(Link)`
  display: block;
  max-width: 240px;
  margin-inline: auto;
  padding: 0.5rem 0;
  @media (min-width: 640px) {
    display: none;
  }
`;

const StyledImage = styled.img`
  width: 100%;
`;

const MegaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 2rem 0;
  background: ${({ theme }) => theme.colors.darkBlue};
`;

const DesktopLogo = styled(Link)`
  display: none;
  @media (min-width: 640px) {
    display: block;
    max-width: 90px;
    margin-right: 0.5rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const NotMobileWrapper = styled.div`
  display: none;
  @media (min-width: 640px) {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
`;

const StyledH4 = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.ml};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  text-align: center;
  margin-bottom: 0.25rem;
  @media (min-width: 640px) {
    text-align: left;
  }
`;

const StyledP = styled.p`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: ${({ theme }) => theme.fontSize.s};
  color: ${({ theme }) => theme.colors.white};
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

const StyledNotice = styled.div`
  padding: 0.25rem 0.375rem;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.konfederacja};
  font-size: ${({ theme }) => theme.fontSize.s};
  text-align: center;
`;

const StyledLinkHref = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: ${({ theme }) => theme.fontSize.s};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

const contactData = [
  {
    icon: <StyledMdPhone />,
    data: personalData.phone,
    id: 101,
    link: 'tel:+48881397639',
  },
  {
    icon: <StyledMdEmail />,
    data: personalData.email,
    id: 201,
    link: 'mailto:kontakt@sprzeciwdlugom.pl',
  },
  {
    icon: <StyledMdAccessTime />,
    data: personalData.openTime,
    id: 203,
  },
  {
    icon: <StyledFacebook />,
    data: personalData.facebook,
    id: 204,
    link: 'https://www.facebook.com/Sprzeciwdlugompl-106305545356846',
    rel: 'nofollow',
  },
  {
    icon: <StyledMdPrivacyTip />,
    data: personalData.privacy,
    id: 204,
    link: 'https://www.sprzeciwdlugom.pl/regulamin',
  },
];

const Footer = () => {
  return (
    <StyledFooter>
      <Logo to={basicRoute.home}>
        <StyledImage src={logoMobile} alt="logo" />
      </Logo>
      <MegaWrapper>
        <DesktopLogo to={basicRoute.home}>
          <StyledImage src={logoDesktop} alt="logo" />
        </DesktopLogo>
        <Wrapper>
          <StyledH4>Skontaktuj się</StyledH4>
          {contactData.map((data, i) =>
            data?.link ? (
              <StyledLinkHref href={data.link} key={data.id + i} target="_blank" rel="noreferrer">
                {data.icon} {data.data}
              </StyledLinkHref>
            ) : (
              <StyledP key={i + data.id}>
                {data.icon} {data.data}
              </StyledP>
            ),
          )}
          <FreeAdviceButton text="DARMOWA PORADA" />
        </Wrapper>
        <NotMobileWrapper>
          <StyledH4>Mapa strony</StyledH4>

          {allLinks.map((link, i) => (
            <StyledLink key={i + link.text} to={link.route}>
              {link.text}
            </StyledLink>
          ))}
        </NotMobileWrapper>
      </MegaWrapper>
      <StyledNotice>&#xA9; 2022 sprzeciwdlugom.pl</StyledNotice>
    </StyledFooter>
  );
};

export default Footer;
