import styled from 'styled-components';
import Image from '../../assets/blog/art1.jpg';
import { basicRoute } from '../../routes/routes';
import { useLocation } from 'react-router';
import { Akapit } from '../../components/Akapit/Akapit';
import { Header } from '../../components/BlogTextHeder';
import { useEffect } from 'react';

const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Details = {
  head: 'Przywrócenie terminu. Jak odczytać pismo od Komornika?',
  bigImage: Image,

  tableOfContents: [
    {
      id: '1',
      text: 'Pismo od komornika. Na co zwrócić uwagę ?',
    },
    {
      id: '2',
      text: ' Jak i Kiedy przywrócić sprawę do sądu ?',
    },
    {
      id: '3',
      text: ' Co dalej ?',
    },
    {
      id: '4',
      text: ' Podsumowanie.',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`;
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit /> Przestrzeganie wynikających z kodeksów i ustaw terminów na złożenie sprzeciwu
          lub odpowiedzi na pozew (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog3} style={{ color: 'orange' }}>
            Jak złożyć sprzeciw od nakazu zapłaty?
          </a>
          ) jest niezwykle istotne. Co jednak zrobić w sytuacji, kiedy z różnych powodów nie udało
          Ci się złożyć odpowiedniego pisma w wyznaczonym do tego terminie? Czy w takim wypadku
          istnieje jeszcze możliwość na przywrócenie terminu do skutecznego działania przed sądem i
          umorzenia długu? W pewnych okolicznościach jest to możliwe, nawet jeśli dostałeś już pismo
          informujące Cię o wszczęciu egzekucji komorniczej a sam tytuł wykonawczy (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog22} style={{ color: 'orange' }}>
            jak działa komornik?
          </a>{' '}
          ) (nakaz zapłaty, wyrok zaoczny) został wydany wiele lat temu i nigdy do Ciebie nie
          trafił. Jeśli często w swoim życiu zmieniałeś miejsca zamieszkania? Byłeś wiele lat za
          granicą? Wskutek różnych sytuacji życiowych spędziłeś jakiś czas w zakładzie karnym albo
          placówkach medycznych? Chciałeś wziąć kredyt i okazało się, że jesteś wpisany do bazy BIK
          albo BIG? (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog8} style={{ color: 'orange' }}>
            jak sprzwdzić czy mam zadłużenia?
          </a>{' '}
          ) Nigdy nie dostałeś żadnego pisma z sądu? Zapoznaj się z naszym artykułem i dowiedz się
          kiedy i w jakich okolicznościach będziesz mógł przywrócić termin do działania przed sądem
          oraz jak odczytać pismo od komornika lub wezwanie do zapłaty, które dostałeś na wniosek
          lub bezpośrednio od firmy windykacyjnej, parabanku, banku, czy funduszu inwestycyjnego{' '}
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>Spis treści :</strong>
        </p>
        <ol style={{ maringLeft: '20px' }} id="1">
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <strong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </strong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper>
        <ol style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li>
              <Header> Pismo od komornika. Na co zwrócić uwagę?</Header>
              <StyledP>
                <Akapit /> Dostając pismo od komornika, może się zdarzyć, że nie będziesz wiedzieć w
                sprawie jakiego długu została wszczęta egzekucja. Jako wierzyciel wskazana może być
                firma której nazwy nie znasz, np.{' '}
                <strong>
                  Ultimo, Hoist, Prokura, Eques Debitum, Debito, Kruk, Best, Omega, Centauris
                </strong>
                , czy Horyzont i z którą nigdy nie zawierałeś żadnej umowy. (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog10}
                  style={{ color: 'orange' }}
                >
                  Cesja wierzytelności. O powiązaniach między pożyczkodawcami i firmami
                  windykacyjnymi
                </a>
                ) Może się nawet zdarzyć, że jako podstawa do prowadzenia egzekucji wskazany
                zostanie nakaz zapłaty albo wyrok zaoczny, wydany wiele lat temu, którego nigdy nie
                otrzymałeś.
                <br />
                <Akapit id="2" />W takich okolicznościach często pierwszą decyzją jest telefon do
                komornika z pytaniem “co to za dług”. Odpowiedź często jest zaskakująca i brzmi “nie
                wiem”. Niestety bowiem komornik nie dysponuje pełną dokumentacją sądową w związku z
                czym nie jest w stanie zawsze zweryfikować kto był pierwotnym wierzycielem oraz jak
                powstało dane zadłużenie. Kolejnym krokiem jest wpisanie w wyszukiwarce nazwy
                podmiotu wskazanego jako wierzyciel i próba nawiązania z nim kontaktu. Nie jest to
                najlepsze rozwiązanie. <strong>Windykacja</strong>, która <strong>kupiła</strong>{' '}
                twój <strong>dług</strong> {''}
                nie jest zainteresowana wyjaśnieniem sprawy, a jedynie za pomocą różnego rodzaju
                taktyk i tricków (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog21}
                  style={{ color: 'orange' }}
                >
                  Tricki i nieczyste zagrywki firm windykacyjnych.
                </a>
                ) uzyskaniem od Ciebie jak najszybszej i jak największej wpłaty. <br /> <Akapit />W
                takiej sytuacji, a także kiedy o długu dowiesz się w innych, opisanych na wstępie
                okolicznościach, skontaktuj się z nami. W trakcie <strong>darmowej analizy</strong>{' '}
                opowiesz nam o swojej sprawie. Doświadczony w takich sprawach prawnik zada Ci kilka
                pytań i udzieli informacji jak przywrócić Twoją sprawę na drogę sądową i jak ją
                wygrać. Zadzwoń do nas lub napisz nawet jeśli nie masz przy sobie żadnych
                dokumentów, nie stanowi to dla nas przeszkody aby Ci pomóc.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }} id="15">
            <li>
              <Header> Jak i kiedy przywrócić sprawę do sądu?</Header>
              <StyledP>
                <Akapit />
                Jak już wspomniałem, kluczowym dla możliwości przywrócenia sprawy do sądu, jest fakt
                nieodebrania w przeszłości osobiście nakazu zapłaty lub wyroku zaocznego. W związku
                z tym, iż znamy właściwe przepisy prawne, ich interpretacje, a także właściwe dla
                tego typu spraw orzecznictwo sądowe będziemy w stanie podważyć prawomocność
                orzeczenia sądowego poprzez uchylenie tzw. fikcji doręczenia przesyłki sądowej{' '}
                <strong>
                  (jest to taka sytuacja w której sąd uznaje przesyłkę za doręczoną nawet jeśli ta
                  nigdy nie trafiła do rąk osoby pozwanej)
                </strong>
                . Co za tym idzie możemy “cofnąć” Twoją sprawę do momentu, kiedy pismo z sądu było
                do Ciebie wysłane. Będzie to oznaczało nie tylko umorzenie egzekucji, ale również
                możliwość walki o <strong>zmniejszenie lub anulowania całego długu.</strong>{' '}
                Pamiętaj przy tym, że nie wystarczy jedynie abyś nie odebrał nakazu zapłaty
                osobiście. Bardzo istotne jest również miejsce, gdzie pismo to było wysyłane i
                przyjęcie właściwej strategii procesowej. W naszej karierze zawodowej udawało nam
                się wygrywać sprawy, w których nakazy zapłaty czy wyroki były wydawane przez ądy{' '}
                <strong>nawet 15 lat od daty wszczęcia egzekucji.</strong> <br />
                <Akapit /> Przeszkodą do przeprowadzenia operacji przywrócenia terminu nie jest
                również <b>podpisana ugoda</b>, (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog14}
                  style={{ color: 'orange' }}
                >
                  Kiedy warto iść na ugodę?
                </a>
                ) ani to, że dokonałeś wpłat na rzecz windykacji czy komornika. Jeśli nie masz
                pewności czy odbierałeś korespondencję z sądu i na jaki adres była wysłana, a nakaz
                zapłaty w twojej sprawie wydał Sąd Rejonowy Lublin-Zachód w Lublinie, VI Wydział
                Cywilny, to <strong> załóż konto e-sąd </strong> lub w przypadku kiedy orzekał inny
                Sąd koniecznie skontaktuj się z tą instytucją telefonicznie w celu uzyskania ww.
                informacji. Obie te czynności są szybkie i proste, a mogą pozwolić{' '}
                <strong> uwolnić się od zadłużenia!</strong>
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }} id="3">
            <li>
              <Header> Co dalej?</Header>

              <StyledP>
                <Akapit />
                Przywrócenie możliwości do działania w zakończonej już sprawie to bardzo ważny, ale
                dopiero pierwszy z kroków jakie należy wykonać, aby na zawsze{' '}
                <strong>anulować dług.</strong>
                Kolejnym krokiem jest wystąpienie do komornika o umorzenie toczącej się egzekucji.
                Komornicy nierzadko umarzają egzekucję niechętnie i szukają każdego pretekstu, aby
                opóźnić wydanie takiego postanowienia. Stąd bardzo ważne, aby kontaktami z
                komornikiem zajmował się doświadczony prawnik, gdyż postępowanie w takich sytuacjach
                bywa <strong>sformalizowane</strong>, <strong>nieintuicyjne</strong> i wymaga
                szczegółowej znajomości przepisów prawa oraz praktyki działań komorników.
                <br />
                <Akapit />
                Kolejnym kluczowym faktem, z którego musisz zdać sobie sprawę jest to, że samo
                przywrócenie sprawy po latach do Sądu nie oznacza automatycznie jej wygrania. Cały
                proces wymaga kompleksowej obsługi, ponieważ po przywróceniu sprawy “do życia” sąd
                doręczy pozew wraz z nakazem zapłaty, do których należy w{' '}
                <strong>zakreślonym przez sąd terminie merytorycznie się ustosunkować.</strong> Co
                ze względu na sprzedaż wierzytelności (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog10}
                  style={{ color: 'orange' }}
                >
                  Cesja wierzytelności. O powiązaniach między pożyczkodawcami i firmami
                  windykacyjnymi
                </a>
                ), konieczność analizy umowy pożyczki lub kredytu (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog11}
                  style={{ color: 'orange' }}
                >
                  Refinansowanie pożyczki. Jak się nie dać złapać w pętlę kredytową?
                </a>
                ) oraz wielu innych przedłożonych przez powoda dokumentów z reguły nie jest proste.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }} id="4">
            <li>
              <Header>Podsumowanie.</Header>

              <StyledP>
                <Akapit />
                Pamiętaj, że fakt iż sprawa jest u komornika, a nakaz zapłaty lub wyrok zaoczny
                został wydany wiele lat temu, nie przekreśla szansy na{' '}
                <strong> umorzenie Twojego długu. </strong>
                Istnieją sposoby na przywrócenie terminu do działania przed sądem, jest to jednak
                dopiero początek drogi. Kolejne etapy,{' '}
                <strong>czyli umorzenie Twojej egzekucji komorniczej</strong> i finalne{' '}
                <strong>wygranie postępowania sądowego</strong> wymaga prowadzenia spraw przez
                doświadczony zespół prawników. Jeśli nie chcesz spłacać przedawnionych długów
                skontaktuj się z nami! Przeprowadzimy <strong>darmową analizę</strong> Twojej
                sprawy, dzięki czemu dowiesz się jak przywrócić termin do skutecznego działania!
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
