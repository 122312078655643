import styled from 'styled-components';
import Image from '../../assets/blog/art3.jpg';
import Image1 from '../../assets/blog/art3.1.jpg';
import { basicRoute } from '../../routes/routes';
import { useLocation } from 'react-router';
import { Akapit } from '../../components/Akapit/Akapit';

import { Header } from '../../components/BlogTextHeder';
import { useEffect } from 'react';

const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Details = {
  head: 'Jak złożyć sprzeciw od nakazu zapłaty ?',
  bigImage: Image,
  text: 'Nakaz zapłaty jest doręczany przez sąd listem poleconym. Po otrzymaniu korespondencji z sądu masz dwa tygodnie na złożenie sprzeciwu od nakazu zapłaty, liczone od daty odebrania listu.  Sprzeciw należy wnieść bezpośrednio do sądu lub listem poleconym na adres sądu w terminie.  Jeżeli pozew został wniesiony na urzędowym formularzu, sprzeciw także można wnieść w takiej formie.',
  tableOfContents: [
    {
      id: '1',
      text: 'Ogólne zasady wnoszenia sprzeciwu.',
    },
    {
      id: '2',
      text: ' Sprzeciw w Elektronicznym Postępowaniu Upominawczym.',
    },
    {
      id: '3',
      text: ' Sprzeciw w „zwykłym” Sądzie Rejonowym.',
    },
    {
      id: '4',
      text: ' Jak ustrzec się błędów przy składaniu sprzeciwu od nakazu zapłaty?',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;
const ImgInsideWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const HeadingWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: center;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`;
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit /> Dostałeś nakaz zapłaty w postępowaniu upominawczym i dowiedziałeś się, że firma
          windykacyjna taka jak Prokura, BEST, Ultimo, Eques Debitum czy Alektum żąda od Ciebie
          pieniędzy? Zastanawiasz się co teraz zrobić? Czytasz pouczenie i zastanawiasz się czy nie
          lepiej dogadać się z wierzycielem? (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog1} style={{ color: 'orange' }}>
            Jak zrozumieć nakaz zapłaty i pozew?
          </a>
          ) Mamy dla Ciebie odpowiedź – <b>złóż sprzeciw </b>(z pomocą doświadczonych w tym
          prawników).
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>Spis treści:</strong>
        </p>
        <ol style={{ maringLeft: '20px' }} id="1">
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <strong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </strong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper>
        <ol style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li>
              <Header>Ogólne zasady wnoszenia sprzeciwu</Header>
              <StyledP>
                <Akapit /> Nakaz zapłaty jest doręczany przez sąd listem poleconym. Po otrzymaniu
                korespondencji z sądu masz{' '}
                <b>
                  dwa tygodnie na złożenie sprzeciwu od nakazu zapłaty, liczone od daty odebrania
                  listu.
                </b>{' '}
                Sprzeciw należy wnieść bezpośrednio do sądu lub listem poleconym na adres sądu we
                właściwym do tego terminie. Jeżeli pozew został wniesiony na urzędowym formularzu,
                sprzeciw także można wnieść w takiej formie.
              </StyledP>
              <HeadingWrapper>
                <h1>(Wzór pierwszej strony formularza sprzeciwu od nakazu zapłaty)</h1>
              </HeadingWrapper>

              <ImgInsideWrapper>
                <StyledImage src={Image1} alt="text" />
              </ImgInsideWrapper>
              <StyledP id="2">
                <Akapit /> W sprzeciwie od nakazu zapłaty pozwany powinien wskazać, czy zaskarża
                nakaz zapłaty w całości, czy w części. Ponadto należy przedstawić{' '}
                <strong>wszystkie zarzuty</strong> względem twierdzeń powoda oraz ewentualnie złożyć
                wraz ze sprzeciwem dowody, które zaprzeczą żądaniom powoda.{' '}
                <strong>
                  Jeśli właściwe zarzuty nie zostaną podniesione w sprzeciwe sąd może nie zezwolić
                  na podniesie ich w kolejnych pismach!
                </strong>
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li>
              <Header> Sprzeciw w Elektronicznym Postępowaniu Upominawczym</Header>

              <StyledP>
                <Akapit /> Sąd Rejonowy Lublin-Zachód, VI Wydział cywilny, czyli tzw. e-sąd (czyt.
                także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog5}
                  style={{ color: 'orange' }}
                >
                  Elektroniczne postępowanie upominawcze, czyli słów parę o tzw. e-sądzie
                </a>
                ) doręcza nakaz zapłaty z pozwem, lecz bez dowodów, których powód nie składa do
                sądu. Wynika to z maksymalnego uproszczenia tego postępowania oraz oznacza, że sąd
                wydaje nakaz zapłaty jedynie w oparciu o twierdzenia powoda. Nie oznacza to jednak,
                że sprzeciw może być napisany byle jak i bez przemyślenia dalszej strategii
                procesowej. Treść sprzeciwu może mieć bowiem wpływ na ostateczną decyzję sądu.
                Niezwykle istotną kwestią jest także zachowanie wszelkich{' '}
                <strong>wymogów formalnych</strong> podczas składania i sporządzania sprzeciwu, aby
                został on uznany za wniesiony w wymagany prawem sposób.
                <br />
                <br /> <Akapit /> W sprawach wniesionych do E-sądu do 7 lutego 2020 roku złożenie
                sprzeciwu od nakazu{' '}
                <strong>
                  powodowało uchylenie nakazu zapłaty i przekazanie sprawy do Sądu Rejonowego
                </strong>{' '}
                właściwego ze względu na według miejsce zamieszkania pozwanego. Następnie powód był
                zobowiązany do uzupełnienia braków formalnych tj. do przedłożenia materiału
                dowodowego wymienionego w pozwie złożonym na etapie postępowania elektronicznego.
                Niewykonanie tego obowiązku powodowało umorzenie postępowania. Tą procedurę stosuje
                się również do spraw, które można „przywrócić” do sądu z powodu wysłania w
                przeszłości nakazu zapłaty na nieaktualny adres zamieszkania osoby pozwanej.(czyt.
                także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog2}
                  style={{ color: 'orange' }}
                >
                  Przywrócenie terminu. Jak odczytać pismo od Komornika?
                </a>
                )<br />
                <br />
                <Akapit /> Obecnie po skutecznym wniesieniu sprzeciwu od nakazu zapłaty Sąd Rejonowy
                Lublin-Zachód w Lublinie, VI Wydział Cywilny uchyla nakaz zapłaty i umarza
                postępowanie. Pamiętać jednak trzeba, że powód – firma windykacyjna czy
                pożyczkodawca ma{' '}
                <strong>następnie możliwość wniesienia pozwu do „zwykłego” Sądu Rejonowego</strong>,
                a jeśli zrobi to w terminie trzech miesięcy od dnia wydania postanowienia o
                umorzeniu elektronicznego postępowania upominawczego, to skutki prawne, które ustawa
                wiąże z wytoczeniem powództwa, następują z dniem wniesienia pozwu w elektronicznym
                postępowaniu upominawczym. Oznacza to, że pomimo skutecznego wniesienia sprzeciwu
                sprawa wcale <strong>nie musi się zakończyć</strong>, a pisma składane w toku
                upominawczego postępowania elektronicznego będą wywoływać również skutki w ponownie
                wytoczonym postępowaniu!
              </StyledP>
              <br />
            </li>
            <li id="3">
              <Header> Sprzeciw w „zwykłym” Sądzie Rejonowym.</Header>
              <StyledP>
                <Akapit /> Nakaz zapłaty doręczany przez „zwykły” Sąd Rejonowy, inaczej niż w
                przypadku e-sądu, doręczany jest wraz ze <strong>wszystkimi załącznikami</strong>, w
                których istotne znaczenie mają dokumenty przedłożone przez firmę windykacyjną, bank,
                czy firmę pożyczkową (Super Grosz, Kuki, Mondeo etc.) jako dowody.{' '}
                <strong>
                  Przed złożeniem sprzeciwu materiał dowodowy należy dokładnie przeanalizować,
                  albowiem od tego zależy trafne podniesienie zarzutów.
                </strong>{' '}
                Zdarza się bowiem, że dokumenty są niekompletne i zasygnalizowanie tego sądowi za
                pomocą odpowiednich argumentów może doprowadzić nawet do{' '}
                <strong>oddalenia powództwa w całości.</strong> Dlatego w takiej sytuacji warto
                zgłosić się do prawnika, który przeanalizuje sprawę i pomoże zmaksymalizować szanse
                jej pozytywnego zakończenia.{' '}
              </StyledP>
              <br />
            </li>
            <li>
              <StyledP>
                <Header id="4">
                  Jak ustrzec się błędów przy składaniu sprzeciwu od nakazu zapłaty?
                </Header>
                <Akapit /> Niejednokrotnie zdarza się, że złożony samodzielnie przez pozwanego
                sprzeciw jest obarczony <strong>brakami formalnymi.</strong> Trzeba również
                pamiętać, aby sam sprzeciw <strong>złożyć w wymaganym do tego czasie.</strong>{' '}
                Sprzeciw wniesiony po upływie wymaganego terminu, niedopuszczalny lub zawierający
                nieuzupełnione braki formalne – <strong>sąd odrzuci</strong>, co doprowadzi do{' '}
                <strong>uprawomocnienia się nakazu zapłaty,</strong> a w efekcie do ewentualnego{' '}
                <strong>wszczęcia egzekucji przez komornika</strong> ze wszystkimi tego
                konsekwencjami. Dlatego tak istotne jest zachowanie odpowiedniej formy sprzeciwu
                oraz wniesienie go w odpowiednim terminie. To połowa sukcesu. Należy bowiem
                pamiętać, że poprawne złożenie sprzeciwu jest początkiem postępowania sądowego, w
                którego trakcie konieczne może okazać się kolejnych pism procesowych w odpowiedzi na
                pisma powoda.
                <br />
                <br /> <Akapit />
                Osobom, które działają na własną rękę, nie posiadającym odpowiedniej wiedzy
                prawniczej oprócz błędów formalnych zdarzają się błędy merytoryczne w postaci
                podniesienia niewłaściwych zarzutów, które mają wpływ na końcowy wynik postępowania
                i mogą doprowadzić do <strong>zasądzenia zadłużenia w całości.</strong> Dlatego
                niezwykle ważne jest, żeby sprzeciw od nakazu zapłaty został wniesiony przez
                profesjonalistów, którzy wiedzą jak działania należy podjąć, aby doprowadzić do{' '}
                <strong>zmniejszenia roszczenia</strong> lub do{' '}
                <strong>oddalenia powództwa w całości.</strong> Aby zmaksymalizować szanse na
                wygraną, potrzebne są wiedza, doświadczenie i indywidualne podejście do sprawy. W
                sytuacji gdy otrzymałeś nakaz zapłaty zachęcamy do kontaktu. Nasi doświadczeni
                prawnicy prawników i radcowie prawni gwarantują profesjonalne zajęcie się sprawą.
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
