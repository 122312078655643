import React, { useEffect} from "react";
import styled from 'styled-components';
// import PenAndPaper from '../assets/1.png';
import cash from '../assets/cash.png';
import paper from '../assets/paper.png';
import art1 from '../assets/blog/art1final.jpg';
import art2 from '../assets/blog/art2.jpg';

import art3 from '../assets/blog/art3.jpg';
import art4 from '../assets/blog/art4.jpg';
import art5 from '../assets/blog/art5.jpg';
import art8 from '../assets/blog/art8.jpg';
import art55 from '../assets/blog/art55.jpg';
// import art10 from '../assets/blog/art10.jpg';
// import art11 from '../assets/blog/art11.jpg';
// import art12 from '../assets/blog/art12.jpg';
// import art13 from '../assets/blog/art13.jpg';
// import art14 from '../assets/blog/art14.jpg';
// import art16 from '../assets/blog/art16.jpg';
// import art18 from '../assets/blog/art18.jpg';
// import art21 from '../assets/blog/art21.jpg';
// import art22 from '../assets/blog/art22.jpg';
import pom2 from '../assets/pom2.png';
import { MdPhonelinkRing } from 'react-icons/md';
import { MdEmail } from 'react-icons/md';
import { MdAccessTime } from 'react-icons/md';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import FreeAdviceButton from '../components/FreeAdviceButton';
import { Link } from 'react-router-dom';
import { basicRoute } from '../routes/routes';

const StyledH1 = styled.h1`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  align-self: center;
  text-align: center;
  margin-top:5%
`;

const StyledH2 = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.l};
  letter-spacing: 2px;
  text-shadow: 2px 2px lightgrey;
  word-break: break-word;
  margin-inline: auto;
`;

const StyledH3 = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.l};
  letter-spacing: 2px;
  text-shadow: 2px 2px lightgrey;
  word-break: break-word;
  margin-inline: auto;
`;
const Styledh6 = styled.h6`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.l};
  word-break: break-all;
`;
// const StyledImg = styled.img`
//   width: 100%;
// `;
// const ImgWrapper = styled.div`
//   max-width: 800px;
//   margin-inline: auto;
// `;
const StyledP = styled.p`
  font-size: ${({ theme }) => theme.fontSize.m};
  text-align: justify;
`;

const StyledUL = styled.ul`
  margin-left:10%;
  margin-top:3%;
  margin-right:10%;
  margin-bottom:3%;
`;

const TextWrapper = styled.article`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 750px;
  margin-inline: auto;
`;

const ReportWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 780px;
  align-items: center;
  margin-inline: auto;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  padding: 2rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.darkBlue};
  border-radius: ${({ theme }) => theme.border.radius};
  min-width: 280px;
  max-width: 350px;
`;
const BlockWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-inline: auto;
`;
const ContactWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
  gap: 1rem;
  margin-inline: auto;
  @media (max-width: 790px) {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const ContantImgWrapper = styled.div`
  min-width: 280px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
const ImgInCarouselWrapper = styled.div`
  width: 100%;
  /* max-width: 800px; */
`;
const CarouselWrapper = styled.div`
  @media (max-width: 440px) {
    max-width: 300px;
  }
  max-width: 700px;
  margin: 0 auto;
`;
const Home = () => {
  useEffect(() => {
     document.title = "Antywindykacja, pomoc w umorzeniu długu i procesie sądowym - darmowa porada prawna - Sprzeciw Długom";  
  }, []);
  
  return (
    <>
      <StyledH1>Kancelaria antywindykacyjna - darmowa pomoc<br /> prawna w umarzaniu długu</StyledH1>
      <StyledH3>Najpopularniejsze artykuły</StyledH3>
      <CarouselWrapper>
        <Carousel>
          <ImgInCarouselWrapper>
            <img src={art1} alt="Jak zrozumieć nakaz zapłaty i pozew." />
            <Link to={basicRoute.blog1}>
              <p
                className="legend"
                style={{ backgroundColor: 'black', color: 'white', opacity: '1', fontSize: '20px' }}
              >
                Jak zrozumieć nakaz zapłaty i pozew.
              </p>
            </Link>
          </ImgInCarouselWrapper>
          <ImgInCarouselWrapper>
            <img src={art2} alt="Przywrócenie terminu jak odzczytać pisamo od komornika." />
            <Link to={basicRoute.blog2}>
              <p
                className="legend"
                style={{ backgroundColor: 'black', color: 'white', opacity: '1', fontSize: '20px' }}
              >
                Przywrócenie terminu jak odzczytać pisamo od komornika.
              </p>
            </Link>
          </ImgInCarouselWrapper>
          <ImgInCarouselWrapper>
            <img src={art3} alt="Jak złożyć sprzeciw od nakazu zapłaty ?" />
            <Link to={basicRoute.blog3}>
              <p
                className="legend"
                style={{ backgroundColor: 'black', color: 'white', opacity: '1', fontSize: '20px' }}
              >
                Jak złożyć sprzeciw od nakazu zapłaty ?
              </p>
            </Link>
          </ImgInCarouselWrapper>
          <ImgInCarouselWrapper>
            <img src={art4} alt="Jak liczyć przedawnienie ?" />
            <Link to={basicRoute.blog4}>
              <p
                className="legend"
                style={{ backgroundColor: 'black', color: 'white', opacity: '1', fontSize: '20px' }}
              >
                Jak liczyć przedawnienie ?
              </p>
            </Link>
          </ImgInCarouselWrapper>
          <ImgInCarouselWrapper>
            <img src={art5} alt="E-sąd" />
            <Link to={basicRoute.blog5}>
              <p
                className="legend"
                style={{ backgroundColor: 'black', color: 'white', opacity: '1', fontSize: '20px' }}
              >
                E-sąd
              </p>
            </Link>
          </ImgInCarouselWrapper>
          <ImgInCarouselWrapper>
            <img src={art55} alt="E-sąd" />
            <Link to={basicRoute.blog55}>
              <p
                className="legend"
                style={{ backgroundColor: 'black', color: 'white', opacity: '1', fontSize: '20px' }}
              >
                Jak założyć konto na e-sądzie ?
              </p>
            </Link>
          </ImgInCarouselWrapper>
          <ImgInCarouselWrapper>
            <img src={art8} alt="E-sąd" />
            <Link to={basicRoute.blog8}>
              <p
                className="legend"
                style={{ backgroundColor: 'black', color: 'white', opacity: '1', fontSize: '20px' }}
              >
                Jak sprawdzić czy mam zadłużenia ?
              </p>
            </Link>
          </ImgInCarouselWrapper>
        </Carousel>
      </CarouselWrapper>

      {/* <ImgWrapper>
        <StyledImg src={PenAndPaper} alt="kartka papieru i dlugopis" />
      </ImgWrapper> */}
      <TextWrapper>
        <StyledP>
          Tematy związane z postępowaniem sądownym w sprawie zadłużenia bywają bardzo zawiłe. Parabanki i inne instytucje nierzadko wykorzystują niewiedzę konsumentów. sPrzeciw Długom to kancelaria radcy prawnego i aplikantów radcowskich. W naszym polu zainteresowań znajduje się szeroko rozumiana antywindykacja.
          <br />
          <br />
          Podstawą pomocy osobom zadłużonym jest darmowa porada prawna. Nasz zespół radców, aplikantów i prawników ocenia szanse na całkowite umorzenie długu lub jego zmniejszenie i proponuje dla Państwa rozwiązanie. Zawsze staramy się przedstawić je w sposób zrozumiały, aby Klient był pewny swojej sytuacji.
        </StyledP>
      </TextWrapper>
      <ReportWrapper>
        <StyledP>
          <b>Zgłoś się do nas! </b>
        </StyledP>
        <FreeAdviceButton text="Darmowa analiza" />
      </ReportWrapper>
      <StyledH2>Czym zajmuje się portal sPrzeciw Długom?</StyledH2>
      <BlockWrapper>
        <Block>
          <img src={cash} alt="Umorzenie dlugow" style={{ width: '50%' }} />
          <Styledh6>Umorzenie długów</Styledh6>
          <p style={{ color: 'white' }}>
            Z naszą pomocą wygrasz sprawę w sądzie i pozbędziesz się zadłużenia!
          </p>
        </Block>
        <Block>
          <img src={paper} alt="Antywindykacja" style={{ width: '40%' }} />
          <Styledh6>Antywindykacja</Styledh6>
          <p style={{ color: 'white' }}>
            Zajmiemy się twoimi sprawami i pozbędziemy się windykacji z Twojego życia!
          </p>
        </Block>
        <Block>
          <img src={pom2} alt="Komornik" style={{ width: '80%' }} />
          <Styledh6>Wygraj z komornikiem</Styledh6>
          <p style={{ color: 'white' }}>
            Pomożemy Ci uniknąć zajęcia konta bankowego i licytacji twojego majątku!
          </p>
        </Block>
      </BlockWrapper>
      <StyledP>
        Kancelaria radców prawnych udziela porad oraz reprezentuje Klientów w sprawach związanych z antywindykacją. To działania mające na celu pomoc osobie zadłużonej. Pierwsza konsultacja ma charakter darmowej analizy Państwa sytuacji. Nasz zakres wsparcia to m.in.:
        <br />
        <StyledUL>
          <li>aktywna pomoc w procesach sądowych z firmami windykacyjnymi, funduszami inwestycyjnymi, parabankami i bankami,</li>
          <li>negocjacje warunków ugody,</li>
          <li>prowadzenie postępowań o zwrot należności zajętych przez komorników w niecelowych egzekucjach,</li>
          <li>doradztwo w zakresie umorzenia długu, wykreślenia z baz dłużników,</li>
          <li>pomoc w napisaniu pism sądowych.</li>
        </StyledUL>
        <br />
         Kancelaria sPrzeciw Długom udziela świadczeń głównie za pośrednictwem internetu. Nasi radcy są dostępni pod numerem telefonu +48 881 397 639 w dni robocze w godzinach 8:00-19:00. Wstępna analiza sprawy odbywa się w formie darmowej porady prawnej.
       </StyledP>

       <StyledH2>Dostałem pismo... Co dalej?</StyledH2>
       <StyledP>
         Otrzymanie nakazu zapłaty czy innego pozwu nie jest tożsamy z koniecznością uiszczenia całości długu lub rozpoczęciem zajęcia komorniczego. W większości przypadków możliwe są negocjacje z wierzycielami, a w efekcie częściowe bądź całkowite umorzenie zadłużenia.
         <br />
         <br />
         Kancelaria sPrzeciw Długom reprezentuje Klientów na drodze sądowej. Podczas darmowej porady staramy się nakreślić sytuację oraz zaplanować dalsze kroki. Prowadzimy antywindykację, dzięki której możliwe jest doprowadzenie do ugody i umorzenie długu.
       </StyledP>
          
      

      <StyledH3>Skontaktuj się</StyledH3>
      <ContactWrapper>
        <ContantImgWrapper>
          <MdPhonelinkRing style={{ width: '60px', height: '60px', color: 'orange' }} />
          <a 
            href="tel:+48881397639"
            target="_blank"
          >
          <p>+48 881 397 639</p>
          </a>
        </ContantImgWrapper>
        <ContantImgWrapper>
          <MdEmail style={{ width: '60px', height: '60px', color: 'orange' }} />
          <a 
            href="mailto:kontakt@sprzeciwdlugom.pl"
            target="_blank"
          >
          <p>kontakt@sprzeciwdlugom.pl</p>
          </a>
        </ContantImgWrapper>
        <ContantImgWrapper>
          <MdAccessTime style={{ width: '60px', height: '60px', color: 'orange' }} />
          <p> Pn - Pt od 8:00 do 19:00 </p>
        </ContantImgWrapper>
      </ContactWrapper>

      {/* <StyledH3>Najnowsze artykuły </StyledH3> */}
      {/* <CarouselWrapper>
        <Carousel>
          <ImgInCarouselWrapper>
            <img src={art1} alt="Jak zrozumieć nakaz zapłaty i pozew." />
            <Link to={basicRoute.blog1}>
              <p className="legend">Jak zrozumieć nakaz zapłaty i pozew.</p>
            </Link>
          </ImgInCarouselWrapper>
          <ImgInCarouselWrapper>
            <img src={art1} alt="Przywrócenie terminu jak odzczytać pisamo od komornika." />
            <Link to={basicRoute.blog2}>
              <p className="legend">Przywrócenie terminu jak odzczytać pisamo od komornika.</p>
            </Link>
          </ImgInCarouselWrapper>
          <ImgInCarouselWrapper>
            <img src={art3} alt="Jak złożyć sprzeciw od nakazu zapłaty ?" />
            <Link to={basicRoute.blog3}>
              <p className="legend">Jak złożyć sprzeciw od nakazu zapłaty ?</p>
            </Link>
          </ImgInCarouselWrapper>
          <ImgInCarouselWrapper>
            <img src={art4} alt="Jak liczyć przedawnienie ?" />
            <Link to={basicRoute.blog4}>
              <p className="legend">Jak liczyć przedawnienie ?</p>
            </Link>
          </ImgInCarouselWrapper>
          <ImgInCarouselWrapper>
            <img src={art5} alt="E-sąd" />
            <Link to={basicRoute.blog5}>
              <p className="legend">E-sąd</p>
            </Link>
          </ImgInCarouselWrapper>
        </Carousel>
      </CarouselWrapper> */}
    </>
  );
};

export default Home;
