import styled from 'styled-components';
import Image from '../../assets/blog/art1final.jpg';
import Image11 from '../../assets/blog/art1.1.png';
import Image12 from '../../assets/blog/art1.2.jpg';
import { useEffect } from 'react';
import { basicRoute } from '../../routes/routes';
import { Akapit } from '../../components/Akapit/Akapit';
import { Header } from '../../components/BlogTextHeder';
import { useLocation } from 'react-router';
const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;

const ImgInsideWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const Details = {
  head: 'Jak zrozumieć nakaz zapłaty i pozew?',
  bigImage: Image,
  text: '',
  tableOfContents: [
    {
      id: '1',
      text: 'Co zawiera nakaz zapłaty i jakie są jego najważniejsze elementy.',
      longText: `
    
      `,
    },
    {
      id: '2',
      text: ' Jakie istotne informacje znajdują się w pozwie. ',
    },
    {
      id: '3',
      text: ' Podsumowanie.',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`; 
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit /> Nakaz zapłaty, który otrzymałeś jest <strong>tytułem egzekucyjnym</strong>{' '}
          wydanym przez sąd, w związku ze złożeniem pozwu przez Twojego aktualnego wierzyciela.
          (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog10} style={{ color: 'orange' }}>
            Cesja wierzytelności. O powiązaniach między pożyczkodawcami i firmami windykacyjnymi
          </a>
          ). Otrzymanie nakazu zapłaty oznacza, że jeśli nie podejmiesz właściwych działań, będziesz
          musiał zapłacić określoną w nim kwotę na rzecz powoda. W przeciwnym razie wierzyciel
          będzie mógł po uzyskaniu klauzuli wykonalności skierować wniosek do komornika sądowego o
          wszczęcie egzekucji. Może ona prowadzić do zajęcia Twojej wypłaty/emerytury, oszczędności
          znajdujących się na rachunku bankowym, nieruchomości oraz innego majątku jaki posiadasz. (
          czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog21} style={{ color: 'orange' }}>
            tricki i nieczyste zagrywki firm windykacyjnych
          </a>
          ). W tym miejscu warto zaznaczyć, że inne działania należy podjąć, kiedy nakaz zapłaty
          zostanie Ci po raz pierwszy doręczony przez komornika wraz z pismem informującym o
          wszczęciu egzekucji, (z reguły ma to miejsce wtedy gdy wierzyciel w pozwie wskazał Twoje
          nieaktualne dane adresowe (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog2} style={{ color: 'orange' }}>
            przywrócenie terminu jak odzczytać pisamo od komornika
          </a>
          ) inne gdy nakaz zapłaty zostanie doręczony przez sąd (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog3} style={{ color: 'orange' }}>
            Jak złożyć sprzeciw od nakazu zapłaty?
          </a>
          ), a jeszcze inne kiedy firm windykacyjne jedynie nim straszy w rozmowach telefonicznych
          lub pismach (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog22} style={{ color: 'orange' }}>
            jak działa komornik?
          </a>
          ).
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>Spis treści :</strong>
        </p>
        <ol style={{ maringLeft: '20px' }}>
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <stgrong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </stgrong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper id="1">
        <ul style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li>
              <Header> Co zawiera nakaz zapłaty i jakie są jego najważniejsze elementy</Header>
              <StyledP>
                <Akapit />
                Nakaz zapłaty, jako pismo sądowe, które musi spełniać określone wymogi formalne,
                charakteryzuje się standardową dla tego typu pism strukturą redakcyjną i podzielone
                jest na kilka elementów. Nakaz zapłaty powinieneś/powinnaś czytać „od góry”, tj.
                przede wszystkim ustalić należy,
                <strong> jaki sąd go wydał, kiedy i w jakim trybie</strong>, tzn. czy jest to nakaz
                zapłaty w postępowaniu upominawczym, czy w postępowaniu nakazowym (w obu
                przypadkach, procedura odwołania będzie wyglądać nieco inaczej). <br />
                <Akapit />
                Następną ważną informacją jest to, kto Cię pozwał{' '}
                <strong>(kto jest powodem)</strong>, a więc kto domaga się od Ciebie zapłaty.
                Kolejnym kluczowym elementem jest kwota jaką będziesz musiał zapłacić wierzycielowi,
                jeśli odpowiednio nie zareagujesz. Ta składa się z trzech elementów. Pierwszą z nich
                jest tzw. „Wartość Przedmiotu Sporu” (WPS), czyli kwota, której dochodzi firma
                windykacyjna/pożyczkodawca, drugą naliczane od tej kwoty odsetki, a trzecią,
                obliczona na podstawie WPS kwota kosztów postępowania sądowego. Ostatnia pozycja to
                koszty powstałe w wyniku postępowania sądowego. Co do zasady ponosi je strona, która
                przegrywa postępowanie, co oznacza, że w przypadku braku złożenia sprzeciwu zwiększą
                one Twoje zadłużenie. Krótko mówiąc, im kwota WPS będzie wyższa, tym wyższe będą
                również koszty postępowania sądowego i odsetki. Kwota ta będzie się również różnić,
                jeśli nakaz zapłaty został wydany w elektronicznym postępowaniu upominawczym przez
                Sąd Rejonowy Lublin- Zachód w Lublinie (tzw. Sąd Elektroniczny lub{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog5}
                  style={{ color: 'orange' }}
                >
                  Elektroniczne postępowanie upominawcze, czyli słów parę o tzw. e-sądzie
                </a>
                ).
                <br />
                <ImgInsideWrapper>
                  <StyledImage src={Image11} alt="text" />
                </ImgInsideWrapper>
                <ImgInsideWrapper>
                  <StyledImage src={Image12} alt="text" />
                </ImgInsideWrapper>
                <Akapit /> Ostatnim, często ignorowanym przez osoby otrzymujące nakazy zapłaty
                elementem pisma są pouczenia. Pomijanie pouczeń to wielki błąd, ale jest nim również
                interpretowanie ich na własną rękę w sytuacji, w której osoba pozwana nie ma
                doświadczenia w „poruszaniu się” w gąszczu przepisów prawa. Rolą tej części nakazu
                zapłaty, jest spełnienie ustawowego obowiązku informacyjnego osób pozwanych. W
                pouczeniu sąd wskazuje w jaki sposób należy postrzegać otrzymane pismo i co można
                zrobić, aby bronić się przed jego konsekwencjami. Niestety, obowiązek ten mimo
                znacznej obszerności treści pouczeń, jest spełniony tylko częściowo, bowiem bardzo
                często pouczenia są napisane formalnym i skomplikowanym językiem. Takie opisanie
                praw i obowiązku osoby pozwanej nie ułatwia osobie zadłużonej zrozumienia co i w
                jaki sposób zrobić, aby podjąć odpowiednie działania i nie pogorszyć swojej
                sytuacji. W celu uniknięcia kłopotów z właściwą interpretacją opisywanych tam treści
                i odpowiednią reakcją na otrzymany nakaz zapłaty najlepiej, skontaktować się z
                osobami profesjonalnie zajmującymi się walką z{' '}
                <strong>firmami windykacyjnymi</strong> (takimi jak np. Ultimo, Kruk, Best, Hoist,
                Kredyt Inkaso, ProfiCredit, Alektum, Eques Debitum, GetBack),
                <strong> bankami, oferującymi tzw. chwilówki parabankami </strong> (takimi jak np.
                LoanMe, SuperGrosz, Provident, Vivus, Rapida, Finbo, SzybkGotówka) i {''}
                <strong>funduszami sekurytyzacyjnymi</strong> (takimi jak np. Omega,
                Centauris,Horyzont).
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }} id="2">
            <li>
              <Header> Jakie istotne informacje znajdują się w pozwie</Header>

              <StyledP>
                <Akapit />
                Pozew, który jest doręczony wraz z nakazem zapłaty, zawiera opis dochodzonego od
                Ciebie roszczenia tzn. powinien tłumaczyć jak powstał dochodzony przez powoda dług.
                Powinny być do niego dołączone dowody, które mają przekonać sąd do twierdzeń
                instytucji, która Cię pozwała. Długość i charakter pozwów jest bardzo zróżnicowany i
                zależy od takich czynników jak wspomniany już WPS, zawiłość sprawy, ale też od tego,
                która z firm windykacyjnych, funduszy, banków, bądź parabanków go składa. To, jak
                wygląda pozew i to jaką wartość sobą prezentuje, determinowane jest często{' '}
                <strong>masowością działań instytucji</strong>, które je składają. Konsekwencją
                ogromnej skali działalności firm windykacyjnych, funduszy, banków i parabanków jest
                to, że popełniają one błędy albo wysyłają do sądu pozwy, które oprócz krótkich
                twierdzeń i ograniczonego materiału dowodowego nie zawierają niczego wartościowego.
                Treść i jakość pozwu są zatem kluczowe w kontekście obrania strategii procesowej,
                która zapewni skuteczne złożenie sprzeciwu i wygranie Twojej sprawy w całości{' '}
                <strong>(anulowania długu)</strong>, bądź zmniejszenie kwoty, którą będzie trzeba
                zapłacić wierzycielowi. <br />
                <Akapit />
                Ludzie, którzy otrzymują pozew, często czytając go, nie rozumieją jego treści ze
                względu na stosowany w nim skomplikowany język prawniczy. Jeśli podobnie jest w
                Twoim przypadku i nie potrafisz ocenić na pierwszy rzut oka np. czy nastąpiło{' '}
                {/* <strong> */}
                przedawnienie długu, (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog4}
                  style={{ color: 'orange' }}
                >
                  jak liczyć przedawnienie?
                </a>
                ){/* </strong> */} nie przejmuj się tym. Nie zajmujesz się prawem na co dzień, nie
                przywykłeś więc do języka używanego w takich pismach. Właściwa analiza pozwu i
                dostrzeżenie jego słabych stron to zadanie dla doświadczonego w{' '}
                <strong>walce z długami</strong> profesjonalisty. Istotne jest również, aby osoba
                która zajmie się Twoją sprawą <strong>posiadała doświadczenie</strong> w walce z
                firmami windykacyjnymi, funduszami sekurytyzacyjnymi, bankami i parabankami, gdyż
                tylko to pozwala na wyciągnięcie właściwych i kompletnych wniosków.
                <br />
                <Akapit /> Kolejną bardzo ważną rzeczą dotyczącą pozwów jest to, że nawet kiedy
                otrzymujesz pozew, który już na pierwszy rzut oka jest pismem o bardzo niskiej
                jakości, bądź zawiera informacje, które uznajesz za niezgodne z prawdą, należy{' '}
                <strong>błyskawicznie reagować</strong>. Niestety, w sprawach dotyczących zadłużeń
                prawda nie obroni się sama i konieczne jest złożenie trafnej{' '}
                <strong>odpowiedzi na pozew</strong>. Sytuacja taka wynika z{' '}
                <strong>kontradyktoryjności polskiego postępowania cywilnego</strong>, co w praktyce
                oznacza, że jeśli zaniedbasz złożenia <strong>pisma do sądu</strong>, ten potraktuje
                twoje „milczenie” jako wyrażenie zgody na żądanie wierzyciela.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }} id="3">
            <li>
              <Header> Podsumowanie</Header>
              <StyledP>
                <Akapit />
                Nie panikuj i nie daj się zastraszyć. Pamiętaj, że{' '}
                <strong>pismo o zapłatę z sądu</strong> to nie koniec świata, a firma windykacyjna,
                fundusz, bank, czy parabank nie są nieomylne. Są to instytucje, które nastawione są
                na zysk, więc każde ich działanie podyktowane jest uzyskaniem od Ciebie jak
                największej kwoty pieniędzy w jak najkrótszym czasie. Jesteś w stanie skutecznie się
                bronić, doprowadzić do przedawnienia długu, anulować odsetki i{' '}
                <strong>komornika</strong>, potrzebujesz do tego jedynie kogoś{' '}
                <strong>kto wie jak z nimi</strong> walczyć. Skontaktuj się z nami za pomocą
                formularza, napisz do nas e-mail lub zadzwoń. Przeprowadzimy{' '}
                <strong>darmową analizę </strong> Twojej sprawy i dobierzemy najlepszą strategię,
                aby ją wygrać!
              </StyledP>
            </li>
          </article>
        </ul>
      </MainTextWrapper>
    </>
  );
};

export default First;
