import { actionTypes } from '../../constants/actionTypes';

const INITIAL_STATE = {
  login: false,
};

const FreeAdviceReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.SIGNIN:
      return {
        login: payload,
      };
    default:
      return state;
  }
};

export default FreeAdviceReducer;
