import axios from 'axios';
import { actionTypes } from '../constants/actionTypes';
import { BASE_URL } from '../constants/api';
export const me = () => async dispatch => {
  try {
    const { data } = await axios.get(`${BASE_URL}/user/me`, { withCredentials: true });

    dispatch({
      type: actionTypes.ME,
      payload: data,
    });
    return data;
  } catch (error) {}
};
