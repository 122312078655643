import { createPortal } from 'react-dom';
import { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import { useDispatch } from 'react-redux';
import { changeVisibilityCookie } from '../../actions/cookie';

const MegaWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 999999;
  background: rgba(15, 15, 15, 0.5);
  display: grid;
  place-items: center;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 600px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.darkBlue};
  border-radius: ${({ theme }) => theme.border.radius};
  gap: 2rem;
  padding: 1rem;
`;

const CoockieChooser = () => {
  const [coockieGeneral, setCoockieGeneral] = useState(true);
  const [coockieStatistic, setCoockieStatistic] = useState(false);
  const [coockieMarketing, setCoockieMarketing] = useState(false);
  const dispatch = useDispatch();
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const handleClick = () => {
    if (coockieGeneral && coockieStatistic && coockieMarketing) {
      document.cookie = `CookieConsent=true;max-age=604800;`;
      document.cookie = `CookieOnlyWorkNeeded=true;max-age=604800;`;
      document.cookie = `CookieStatistic=true;max-age=604800;`;
      document.cookie = `CookieMarketing=true;max-age=604800;`;
    }
    if (coockieGeneral) {
      document.cookie = `CookieOnlyWorkNeeded=true;max-age=604800;`;
      document.cookie = `CookieStatistic=false;max-age=604800;`;
      document.cookie = `CookieMarketing=false;max-age=604800;`;
      document.cookie = `CookieConsent=true;max-age=604800;`;
      document.cookie = `_ga=null;max-age=604800;`;
      document.cookie = `_ga_VHX8ZC8GXK=null;max-age=604800;`;
    }
    dispatch(changeVisibilityCookie());

    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  const rows = [
    createData(
      ' Cookies własne',
      'Cookies niezbędne służą do prawidłowego działania Strony Internetowej, zapewnienia jej funkcjonalności i bezpieczeństwa. Niestety, nie możesz wyłączyć tych ciasteczek ponieważ po ich wyłączeniu nasza Strona nie będzie poprawnie działać.',
    ),
    createData(
      'Cookies analityczne i statystyczne',
      'Cookies potrzebne do zbierania informacji o sposobie korzystania z naszej Strony Internetowej, w celu optymalizacji działania Strony, prowadzenia statystyk. Dzięki tym cookies możemy dowiedzieć się w jaki sposób Klienci i Osoby Odwiedzające zachowują się na naszej Stronie. Jeśli nie wyrazisz zgody na wykorzystywanie tych cookies, nie będziemy w stanie monitorować działania Strony. Bez tych plików niektóre funkcje mogą stać się niedostępne.',
    ),
    createData(
      'Cookies  marketingowe ',
      'Cookies reklamowe pozwalają dostarczyć Klientowi i Osobie odwiedzającej treści reklamowe dostosowane do ich preferencji i zainteresowań, czy sposobu zachowania na naszej Stronie Internetowej. Korzystamy z działań remarketingowych z użyciem plików cookies stron trzecich. Cookies pozwalają tworzyć profile Osób Odwiedzających i Klientów, wyświetlania naszych reklam w innych miejscach w Internecie, a także mierzenia efektywności kampanii reklamowych. Pliki mogą zawierać informacje, że nasza Strona została otwarta na danym urządzeniu, w danej lokalizacji. Część informacji możemy przekazywać naszym zaufanym partnerom. Nie wyrażenie zgody na ten rodzaj przetwarzania danych nie wpłynie w żaden sposób na funkcjonowanie Strony.',
    ),
  ];

  return createPortal(
    <MegaWrapper>
      <Wrapper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 300, maxWidth: 900 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Usługa</TableCell>
                <TableCell align="center">Cel użycia</TableCell>
                <TableCell align="right">Włącz</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={rows[0].name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {rows[0].name}
                </TableCell>
                <TableCell align="left">{rows[0].calories}</TableCell>
                <TableCell align="center">
                  <Switch
                    // onChange={() => setCoockieGeneral(!coockieGeneral)}
                    checked={coockieGeneral}
                  />
                </TableCell>
              </TableRow>

              <TableRow
                key={rows[1].name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {rows[1].name}
                </TableCell>
                <TableCell align="left">{rows[1].calories}</TableCell>
                <TableCell align="center">
                  <Switch
                    onChange={() => setCoockieStatistic(!coockieStatistic)}
                    checked={coockieStatistic}
                  />
                </TableCell>
              </TableRow>

              <TableRow
                key={rows[2].name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {rows[2].name}
                </TableCell>
                <TableCell align="left">{rows[2].calories}</TableCell>
                <TableCell align="center">
                  <Switch
                    onChange={() => setCoockieMarketing(!coockieMarketing)}
                    checked={coockieMarketing}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <button
          style={{
            backgroundColor: '#FF7924',
            color: 'white',
            fontSize: '24px',
            border: '2px solid #38587E',
            width: '120px',
            height: '50px',
          }}
          onClick={() => handleClick()}
        >
          zapisz
        </button>
      </Wrapper>
    </MegaWrapper>,
    document.getElementById('portal'),
  );
};
export default CoockieChooser;
