import styled from 'styled-components';
import Image from '../../assets/blog/art55.jpg';
import Image1 from '../../assets/blog/art5/1.jpg';
import Image2 from '../../assets/blog/art5/2.jpg';
import Image3a from '../../assets/blog/art5/3a.jpg';
import Image3b from '../../assets/blog/art5/3b.jpg';

import Image4 from '../../assets/blog/art5/4.jpg';
import Image5 from '../../assets/blog/art5/5.jpg';
import Image6 from '../../assets/blog/art5/6.jpg';
import Image7 from '../../assets/blog/art5/7.jpg';
import Image8 from '../../assets/blog/art5/8.jpg';
import { useLocation } from 'react-router';

import { basicRoute } from '../../routes/routes';
import { Header } from '../../components/BlogTextHeder';
import { useEffect } from 'react';
import { Akapit } from '../../components/Akapit/Akapit';

const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Details = {
  head: ' Jak założyć konto na e-sądzie ?',
  bigImage: Image,
  text: ' ',
  tableOfContents: [
    {
      id: '1',
      text: 'Kiedy warto założyć konta na portalu e-sądu?',
    },
    {
      id: '2',
      text: ' Jak założyć konto w serwisie e-sad.gov.pl ',
    },
    {
      id: '3',
      text: ' Podsumowanie',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;
const ImgInsideWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`;
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit /> Jedynie w 2020 roku do Sądu Rejonowego Lublin-Zachód w Lublinie w ramach
          elektronicznego postępowania upominawczego wpłynęło blisko 2 miliony spraw, które
          przełożyły się na wydanie ponad 1,5 mln nakazów zapłaty. Można więc bez żadnych
          wątpliwości stwierdzić, że tzw. e-sąd obsługuje najwięcej spraw ze wszystkich polskich
          sądów. Duża część tych spraw jest związana z działalnością szeroko pojętej windykacji tzn.
          działań firmy windykacjnych i parabanków np. Prokury, LoanMe, Eques Debitum, Alektum,
          SuperGrosz, Vivus czy Horyzonta. Jeśli nie wiesz czym jest ta instytucja zachęcamy do
          zapoznania się z naszym artykułem (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog5} style={{ color: 'orange' }}>
            Elektroniczne postępowanie upominawcze, czyli słów parę o tzw. e-sądzie
          </a>
          ) , w którym dokładnie ją opisujemy.{' '}
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>Spis treści :</strong>
        </p>
        <ol style={{ maringLeft: '20px' }}>
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <strong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </strong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper>
        <ol style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li id="1">
              <Header> Kiedy warto założyć konto na portalu e-sądu?</Header>

              <StyledP>
                <Akapit /> Zawsze, jeśli mamy informację o tym, że toczy się lub toczyła się w nim
                przeciwko nam sprawa. Założenie takiego konta jest w pełni{' '}
                <strong>darmowe i zajmuje maksymalnie 10 minut, </strong>
                jednocześnie umożliwiając dostęp do elektronicznych akt sprawy za pośrednictwem
                internetu. Można się z nich dowiedzieć nie tylko, kto jest powodem i jakiej kwoty
                żąda, ale sprawdzić również z czego wynika zadłużenie oraz na jakim etapie jest
                postępowanie sądowe.
                <br />
                <br />
                <Akapit />
                Konto na portalu e-sądu warto założyć również w przypadku powrotu{' '}
                <strong>po dłuższym czasie z zagranicy</strong>{' '}
                <strong>lub w przypadku prób kontaktu ze strony firm windykacyjnych</strong> np.
                Kruka, Prokury, Ultimo lub Besta. Bardzo często bowiem wyżej wymienione firmy
                kierują powództwa przeciwko swoim dłużnikom do tego właśnie sądu czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog1}
                  style={{ color: 'orange' }}
                >
                  Jak zrozumieć nakaz zapłaty i pozew?
                </a>
                ). Jest to dla nich bowiem tańsze i prostsze od postępowań przed zwykłymi „sądami”.
                Założenie takiego konta może okazać się szczególnie pomocne w przypadku osób, które
                mieszkały przez dłuższy czas poza granicami kraju. W ich bowiem przypadku listy
                wysłane z sądu były kierowane zapewne na nieaktualne dane adresowe i choć
                pozostawały nieodebrane mogły wywołać tzw. zastępczy skutek doręczenia (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog2}
                  style={{ color: 'orange' }}
                >
                  Przywrócenie terminu. Jak odzczytać pisamo od komornika?
                </a>
                ) co z kolei sprawiło, że nakaz zapłaty się uprawomocnił i może być podstawą{' '}
                <strong>egzekucji sądowej prowadzonej przez komornika</strong> (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog22}
                  style={{ color: 'orange' }}
                >
                  jak działa komornik?
                </a>
                ). Co za tym idzie po podjęciu przez taką osobę pracy w Polsce pierwszą wypłatę może
                niespodziewanie <strong>zająć komornik</strong>. Również przy założeniu konta
                bankowego, może okazać się, że zostanie ono <strong>zajęte przez komornika</strong>{' '}
                sądowego. Warto więc zawczasu założyć konto na portalu ww. sądu i sprawdzić, czy nie
                został wydany nakaz zapłaty. Powinno to pozwolić na uniknięcie przykrych
                niespodzianek.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="2">
              <Header> Jak założyć konto w serwisie e-sad.gov.pl</Header>

              <StyledP>
                <Akapit /> Pierwszym krokiem do założenie konta na portalu Sądu Rejonowego
                Lublin-Zachód w Lublinie, VI Wydział Cywilny, jest otwarcie w swojej przeglądarce
                strony internetowej{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.e-sad.gov.pl/"
                  style={{ color: 'orange' }}
                >
                  e-sad.gov.pl (https://www.e-sad.gov.pl/)
                </a>{' '}
                . Następnie należy kliknąć lewym klawiszem myszki w zakładkę{' '}
                <span style={{ color: '#FF7924' }}>Zarejestruj konto.</span>
              </StyledP>
              {/* <HeadingWrapper>
                <h1>(wzór pierwszej strony formularza sprzeciwu od nakazu zapłaty)</h1>
              </HeadingWrapper> */}

              <ImgInsideWrapper>
                <StyledImage src={Image1} alt="text" />
              </ImgInsideWrapper>
              <StyledP>
                Po otworzeniu się nowej podstrony należy zaznaczyć{' '}
                <span style={{ color: '#FF7924' }}>Konto podstawowe</span> i kliknąć przycisk dalej.
              </StyledP>
              <ImgInsideWrapper>
                <StyledImage src={Image2} alt="text" />
              </ImgInsideWrapper>
              <StyledP>
                Kolejny etapem jest wypełnienie formularza danymi osoby, dla której zakładamy konto.
                Warto zapisać wprowadzone w odpowiednim okienku hasło. Będzie ono niezbędne do
                dalszego korzystania z konta. Po wprowadzeniu niezbędnych danych należy kliknąć
                przycisk <span style={{ color: '#FF7924' }}>Prześlij wniosek. </span>
              </StyledP>
              <ImgInsideWrapper>
                <StyledImage src={Image3a} alt="text" />
              </ImgInsideWrapper>
              <ImgInsideWrapper>
                <StyledImage src={Image3b} alt="text" />
              </ImgInsideWrapper>
              <StyledP>
                W przypadku poprawnego wypełnienia formularzu, na ekranie zostanie wyświetlona
                informacja o przesłaniu wniosku o założenie konta. Oznacza to, że wprowadzone dane
                będą teraz weryfikowane przez system oraz pracowników sądu. W międzyczasie, z reguły
                w ciągu 15 minut, na podany w trakcie rejestracji adres mailowy zostanie wysłany
                link potwierdzający jego poprawność, w który należy kliknąć.
              </StyledP>
              <ImgInsideWrapper>
                <StyledImage src={Image4} alt="text" />
              </ImgInsideWrapper>
              <ImgInsideWrapper>
                <StyledImage src={Image5} alt="text" />
              </ImgInsideWrapper>
              <StyledP>
                Z reguły wniosek o założenie konta rozpatrywany jest w ciągu 24 godzin. Po jego
                pozytywnym rozpatrzeniu na podany w procesie rejestracji adres mailowy zostanie
                przesłany link aktywacyjny oraz login. Po kliknięciu w link konto zostanie
                aktywowane i będzie się można na nie zalogować po wprowadzeniu loginu i hasła na
                stronie:{' '}
                <a
                  href="http://www.e-sad.gov.pl"
                  target="_blank"
                  rel="noreferrer"
                  syle={{ color: '#FF7924' }}
                >
                  e-sad.gov.pl
                </a>
              </StyledP>
              <ImgInsideWrapper>
                <StyledImage src={Image6} alt="text" />
              </ImgInsideWrapper>
              <ImgInsideWrapper>
                <StyledImage src={Image7} alt="text" />
              </ImgInsideWrapper>
              <StyledP>
                W przypadku błędnego wypełnienia formularzu, na podany w procesie rejestracji adres
                mailowy zostanie przesłany mail wskazujący dlaczego wniosek został odrzucony.
              </StyledP>
              <ImgInsideWrapper>
                <StyledImage src={Image8} alt="text" />
              </ImgInsideWrapper>
              <StyledP>
                Oznacza to konieczność ponownego założenia konta na portalu e-sądu od początku.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="3">
              <Header> Podsumowanie </Header>

              <StyledP>
                <Akapit /> Założenie konta w systemie e-sądu umożliwi dostęp do akt sprawy co nie
                tylko dostarczy niezbędnych informacji, ale również umożliwi podjęcie decyzji co do
                dalszych działań dotyczących danej sprawy.
                <br />
                <br />
                <Akapit /> Zachęcamy do przesłania akt sprawy na nasz adres mailowy lub kontakt
                telefoniczny. W przypadku problemów z pobraniem akt mogą nam Państwo przesłać login
                i hasło co pozwoli nam samodzielnie pobrać akta. Dzięki temu będziemy mogli wykonać{' '}
                <strong>darmową analizę </strong>Państwa spraw oraz doradzić jakie kroki należy
                podjąć, aby zmniejszyć lub zlikwidować zadłużenie.
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
