import styled from 'styled-components';
import Image from '../../assets/blog/art8.jpg';
import { basicRoute } from '../../routes/routes';
import { useLocation } from 'react-router';
import { Header } from '../../components/BlogTextHeder';
import { useEffect } from 'react';
import { Akapit } from '../../components/Akapit/Akapit';

const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Details = {
  head: ' Jak sprawdzić czy mam zadłużenia?',
  bigImage: Image,
  text: ' ',
  tableOfContents: [
    {
      id: '1',
      text: 'Czy istnieje jeden rejestr wszystkich długów i osób zadłużonych?      ',
    },
    {
      id: '2',
      text: ' Gdzie szukać długów? ',
    },
    {
      id: '3',
      text: ' Podsumowanie. O co pytać?',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`;
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit /> Działania mające na celu pozbycie się długów rozpocząć zawsze należy od ich
          “zlokalizowania”. Informacje o tym, kto jest wierzycielem w sprawie, czy sprawa była w
          sądzie, jeśli tak to w jakim i co się w nim wydarzyło oraz kwoty jaka jest w tej chwili
          wymagalna do spłaty są niezbędne dla wykonania <b>analizy prawnej</b> przez prawnika i
          doprowadzenia do <b>anulowania długu.</b> Często ustalenie tych najistotniejszych faktów
          jest dość łatwe i nie powinno przysporzyć wielu problemów, ale nie jest to regułą.
          Niniejszy artykuł pozwoli odpowiedzieć na pytania w jakich sytuacjach należy się ich
          spodziewać oraz jak sobie z nimi poradzić.{' '}
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>Spis treści :</strong>
        </p>
        <ol style={{ maringLeft: '20px' }}>
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <strong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </strong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper>
        <ol style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li id="1">
              <Header> Czy istnieje jeden rejestr wszystkich długów i osób zadłużonych?</Header>

              <StyledP>
                <Akapit />
                Niestety taki rejestr nie istnieje. Chociaż jego powstanie jest planowane od kilku
                lat, wciąż żeby ustalić czy masz jakieś zadłużenia np. w jednym z następujących
                podmiotów:{' '}
                <strong>
                  Ultimo, Kruk, Best, Hoist, Kredyt Inkaso, ProfiCredit, Alektum, Eques Debitum,
                  GetBack
                </strong>
                , w jakimś banku lub w którymś z parabanków np.{' '}
                <strong>LoanMe, SuperGrosz, Provident, Vivus, Rapida</strong> albo funduszu
                sekurytyzacyjnym np. <strong>Omega, Centauris, Horyzont</strong> często trzeba zadać
                sobie nieco trudu.
                <br />
                <Akapit />
                Oczywiście najłatwiejszą sytuacją, dla “zlokalizowania” zadłużenia jest chwila w
                której otrzymujesz <strong>pozew lub nakaz zapłaty z sądu </strong>(czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog1}
                  style={{ color: 'orange' }}
                >
                  jak zrozumieć nakaz zapłaty i pozew
                </a>{' '}
                ), <strong>pismo od komornika</strong> albo pismo z wezwaniem o zapłatę od któregoś
                z ww. podmiotów. W tym ostatnim przypadku zaskakującym może być, że nigdy nie
                zawierałeś żadnej umowy z firmą która wzywa Cię o zapłatę. Taka sytuacja wiąże się
                zazwyczaj z faktem, że dług został kupiony, tj. doszło do cesji wierzytelności.
                (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog10}
                  style={{ color: 'orange' }}
                >
                  Cesja wierzytelności. O powiązaniach między pożyczkodawcami i firmami
                  windykacyjnymi
                </a>
                ).
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="2">
              <Header> Gdzie szukać długów?</Header>

              <StyledP>
                <Akapit /> Czasami jednak dochodzi do sytuacji w których nigdy nie dostałeś żadnego
                pisma z sądu, od komornika (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog2}
                  style={{ color: 'orange' }}
                >
                  przywrócenie terminu jak odzczytać pisamo od komornika
                </a>{' '}
                ) ani od żadnej firmy. Dzieje się tak, ponieważ zmieniałeś adresy zamieszkania lub w
                umowie, która jest podstawą zadłużenia znajdują się nieaktualne albo błędne dane.
                Nawet wiedza o tym jaka firma żąda spłaty zadłużenie może okazać się
                niewystarczająca bowiem znalezienie numeru niektórych firm windykacyjnych jest
                praktycznie niemożliwe, a ich pracownicy niechętnie udzielają informacji (czyt.
                także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog21}
                  style={{ color: 'orange' }}
                >
                  tricki i nieczyste zagrywki firm windykacyjnych
                </a>
                ). Niekiedy możesz również znaleźć swoje imię i nazwisko na tzw. Giełdzie Długów. W
                takich okolicznościach trudno od razu stwierdzić{' '}
                <strong>czego dotyczy zadłużenie</strong>, a przecież bardzo istotny jest{' '}
                <strong>szybki kontakt ze specjalistą </strong>i podjęcie odpowiednich działań.
                Istnieje kilka podstawowych kroków, które należy podjąć:
                <br />
                <b>-</b> pierwszym z nich jest założenie konta na e-sądzie. (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog5}
                  style={{ color: 'orange' }}
                >
                  e-sąd
                </a>{' '}
                ) Jeśli nie jesteś sobie w stanie z tym poradzić, nie przejmuj się. Założymy Ci to
                konto <strong>bezpłatnie</strong> i zajmiemy się{' '}
                <strong>analizą umieszczonych tam akt spraw</strong>;
                <br />
                <b>-</b> jeśli nie odnajdziesz zadłużenia na koncie e-sądu, możesz założyć konto na
                portalu informacyjnym apelacji, w której znajduje się Twoja miejscowość lub
                miejscowości, w której mieszkałeś/aś ostatnio, np. zanim wyjechałeś{' '}
                <strong>za granicę</strong>;
                <br />
                <b>-</b> w przypadku <strong>zajęcia na koncie bankowym</strong> (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog22}
                  style={{ color: 'orange' }}
                >
                  jak działa komornik
                </a>
                ) warto skontaktować się z bankiem, ustalić dane komornika, który dokonał zajęcia a
                następnie
                <strong>skontaktować się z nami</strong>, w celu podjęcia kolejnych działań;
                <br />
                <b>-</b> możesz również skontaktować się z Biurami Informacji Gospodarczej (BIG) lub
                pobrać raportu Biura Informacji Kredytowej (BIK).
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="3">
              <Header> Podsumowanie. O co pytać?</Header>

              <StyledP>
                <Akapit />
                Podejmując ww. działania pamiętaj, że kluczowym jest, aby wiązały się one z
                uzyskaniem właściwych informacji. Tylko ustalenie pewnych, konkretnych faktów,
                pozwoli nam na przyjęcie skutecznego planu działania. Jeśli masz wątpliwości o co
                pytać i jak poszukiwać swojego długu, skontaktuj się z nami poprzez formularz
                zgłoszeniowy, telefon lub maila. Zrobimy wszystko co możliwe, aby{' '}
                <strong>
                  anulować twoje zadłużenie i odzyskać niesłusznie zabrane Ci pieniądze.
                </strong>
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
