import styled from 'styled-components';
import Image from '../../assets/blog/art11.jpg';
import { basicRoute } from '../../routes/routes';
import { useLocation } from 'react-router';
import { Header } from '../../components/BlogTextHeder';
import { useEffect } from 'react';
import { Akapit } from '../../components/Akapit/Akapit';
const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Details = {
  head: ' Klauzule abuzywne',
  bigImage: Image,
  text: '',
  tableOfContents: [
    {
      id: '1',
      text: 'Czym charakteryzują się klauzule abuzywne i czego dotyczą?',
    },
    {
      id: '2',
      text: ' Jak walczyć z klauzulami abuzywnymi?',
    },
    {
      id: '3',
      text: ' Podsumowanie.',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`; 
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit />
          Najprościej mówiąc <b>klauzule abuzywne</b> to zapisy zawarte w umowach pożyczek, w tym w{' '}
          <b>pożyczkach refinansujących</b>, które ze względu na nadmierne obciążenie kredytobiorcy
          lub niezgodność z obowiązującym prawem, nie wiążą dłużnika. Oznacza to, że nawet jeśli
          zawarłeś jakąś niekorzystną umowę z{' '}
          <b>
            którymś z następujących podmiotów: Ultimo, Kruk, Best, Hoist, Kredyt Inkaso,
            ProfiCredit, Alektum, Eques Debitum, GetBack, jakimś bankiem lub którymś z parabanków
            np. LoanMe, SuperGrosz, Provident, Vivus, Rapida albo funduszy sekurytyzacyjnych np.
            Omega, Centauris, Horyzont
          </b>{' '}
          (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog10} style={{ color: 'orange' }}>
            Cesja wierzytelności. O powiązaniach między pożyczkodawcami i firmami windykacyjnymi.
          </a>{' '}
          ), to istnieje możliwość abyśmy doprowadzili do sytuacji, w której nie będziesz musiał
          ponosić konsekwencji niekorzystnych dla Ciebie warunków umowy. (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog3} style={{ color: 'orange' }}>
            Jak złożyć sprzeciw od nakazu zapłaty ?
          </a>{' '}
          ) Możliwe jest bowiem{' '}
          <b>anulowanie prowizji lub zwalczenie innych niekorzystnych zapisów umowy</b> a w
          konsekwencji <b>umorzenie długu</b>.{' '}
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>Spis treści :</strong>
        </p>
        <ol style={{ maringLeft: '20px' }}>
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <strong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </strong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper>
        <ol style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li id="1">
              <Header>Czym charakteryzują się klauzule abuzywne i czego dotyczą?</Header>

              <StyledP>
                <Akapit /> Klauzule abuzywne wiążą się najczęściej z tym, że firmy pożyczkowe, banki
                i parabanki umieszczają w umowach zapisy, które obciążają kredytobiorcę ogromnymi
                prowizjami, często tak <strong>wysokimi jak cała kwota otrzymanej pożyczki.</strong>{' '}
                Opłaty te, kryjące się pod różnymi nazwami, takimi jak opłata przygotowawcza,
                różnego rodzaju pakiety, czy pseudoubezpieczenia z reguły mają na celu jedynie
                obejście przepisów o odsetkach maksymalnych i wydrenowanie kieszeni kredytobiorcy.
                Innym rodzajem klauzul abuzywnych są te, które doprowadzają osoby, które skorzystały
                z tzw. chwilówek do konieczności podejmowania niekorzystnych decyzji, takich jak
                chociażby wielokrotne{' '}
                <strong>
                  {' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={basicRoute.blog11}
                    style={{ color: 'orange' }}
                  >
                    refinansowanie
                  </a>{' '}
                </strong>
                pożyczek, obarczonych krótkim terminem spłaty.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="2">
              <Header>Jak walczyć z klauzulami abuzywnymi?</Header>

              <StyledP>
                <Akapit />
                Podstawowym orężem w walce z klauzulami abuzywnymi jest polski i europejski system
                prawny. Istnieje wiele przepisów prawa, wyroków sądów oraz orzecznictwa
                Europejskiego Trybunału Sprawiedliwości, które regulują jakie zapisy umów stanowią
                klauzule abuzywne. Bardzo istotną kwestią jest, aby po otrzymaniu nakazu zapłaty
                <strong> osoba znająca te przepisy i orzeczenia,</strong> przeanalizowała treść
                pozwu i jego załączników pod kątem występowania w nich nielegalnych zapisów. Warto
                pamiętać, że nie każda prowizja, będzie stanowiła klauzulę abuzywną i nie wystarczy
                jedynie powołanie się przed Sądem na fakt jej występowania w umowie pożyczki, trzeba
                to również odpowiednio uzasadnić.
                <br />
                <Akapit />
                Proces regulowania warunków umów pod kątem ich abuzywności jest bardzo dynamiczny,
                zatem wiedzę na temat tego co jest klauzulą abuzywną należy bezustannie
                aktualizować. Firmy pożyczkowe i parabanki, ale i coraz częściej duże, renomowane
                banki, będąc świadomym zwiększającej się ochrony konsumenta, stosują coraz to nowe
                metody nakładania dodatkowych kosztów na pożyczkobiorców, starając się przy tym
                obchodzić tworzone regulacje.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="3">
              <Header>Podsumowanie</Header>

              <StyledP>
                <Akapit />
                Stosowanie klauzul abuzywnych, szczególnie przez parabanki i firmy pożyczkowe to
                <strong> powszechna praktyka.</strong> Niestety, nawet mimo wciąż rosnącej ochrony
                konsumenta, niewielu kredytobiorców zdaje sobie sprawę ze swoich praw i Nie daj
                naciągnąć na dodatkowe koszta pożyczek! Jeśli odsetki pożyczki narastają pomimo
                tego, że dokonujesz wpłat lub pożyczkodawca wymaga od Ciebie spłaty znacznie
                większej kwoty, niż wynika to z twoich obliczeń{' '}
                <strong>
                  zgłoś się do nas i wyślij dokumenty. Za darmo przeanalizujemy Twoją sprawę i
                  doradzimy jak uchronić się przed niesłusznymi kosztami.
                </strong>
                <br />
                <Akapit />
                Pamiętaj, również, że nic nie stoi na przeszkodzie, aby <strong>
                  odzyskać
                </strong>{' '}
                już utracone pieniądze, jeśli spłaciłeś je w związku z występowaniem w umowie twojej
                pożyczki klauzul abuzywnych.
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
