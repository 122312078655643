import styled from 'styled-components';
import Image from '../../assets/blog/art5.jpg';
import { basicRoute } from '../../routes/routes';
import { useLocation } from 'react-router';
import { Header } from '../../components/BlogTextHeder';
import { useEffect } from 'react';
import { Akapit } from '../../components/Akapit/Akapit';

const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Details = {
  head: 'Elektroniczne postępowanie upominawcze, czyli słów parę o tzw. e-sądzie.',
  bigImage: Image,
  text: '',
  tableOfContents: [
    {
      id: '1',
      text: 'Czym jest e-sąd?',
    },
    {
      id: '2',
      text: ' Czemu firmy takie jak Kruk, Prokura czy Ultimo chętnie korzystają z tego sądu? ',
    },
    {
      id: '3',
      text: ' Jak wykorzystać e-sąd na korzyść dłużnika?',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`;
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit /> Często zdarza się, że firmy windykacyjne takiej jak Intrum, Hoist czy EasyDebt
          oraz parabanki udzielające tzw. chwilówek pozywają dłużników przed VI Wydziałem Cywilnym
          Sądu Rejonowego Lublin-Zachód w Lublinie. W poniższym tekście wyjaśnię czemu tak chętnie
          kierują pozwy do tego sądu oraz jak wykorzystać możliwości, które on oferuje, aby podjąć
          skuteczną walkę z długiem.{' '}
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>Spis treści :</strong>
        </p>
        <ol style={{ maringLeft: '20px' }}>
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <strong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </strong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper>
        <ol style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li id="1">
              <Header> Czym jest e-sąd?</Header>
              <StyledP>
                <Akapit /> Jak wspomniałem na początku, tak naprawdę e-sąd jest jedynie wydziałem
                sądu rejonowego położone w Lublinie. Tym co wyróżnia go spośród setek innych
                wydziałów sądów powszechnych jest sposób w jaki rozpoznaje kierowane do niego
                sprawy. Są one bowiem rozpatrywane w trybie{' '}
                <strong>elektronicznego postępowania upominawczego</strong>, które charakteryzuje
                się <strong>dużym uproszczeniem,</strong> w stosunku do pozostałych sposób
                rozpoznawania spraw cywilnych przez sądy w Polsce.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="2">
              <Header>
                {' '}
                Czemu firmy takie jak Kruk, Prokura czy Ultimo chętnie korzystają z tego sądu?
              </Header>

              <StyledP>
                <Akapit /> W pierwszej kolejności z tego powodu, iż jest on zdecydowanie szybszy od
                „zwykłych” trybów postępowania. Wynika to z jego maksymalnego uproszczenia. Powód
                nie musi bowiem przedkładać{' '}
                <strong>żadnych dokumentów potwierdzających istnienie zadłużenia</strong>, ani
                stawiać się w sądzie. Wystarczy, że opisze sprawę w pozwie i wymieni dokument na
                który się powołuje. Jedynie na tej podstawie referendarz sądowy orzeka w sprawie,
                zazwyczaj nie analizując jej zbyt szczegółowo. W związku z czym często firmy
                windykacyjne lub pożyczkowe uzyskują przeciwko stronie pozwanej{' '}
                <strong>nakaz zapłaty</strong> (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog1}
                  style={{ color: 'orange' }}
                >
                  jak zrozumieć nakaz zapłaty i pozew?
                </a>
                ) na dług przedawniony lub nawet <strong>nieistniejący! </strong>
                Wierzyciele często wybierają ten sposób załatwienia sprawy również z tego powodu, że
                opłaty sądowe, które muszą wnieść od pozwu są zdecydowanie niższe niż w „zwykłych”
                postępowaniach, a samo sprawa toczy się przez internet, oznacza to, że po założeniu
                specjalnego konta na platformie e-sad.gov.pl (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog55}
                  style={{ color: 'orange' }}
                >
                  jak załozyć konto na e sądzie?
                </a>
                ) wierzyciel nie musi wysyłać pozwu listownie. Wystarczy, że prześle go za pomocą
                ww. strony internetowej. W praktyce jest to niemal tak proste jak wysłanie maila.
                Oczywiście wierzyciel musi w takim przypadku posiadać podpis elektroniczny. W ten
                sposób łatwo i szybko powód może uzyskać nakaz zapłaty, który po uprawomocnieniu
                może <strong>skierować do komornika w celu prowadzenia egzekucji. </strong>
                (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog22}
                  style={{ color: 'orange' }}
                >
                  jak działa komornik?
                </a>
                )
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="3">
              <Header>Jak wykorzystać e-sąd na korzyść dłużnika?</Header>

              <StyledP>
                <Akapit /> Wbrew pozorom to, że sprawa toczyła się w takim uproszczonym trybie, może
                pomóc również osobie pozwanej. Dzięki temu, że całe postępowanie toczy się przez
                internet każda z jego stron może <strong>uzyskać dostęp do akt sprawy.</strong> W
                tym celu osoba pozwana musi założyć konto na portalu E-sad.gov.pl. Dzięki temu z
                reguły w ciągu 24 godzin, wypełniając krótki formularz swoimi danymi, osoba
                zadłużona może uzyskać kompletną informację o danej sprawie między innymi kto i z
                jakiego powodu ją pozwał, z czego wynika zadłużenie oraz jaka kwota jest dochodzona.
                W przypadku „zwykłego” postępowania nie jest to niestety takie proste. Taki komplet
                informacji pozwala dokonać analizy sprawy i ocenić jakie kroki należy podjąć aby
                powalczyć z zadłużeniem (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog2}
                  style={{ color: 'orange' }}
                >
                  Przywrócenie terminu. Jak odczytać pismo od Komornika?
                </a>
                ).
                <br />
                <br /> <Akapit /> Nie ulega wątpliwości, że e-sąd jest wykorzystywany przez firmy
                windykacyjne i pożyczkowe, ale wcale nie oznacza to, że nie może pomóc również
                osobie pozwanej. Przy umiejętnym poprowadzeniu sprawy nawet nakazy, które się już
                uprawomocniły i trafiły do komornika, mogą zostać uchylone. Co będzie skutkowało nie
                tylko <strong>zakończeniem egzekucji</strong> komorniczej, ale pozwoli również na
                sądową walkę o <strong>zlikwidowanie lub zmniejszenie zadłużenia.</strong>
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
