import styled from 'styled-components';
import Image from '../../assets/blog/art10.jpg';
import { basicRoute } from '../../routes/routes';
import { useLocation } from 'react-router';
import { Header } from '../../components/BlogTextHeder';
import { useEffect } from 'react';
import { Akapit } from '../../components/Akapit/Akapit';
const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-shadow: 2px 2px lightgrey;
  margin-inline: auto;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  max-width: 800px;
  width: 60%;
  margin-inline: auto;
`;
const StyledImage = styled.img`
  width: 100%;
`;
const Details = {
  head: ' Cesja wierzytelności. O powiązaniach między pożyczkodawcami i firmami windykacyjnymi.',
  bigImage: Image,
  text: '  ',
  tableOfContents: [
    {
      id: '1',
      text: 'Co to jest cesja wierzytelności?      ',
    },
    {
      id: '2',
      text: ' Dlaczego firmy pożyczkowe/banki sprzedają zadłużenia do firm windykacyjnych? ',
    },
    {
      id: '3',
      text: ' Powiązania firm pożyczkowych z firmami windykacyjnymi',
    },
    {
      id: '4',
      text: ' Podsumowanie',
    },
  ],
};

const StyledP = styled.p`
  line-height: 30px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OlWrapper = styled.div`
  margin-left: 5px;
`;
const StyledListItem = styled.li`
  cursor: pointer;
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;
const StyledA = styled.a`
  &:hover {
    /* color: ${({ theme }) => theme.colors.darkBlue};
     */
    color: blue;
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const First = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${Details.head} - Kancelaria Radcy Prawnego - Sprzeciw Długom`; 
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <StyledH1>{Details.head}</StyledH1>
      <ImageWrapper>
        <StyledImage src={Details.bigImage} alt="xd" />
      </ImageWrapper>
      <TextWrapper>
        <StyledP>
          <Akapit /> Osoby zadłużone często opisują rozmowy z windykatorami firmy BEST, Ultimo czy
          Kruka w trakcie, których są informowani, że firma windykacyjna kupiła dług albo, że
          komornik wszczął egzekucję na jej wniosek (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog2} style={{ color: 'orange' }}>
            Przywrócenie terminu. Jak odczytać pismo od Komornika?
          </a>{' '}
          ) i to pomimo tego, że nigdy nie zawierały żadnej umowy z taką firmą. Zdarza się również,
          że jesteśmy informowani o przypadkach, w których dłużnik otrzymuje nakaz z Eques Debitum,
          (czyt. także{' '}
          <a target="_blank" rel="noreferrer" href={basicRoute.blog3} style={{ color: 'orange' }}>
            Jak złożyć sprzeciw od nakazu zapłaty ?
          </a>{' '}
          ) Kredyt Inkaso, choć nigdy nie słyszał o takiej firmie. W tym artykule wyjaśniamy jak
          dochodzi do takich zdarzeń, w jaki sposób firmy sprzedają sobie nawzajem długi, czy jest
          to zgodne z prawem oraz jak wyglądają powiązania firm pożyczkowych oraz firm
          windykacyjnych.
        </StyledP>
      </TextWrapper>

      <OlWrapper>
        <p>
          <strong>Spis treści :</strong>
        </p>
        <ol style={{ maringLeft: '20px' }}>
          {Details.tableOfContents.map((x, i) => {
            return (
              <div key={i + '23'} style={{ marginTop: '10px' }}>
                <StyledListItem>
                  <strong>
                    <StyledA href={`#${x.id}`}>{x.text}</StyledA>
                  </strong>
                </StyledListItem>
              </div>
            );
          })}
        </ol>
      </OlWrapper>
      <MainTextWrapper>
        <ol style={{ maringLeft: '20px', listStyle: 'none' }}>
          <article style={{ marginTop: '15px' }}>
            <li id="1">
              <StyledP>
                <Header> Co to jest cesja wierzytelności?</Header>
                <Akapit />
                Polski kodeks cywilny reguluje możliwość zawierania umowy cywilnoprawnej, która
                umożliwia przeniesienie (cesję) wierzytelności tj. zadłużenia, którego dana firma
                może dochodzić od swojego dłużnika, z jednego podmiotu na inny. Przykładowo firma
                pożyczkowa Vivus może sprzedać wierzytelność, która powstała wskutek brak spłaty
                pożyczki przez dłużnika firmie Kruk, która jako firma windykacyjna, będzie starała
                się odzyskać pożyczone przez pożyczkodawcę pieniądze. Należy pamiętać, że w chwili
                zawarcia umowy przeniesienia wierzytelności nabywca (cesjonariusz) uzyskuje od
                zbywcy (cedenta) prawa wobec zadłużenia dłużnika i może ich dochodzić tak jakby to
                on był od samego początku wierzycielem.
                <br />
                <Akapit />
                Co do zasady dłużnik <strong>nie musi wyrażać zgody {''}</strong>
                na zawarcie umowy cesji, ponieważ nie jest stroną tej transakcji. Jednak jak wynika
                z naszej praktyki bardzo często zbywca i nabywca informują osobę zadłużoną o
                przeniesieniu długu. W takim przypadku również przy próbie kontaktu z dotychczasowym
                wierzycielem z reguły przekazuje on informację, że należy się teraz kontaktować z
                Prokurą, Bestem, Kredy Inkaso, Ultimo lub inną firmą windykacyjną.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="2">
              <Header>
                Dlaczego firmy pożyczkowe/banki sprzedają zadłużenia do firm windykacyjnych?
              </Header>

              <StyledP>
                <Akapit />
                Umowy cesji są często zawierane zarówno przez podmioty udzielające pożyczek jak i
                firmy windykacyjne. Sprzedaż wierzytelności stosowana jest przez pożyczkodawców,
                którzy nie chcą się dłużej starać o odzyskanie niespłaconych (
                <strong>nierzadko przedawnionych</strong>) pożyczek lub kredytów. Pożyczkodawcy
                uzyskują niewielką część pieniędzy z niespłaconej pożyczki, ale jednocześnie
                pozbywają się problematycznej sprawy. Natomiast firma windykacyjna uzyskuje
                zadłużenie po niższej cenie niż wynosi jego faktyczna wartość i tylko od niej zależy
                jak dużo uda się jej odzyskać, a co za tym idzie zarobić w danym przypadku.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="3">
              <Header>Powiązania firm pożyczkowych z firmami windykacyjnymi</Header>
              <StyledP>
                <Akapit />
                Po omówieniu czym jest sprzedaż wierzytelności możemy przejść do umówienia powiązań
                firm pożyczkowych i windykacyjnych.
                <br />
                <Akapit /> Jedną z najbardziej znanych firm windykacyjnych jest Kruka S.A., która
                zarządza funduszem inwestycyjnym Prokura NSFIZ. Długi, które najczęściej starają się
                windykować wynikają z zadłużeń powstałych w Vivus Finance, pożyczek zawartych z
                Zaplo oraz Recredit. Pismo z Prokury może do Was również może trafić gdy mieliście
                dawniej pożyczkę lub kredyt w Santanderze (dawnym Banku Zachodnim BZWBK).
                <br />
                <Akapit /> Kolejna znana firma windykacyjna — Ultimo kupuje zadłużenie wynikające z
                chwilówek udzielanych przez Providenta, Takto Finanse czy Aasa Polska. W jej
                „portfolio” znajdują się również pożyczki Monedo (zwane również pod nazwą
                Kreditech).
                <br />
                <Akapit />
                BEST kupuje głównie zadłużenia wynikające z kredytów bankowych. Najczęściej
                wierzytelności pochodzą z dawnego Kredyt Banku, Euro Banku. Dotyczą również często
                zadłużeń powstałych w mBanku oraz firmach telekomunikacyjnych takich jak T-Mobile
                czy Orange.
                <br />
                <Akapit />
                Mniej znane firmy windykacyjne również opierają się na zakupie tanich wierzytelności
                z niespłaconych wierzytelności. Przykładowo enigmatyczna firma Alektum Capital ze
                Szwajcarii kupuje zadłużenia od SuperGrosza (Aiqlabs), Ducatosa (właściciela marki
                Kuki.pl) oraz zadłużenia wynikające z pożyczek refinansujących jak np. Primastar
                (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog11}
                  style={{ color: 'orange' }}
                >
                  refinansowanie
                </a>
                )).
                <br />
                <Akapit />
                Jest to tylko wierzchołek góry lodowej powiązań pomiędzy firmami pożyczkowymi, a
                windykacyjnymi. Z reguły firmy te wchodzą w stałą współpracę, która trwa wiele lat.
              </StyledP>
            </li>
          </article>
          <article style={{ marginTop: '15px' }}>
            <li id="4">
              <Header>Podsumowanie</Header>
              <StyledP>
                <Akapit />
                Cesja wierzytelności i tym samym dochodzenie zadłużenia przez firmę windykacyjną,
                która wchodzi w miejsce pierwotnego wierzyciela nie oznacza, wbrew twierdzeniom jej
                pracowników, że jedynym rozwiązaniem problemu jest spłacenie długu lub zawarcie
                ugody. Nawet wysłanie przez taką firmę wezwania do zapłaty lub uzyskania przez nią
                nakazu zapłaty nie musi przekreślać szansy na uwolnienie się od długu. W wielu
                przypadkach w toku postępowania sądowego, można bowiem nie tylko doprowadzić do
                zmniejszenia zadłużenia ale nawet jego całkowitej likwidacji. Firmy windykacyjne
                prowadzą wiele spraw przez co zdarza się im popełniać błędy. Firma windykacyjna nie
                potrafi wykazać swojego zadłużenia oraz wysokości opłat wynikających z pożyczki
                (czyt. także{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={basicRoute.blog12}
                  style={{ color: 'orange' }}
                >
                  klauzule abuzywne
                </a>
                ), albo samego faktu przeniesienia wierzytelności na swoją rzecz. Dzięki temu, po
                przeanalizowaniu spawy przez doświadczonego prawnika,{' '}
                <strong>
                  można doprowadzić do uwolnienia się od zadłużenia bez konieczności jego spłacania.
                </strong>
              </StyledP>
            </li>
          </article>
        </ol>
      </MainTextWrapper>
    </>
  );
};

export default First;
