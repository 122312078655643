import { combineReducers } from 'redux';

import FreeAdviceReducer from './FreeAdviceReducer';
import MeReducer from './meReducer';
import LoginReducer from './loginReducer/loginReducer';
import RegistrationsReducer from './registrationsReducer/registrationsReducer';
import CoockieReducer from './CoockieReducer';

export default combineReducers({
  FreeAdviceReducer,
  MeReducer,
  LoginReducer,
  RegistrationsReducer,
  CoockieReducer,
});
