import axios from 'axios';
import { actionTypes } from '../constants/actionTypes';
import { BASE_URL } from '../constants/api';
export const registrations = () => async dispatch => {
  try {
    const { data } = await axios.get(`${BASE_URL}/registrations`, { withCredentials: true });

    dispatch({
      type: actionTypes.REGISTRATIONS,
      payload: data,
    });
    return data;
  } catch (error) {}
};

export const registrationsInProgress = () => async dispatch => {
  try {
    const { data } = await axios.get(`${BASE_URL}/registrations/inprogress`, {
      withCredentials: true,
    });

    dispatch({
      type: actionTypes.REGISTRATIONS,
      payload: data,
    });
    return data;
  } catch (error) {}
};

export const registrationsEnded = () => async dispatch => {
  try {
    const { data } = await axios.get(`${BASE_URL}/registrations/ended`, { withCredentials: true });

    dispatch({
      type: actionTypes.REGISTRATIONS,
      payload: data,
    });
    return data;
  } catch (error) {}
};

export const filter = (phoneNumber, date, tag, status, email, name, lastname) => async dispatch => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/filter?phoneNumber=${phoneNumber}&date=${date}&tag=${tag}&status=${status}&email=${email}&name=${name}&lastname=${lastname}`,
      { withCredentials: true },
    );

    dispatch({
      type: actionTypes.REGISTRATIONS,
      payload: data,
    });
    return data;
  } catch (error) {}
};
